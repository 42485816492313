import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Zoom from 'react-medium-image-zoom'
import { FormattedMessage, useIntl } from 'react-intl'

import { Input } from 'antd'
import { Form } from '@ant-design/compatible'

import config from 'config'

const Photos = ({ updateImage, object: { images } }) => {
  const [commentObject, setComment] = useState({})
  const intl = useIntl()

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    className: 'formItem'
  }

  const handleImageUpdate = image => {
    setComment({ ...commentObject, [image._id]: image.comment })
    updateImage(image)
  }

  return (
    <>
      {(images || []).map(image => (
        <React.Fragment key={image._id}>
          <h3>
            <FormattedMessage id='photo' />
          </h3>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'photo'
            })}
          >
            <Zoom zoomMargin={40}>
              <img
                src={config.server.url + image.imageUrl}
                className='img'
                style={{ width: '100%' }}
              />
            </Zoom>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'photo comment'
            })}
          >
            <Input.TextArea
              value={
                commentObject[image._id] !== undefined
                  ? commentObject[image._id]
                  : image.comment
              }
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={event =>
                handleImageUpdate({
                  ...image,
                  comment: (event.target && event.target.value) || ''
                })
              }
            />
          </Form.Item>
        </React.Fragment>
      ))}
    </>
  )
}

Photos.propTypes = {
  object: PropTypes.object,
  updateImage: PropTypes.func
}

export default Photos
