import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import FormProject from './FormProject'
import projectsService from '../../../services/projects'
import Popconfirm from '../../../components/Popconfirm'

const ProjectManagement = ({ visible, project, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createProject] = useApi(projectsService.addProject)
  const [updating, updateProject] = useApi(projectsService.updateProject)
  const [archiving, archiveProject] = useApi(projectsService.archiveProject)

  const handleSubmit = async () => {
    try {
      const { _id } = project || {}
      const values = await form.current.validateFields()
      onSubmit(await (_id ? updateProject(_id, values) : createProject(values)))
    } catch (error) {}
  }

  const handleArchive = async () => onSubmit(await archiveProject(project._id))

  const loading = creating || updating || archiving

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && project) {
    drawerButtons.unshift(
      <Popconfirm
        title={intl.formatMessage({
          id: 'do you want to archive this project?'
        })}
        value={<FormattedMessage id='archive' />}
        onConfirmAction={handleArchive}
      />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${project ? 'edit' : 'add'} project`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormProject ref={form} project={project} />
    </Drawer>
  )
}

ProjectManagement.propTypes = {
  project: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ProjectManagement.defaultProps = {
  visible: false
}

export default ProjectManagement
