import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input, Button, Form } from 'antd'
import { Icon } from '@ant-design/compatible'

const { Item } = Form

const FormForgotPassword = forwardRef(({ finishAction, loading }, ref) => {
  const intl = useIntl()

  return (
    <Form ref={ref} onFinish={finishAction}>
      <Item name='email' label={intl.formatMessage({ id: 'email' })}>
        <Input
          placeholder={intl.formatMessage({ id: 'email' })}
          prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
      </Item>

      <Item>
        <Button type='primary' block htmlType='submit' disabled={loading}>
          <FormattedMessage id='send' />
        </Button>
      </Item>
    </Form>
  )
})

FormForgotPassword.propTypes = {
  finishAction: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default FormForgotPassword
