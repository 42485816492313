import styled from 'styled-components'

const UploadWrapper = styled('div')`
  margin-bottom: 15px;

  .file-upload-list-cover {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .file-upload-list-item {
      padding: 5px;
      display: flex;
      margin-top: 8px;
      align-items: center;
      flex-direction: row;
      border: 1px solid #e2e2e2;
    }

    .file-description-item {
      flex: 1;
      line-height: 1.2;
      p {
        margin: 0;
      }
    }

    .file-image-cover {
      width: 50px;
      height: 50px;
      margin-right: 8px;
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        margin: auto;
        display: block;
      }
    }
  }
`

export default UploadWrapper
