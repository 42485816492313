import config from 'config'
import axios from 'helpers/axios'

export default {
  async getList (params = {}) {
    const response = await axios.get('/contract-section', params)
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/contract-section', params)
  },
  async addContractSection (data) {
    const response = await axios.post('/contract-section', data)
    return response
  },
  async deleteContractSection (id) {
    const response = await axios.delete(`/contract-section/${id}`)
    return response
  },
  async updateContractSection (id, data) {
    const response = await axios.put(`/contract-section/${id}`, data)
    return response
  }
}
