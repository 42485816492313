import React from 'react'
import { Button, Input } from 'antd'
import { Icon, Form } from '@ant-design/compatible'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'

import FormBuilder from 'components/FormBuilder'
import Popconfirm from 'components/Popconfirm'

const FormBuilderPage = props => {
  const intl = useIntl()
  const saveForm = () => {
    props.form.validateFields(err => {
      if (!err) {
        props.saveForm()
      }
    })
  }

  const {
    form: { getFieldDecorator },
    authUser
  } = props

  return (
    <div>
      <h1>
        <FormattedMessage id='form' />
      </h1>

      <div
        style={{
          width: 650
        }}
      >
        <div
          style={{
            textAlign: 'right',
            padding: 10
          }}
        >
          <Button
            shape='circle'
            onClick={() => props.setForm({ form: {} })}
            style={{
              marginRight: 10
            }}
          >
            <Icon type='arrow-left' />
          </Button>
        </div>

        <Form.Item
          label={
            <span>
              Name<span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('name', {
            initialValue: props.formData.name,
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'this field is required'
                })
              }
            ]
          })(
            <Input
              size='large'
              placeholder='Name'
              onChange={event => props.updateForm('name', event.target.value)}
            />
          )}
        </Form.Item>

        <FormBuilder
          data={props.formData.fields || []}
          onChange={data => props.updateForm('fields', data.task_data)}
        />

        <div
          style={{
            textAlign: 'left',
            padding: 10
          }}
        >
          {authUser.scope.some(item => item === 'superadmin') && (
            <Popconfirm
              onConfirmAction={() => props.deleteForm(props.formData)}
            />
          )}

          <Button
            style={{ marginLeft: 8 }}
            type='primary'
            onClick={() => saveForm()}
          >
            <FormattedMessage id='save' />
          </Button>
        </div>
      </div>
    </div>
  )
}

FormBuilderPage.propTypes = {
  formData: PropTypes.object,
  setForm: PropTypes.func.isRequired,
  saveForm: PropTypes.func.isRequired,
  deleteForm: PropTypes.func.isRequired,
  authUser: PropTypes.object,
  updateForm: PropTypes.func.isRequired,
  form: PropTypes.object
}

export default Form.create({ name: 'formbuilder_form' })(FormBuilderPage)
