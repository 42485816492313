import React from 'react'

import moment from 'moment'
import { Button, Table } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import PageTitle from 'components/Global/PageTitle'

const FormsPage = props => {
  const intl = useIntl()

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder:
        props.params.sortField === 'name' ? props.params.sortOrder : 'descend'
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      key: 'createdBy',
      dataIndex: 'createdBy',
      render: (text, record) => {
        if (record.createdBy) {
          return record.createdBy.fullName
        }

        return ''
      }
    },

    {
      width: 65,
      align: 'right',
      key: 'actions',
      render: record => (
        <Button onClick={() => props.setForm(record)}>
          <EditOutlined />
        </Button>
      )
    }
  ]

  const headerButtons = [
    <Button
      key={86568433536}
      type='primary'
      onClick={() => props.setForm({ name: '' })}
    >
      <FormattedMessage id='create form' />
    </Button>
  ]

  return (
    <div>
      <PageTitle
        title={intl.formatMessage({ id: 'forms' })}
        buttons={headerButtons}
      />
      <Table
        columns={columns}
        dataSource={props.forms}
        onRow={record => ({
          onDoubleClick: () => {
            props.setForm(record)
          }
        })}
        loading={props.loading}
        onChange={props.onTableChange}
      />
    </div>
  )
}

FormsPage.propTypes = {
  params: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  setForm: PropTypes.func.isRequired,
  forms: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired
}

export default FormsPage
