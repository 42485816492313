import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { Table, Menu, Dropdown } from 'antd'
import { Icon } from '@ant-design/compatible'

import {
  fetchFieldList,
  setPickedField,
  setDrawerVisible
} from '../../../ducks/fields'

export class FieldDefinitionTable extends React.Component {
  state = {
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    params: {}
  }

  requestFields = async () => {
    const { dispatch } = this.props
    const params = await this.getParams()
    this.setState({
      loading: true
    })
    dispatch(
      fetchFieldList({
        limit: this.state.defaultPageSize,
        ...params
      })
    ).then(data => {
      this.handleFieldResponse(data)
    })
  }

  componentDidMount () {
    this.requestFields()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch }
    } = this.props

    if (prevSearch !== nextSearch) {
      this.requestFields()
    }
  }

  getParams = async () => {
    const { history } = this.props
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }

      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { history } = this.props

    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  handleFieldResponse = async data => {
    const params = await this.getParams()
    const pagination = {
      ...this.state.pagination
    }
    pagination.total = data.totalDocs
    this.setState({
      loading: false,
      pagination: {
        ...pagination,
        pageSize: data.limit,
        current: data.page
      },
      params: { ...params }
    })
  }

  showDrawer = recording => {
    const { dispatch } = this.props
    dispatch(setPickedField(recording))
    dispatch(setDrawerVisible(true))
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    const stateParams = { ...this.state.params }
    pager.offset = pagination.current
    const params = {
      ...stateParams,
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    Object.keys(filters).forEach(key => {
      if (filters[key].length === 0) {
        delete params[key]
      }
      if (key === 'objectId') {
        params.objectId = [filters.objectId[0]._id]
      }
    })
    this.setParams(params)
  }

  render () {
    const { params, pagination, loading } = this.state
    const { intl, fieldList } = this.props

    const columns = [
      {
        title: intl.formatMessage({ id: 'key' }),
        key: 'key',
        dataIndex: 'key',
        sorter: true
      },
      {
        title: intl.formatMessage({ id: 'label' }),
        key: 'label',
        dataIndex: 'label'
      },
      {
        title: intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder:
          params.sortField === 'createdAt' ? params.sortOrder : 'descend'
      },
      {
        title: intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : ''
      }
    ]

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='0' onClick={() => this.showDrawer(record)}>
                  <Icon type='edit' /> {intl.formatMessage({ id: 'edit' })}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <a className='ant-dropdown-link' href='#'>
              <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown>
        </div>
      )
    })

    return (
      <Table
        key={JSON.stringify(params)}
        ref={ref => (this._table = ref)}
        rowKey={record => record._id}
        loading={loading}
        columns={columns}
        dataSource={fieldList || []}
        onChange={this.handleTableChange}
        onRow={record => ({ onDoubleClick: () => this.showDrawer(record) })}
        defaultPageSize={pagination.defaultPageSize}
        pagination={pagination}
      />
    )
  }
}

FieldDefinitionTable.propTypes = {
  fieldList: PropTypes.array,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  fieldList: state.fields.fieldsData.docs,
  objects: state.objects.searchedFieldsList
})

export default withRouter(
  injectIntl(connect(mapStateToProps, null)(FieldDefinitionTable))
)
