import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Button } from 'antd'
import { Helmet } from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import UserManagement from './UserManagement'
import usersService from '../../../services/user'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'

const UsersPage = () => {
  const intl = useIntl()
  const [user, setUser] = useState()
  const [data, setData] = useState({})
  const [loading, fetchUsers] = useApi(usersService.listV2)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchUsers({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = user => {
    setUser(user)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setUser()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const roles = {
    user: 'Standard User',
    admin: 'Administrator',
    superadmin: 'Superadmin'
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      sorter: true,
      defaultSortOrder: sortField === 'fullName' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'email' }),
      dataIndex: 'email'
    },
    {
      title: intl.formatMessage({ id: 'function' }),
      dataIndex: 'function'
    },
    {
      title: intl.formatMessage({ id: 'role' }),
      dataIndex: 'scope',
      render: scope => scope.map(item => roles[item] || item).join(', ')
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'last login' }),
      dataIndex: 'lastLogin',
      sorter: true,
      defaultSortOrder: sortField === 'lastLogin' && sortOrder,
      render: lastLogin =>
        lastLogin ? moment(lastLogin).format('DD.MM.YYYY, HH:mm') : '-'
    },
    {
      title: intl.formatMessage({ id: 'activated' }),
      dataIndex: 'activated',
      align: 'center',
      sorter: true,
      defaultSortOrder: sortField === 'activated' && sortOrder,
      render: activated =>
        activated ? (
          <CheckOutlined style={{ color: '#005591' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )
    },
    {
      width: 65,
      align: 'right',
      key: 'actions',
      render: record => (
        <Button onClick={() => handleOpen(record)}>
          <EditOutlined />
        </Button>
      )
    }
  ]

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.users'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'users' })}
        buttons={[
          <Button type='primary' key='create_user' onClick={() => handleOpen()}>
            <FormattedMessage id='create user' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        scroll={{ x: true }}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      <UserManagement
        user={user}
        visible={drawerVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  )
}

export default UsersPage
