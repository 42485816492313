import React from 'react'
import PropTypes from 'prop-types'

export const PageContext = React.createContext({})

const PageProvider = ({ children, ...props }) => (
  <PageContext.Provider value={props}>{children}</PageContext.Provider>
)

PageProvider.propTypes = {
  children: PropTypes.any
}

export default PageProvider
