import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { Select, Spin } from 'antd'
import { Form } from '@ant-design/compatible'

import { fetchSpecialWork } from '../../../../../../../../../ducks/specialWork'
import { updateWorkorderField } from '../../../../../../../../../ducks/workorder'

import '../../../styles.scss'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const { Option } = Select

class SpecialWork extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchSpecialWork, 300)
  }

  componentDidMount () {
    const { dispatch } = this.props
    dispatch(fetchSpecialWork({}, true))
  }

  assignSpecialWorkHandler = (value, e) => {
    const { dispatch, workorder } = this.props
    const outputArray = e.map((item, index) => {
      if (item.props.data) {
        return item.props.data
      } else {
        return (
          workorder.value.specialWork || workorder.income.specialWork
        ).find(category => category.name === value[index])
      }
    })

    dispatch(updateWorkorderField({ specialWork: outputArray }, true))
  }

  fetchSpecialWork = text => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchSpecialWork({ 'name[]': text }, true)).then(response => {
      this.setState({
        loading: false
      })
    })
  }

  render () {
    const { workorder, intl, specialWorkList } = this.props
    const { loading } = this.state

    const specialWork = workorder.income.specialWork || []
    const specialWorkNames = specialWork.map(item => {
      return item.name
    })

    return (
      <>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'select special work'
          })}
        >
          <Select
            mode='multiple'
            autoClearSearchValue
            defaultValue={specialWorkNames}
            notFoundContent={
              loading ? (
                <div className='workorder-spinner-container'>
                  <Spin size='small' />
                </div>
              ) : null
            }
            style={{ width: '100%' }}
            placeholder={this.props.intl.formatMessage({
              id: 'choose special work'
            })}
            onFocus={() => this.fetchSpecialWork('')}
            onSearch={value => this.handleInputThrottled(value)}
            onChange={(value, e) => this.assignSpecialWorkHandler(value, e)}
            disabled={workorder.income.status === 5}
          >
            {specialWorkList.map(item => (
              <Option key={item._id} data={item} id={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
    )
  }
}

SpecialWork.propTypes = {
  intl: PropTypes.object.isRequired,
  workorder: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  specialWorkList: PropTypes.array,
  objectName: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = state => {
  return {
    workorder: state.workorder.pickedWorkorder,
    users: state.users.searchedUsersList,
    specialWorkList: state.specialWork.searchedSpecialWork
  }
}
export default injectIntl(connect(mapStateToProps)(SpecialWork))
