import config from 'config'
import axios from '../helpers/axios'

export default {
  async getList () {
    const response = await axios.get('/objectType')
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/objectType', params)
  },
  async addObjectType (data) {
    const response = await axios.post('/objectType', data)
    return response
  },
  async archiveObjectType (id) {
    const response = await axios.put(`/objectType/${id}`, { archived: true })
    return response
  },
  async updateObjectType (id, data) {
    const response = await axios.put(`/objectType/${id}`, data)
    return response
  }
}
