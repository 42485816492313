import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import isEqual from 'lodash/isEqual'

import usePrevious from '../../../hooks/usePrevious'

import CommonFields from './CommonFields'
import SpecificFields from './SpecificFields'
import PropTypes from 'prop-types'

import './styles.scss'

const DefectForm = ({ data, form, ...props }) => {
  const prevData = usePrevious(data)

  useEffect(() => {
    if (!isEqual(data, prevData) && Object.keys(data).length) {
      form.resetFields()
    }
  }, [data, prevData])

  const { updateStateDefect, handleObjectOpen, errors, refSpecific } = props
  return (
    <Form className='form-cover'>
      <CommonFields
        updateStateDefect={updateStateDefect}
        data={data}
        errors={errors}
      />

      <SpecificFields
        refSpecific={refSpecific}
        updateStateDefect={updateStateDefect}
        handleObjectOpen={handleObjectOpen}
        data={data}
        errors={errors}
      />
    </Form>
  )
}

DefectForm.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  errors: PropTypes.object,
  updateStateDefect: PropTypes.func.isRequired,
  handleObjectOpen: PropTypes.func.isRequired,
  refSpecific: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
}

export default Form.create()(injectIntl(DefectForm))
