import config from 'config'
import axios from '../helpers/axios'

export default {
  async getList () {
    const response = await axios.get('/user')
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/user', params)
  },
  async addUser (data) {
    const response = await axios.post('/user', data)
    return response
  },
  async signup (data) {
    const response = await axios.post('/signup', data)
    return response
  },
  async deleteUser (id) {
    const response = await axios.delete(`/user/${id}`)
    return response
  },
  async updateUser (id, data) {
    const response = await axios.put(`/user/${id}`, data)
    return response
  },

  async reset (password, token) {
    return axios.post('/password', {
      password,
      token
    })
  },

  async sendToken (email) {
    return axios.post('/resetpassword', {
      email,
      passwordUrl: `${window.location.origin}/password`
    })
  }
}
