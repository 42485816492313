import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import './styles.scss'
import Popconfirm from '../../../../components/Popconfirm'

export const ContactsListItem = ({ contactData, handleRemove }) => {
  const intl = useIntl()

  return (
    <div className='user-item-block'>
      <data className='contact-data-cover'>
        <p className='user-item title'>
          {(contactData.contact_sub && contactData.contact_sub.company) ||
            `${(contactData.contact_sub && contactData.contact_sub.firstName) ||
              ''} ${(contactData.contact_sub &&
              contactData.contact_sub.lastName) ||
              ''}`}
        </p>
        {contactData.contact_sub && contactData.contact_sub.email && (
          <p className='user-item-text'>
            {intl.formatMessage({ id: 'email' })}:{' '}
            {contactData.contact_sub && contactData.contact_sub.email}
          </p>
        )}
        {contactData.contact_sub && contactData.contact_sub.phone && (
          <p className='user-item-text'>
            {intl.formatMessage({ id: 'phone' })}:{' '}
            {contactData.contact_sub && contactData.contact_sub.phone}
          </p>
        )}
      </data>
      <Popconfirm
        icon='delete'
        value=''
        onConfirmAction={() => handleRemove(contactData)}
      />
    </div>
  )
}

ContactsListItem.propTypes = {
  contactData: PropTypes.object,
  handleRemove: PropTypes.object
}

export default ContactsListItem
