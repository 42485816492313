import React, { forwardRef } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Form, Input, Select } from 'antd'

import useForms from '../../../hooks/useForms'

const { Item } = Form
const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormCategory = forwardRef(({ category }, ref) => {
  const intl = useIntl()

  const { name, custom_form: customForm } = category || {}
  const values = { name, custom_form: customForm && customForm._id }

  const { loading: loadingForms, fetch: fetchForms, items: forms } = useForms(
    customForm
  )

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Item
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'set a name'
          })}
        />
      </Item>

      <Item
        name='custom_form'
        label={intl.formatMessage({ id: 'category form' })}
      >
        <Select
          showSearch
          allowClear
          filterOption={false}
          loading={loadingForms}
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({
            id: 'choose a form'
          })}
          onSearch={debounce(fetchForms, 500)}
        >
          {forms.map(({ _id, name }) => (
            <Option key={_id}>{name}</Option>
          ))}
        </Select>
      </Item>
    </StyledForm>
  )
})

FormCategory.propTypes = {
  category: PropTypes.object
}

export default FormCategory
