import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import FormTask from './FormTask'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import tasksService from '../../../services/tasks'

const TaskManagement = ({ request, visible, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()

  const [saving, saveTask] = useApi(tasksService.saveTask)

  const handleSubmit = async () => {
    try {
      const task = await form.current.validateFields()
      const payload = {}
      Object.keys(task).forEach(item => {
        if (item.indexOf('extra_fields_') > -1) {
          const id = item.replace('extra_fields_', '')
          payload.extra_fields = [
            ...(payload.extra_fields || []),
            {
              id,
              value: task[item]
            }
          ]
        } else {
          payload[item] = task[item]
        }
      })
      if (request.images) {
        payload.images = request.images
      }
      onSubmit(await saveTask(payload))
    } catch (e) {}
  }

  const loading = saving
  const { name } = request

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  return (
    <Drawer
      title={`${intl.formatMessage({ id: 'create task' })} "${name}"`}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormTask ref={form} request={request} />
    </Drawer>
  )
}

TaskManagement.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired
}

TaskManagement.defaultProps = {
  visible: false
}

export default TaskManagement
