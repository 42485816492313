import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Tooltip, Input, Form, Spin, Select } from 'antd'
import {
  updateTask,
  setPickedTask,
  setTasksData
} from '../../../../../ducks/tasks'
import { fetchObjectsList } from '../../../../../ducks/object'
import debounce from 'lodash/debounce'
import { setWorkorderData } from '../../../../../ducks/workorder'
import checklistService from '../../../../../services/checklist'

const { Option } = Select

const statusOptions = ['opened', 'in process', 'finished', 'completed']

export class FieldEdit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: undefined,
      error: false,
      loading: false,
      editing: false,
      checklistArray: []
    }
    this.handleInputThrottled = debounce(this.fetchObjectsOnSearch, 300)
    this.handleChecklistThrottled = debounce(this.fetchChecklistOnSearch, 300)
  }

  handleCancelEdit = () => {
    this.setState({
      value: undefined,
      editing: false
    })
  }

  handleValue = value => {
    this.setState({
      value
    })
  }

  handleSubmit = () => {
    const {
      editingField,
      pickedTask,
      dispatch,
      tasksData,
      workorderData,
      defaultField
    } = this.props
    const { value } = this.state
    if (
      (!value ||
        (typeof value === 'object' && Object.keys(value).length === 0)) &&
      editingField !== 'checklist'
    ) {
      this.setState({ error: true })
      return
    }
    let outputArray = [...pickedTask.extra_fields]

    if (!defaultField) {
      // eslint-disable-next-line
      let extra_field = pickedTask.extra_fields.find(
        item => editingField === item.id
      )
      // eslint-disable-next-line
      if (!extra_field) {
        // eslint-disable-next-line
        extra_field = {
          id: editingField,
          value
        }
        outputArray.push(extra_field)
      } else {
        outputArray = outputArray.map(item => {
          if (item.id === editingField) {
            item.value = value
          }
          return item
        })
      }
    }
    dispatch(
      updateTask(
        pickedTask._id,
        defaultField ? { [editingField]: value } : { extra_fields: outputArray }
      )
    ).then(response => {
      if (!response.error) {
        dispatch(setPickedTask(response))
        if (!pickedTask.workorder) {
          dispatch(
            setTasksData({
              ...tasksData,
              docs: [
                ...tasksData.docs.map(task => {
                  if (task._id === response._id) {
                    return response
                  }
                  return task
                })
              ]
            })
          )
        } else {
          dispatch(
            setWorkorderData({
              ...workorderData,
              docs: [
                ...workorderData.docs.map(workorder => {
                  if (workorder._id === pickedTask.workorder._id) {
                    const outputWorkorder = { ...workorder }
                    const tasksList = workorder.tasks.map(task => {
                      if (task._id === response._id) {
                        return response
                      }
                      return task
                    })
                    outputWorkorder.tasks = [...tasksList]
                    return outputWorkorder
                  }
                  return workorder
                })
              ]
            })
          )
        }

        this.handleCancelEdit()
      }
    })
  }

  fetchObjectsOnSearch = searchQueue => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchObjectsList({ 'name[]': searchQueue }, true)).then(
      response => {
        this.setState({
          loading: false
        })
      }
    )
  }

  fetchChecklistOnSearch = async (searchQueue = '') => {
    // const { dispatch } = this.props
    this.setState({
      loading: true
    })

    const checklistData = await checklistService.listV2({
      'name[]': searchQueue
    })

    if (checklistData.docs) {
      this.setState({
        loading: false,
        checklistArray: checklistData.docs
      })
    }
    this.setState({
      loading: false
    })
  }

  render () {
    const {
      pickedTask,
      intl,
      editingField,
      objects,
      fieldType,
      disabled,
      defaultField,
      objectIdName,
      label
    } = this.props
    const { value, error, loading, editing, checklistArray } = this.state

    const extraFieldValueObject = pickedTask.extra_fields.find(
      item => item.id === editingField
    )
    const extraFieldValue = extraFieldValueObject
      ? extraFieldValueObject.value
      : ''
    return (
      <div className='editing'>
        <p onClick={() => this.setState({ editing: !disabled })}>
          <Tooltip
            title={
              (!disabled &&
                defaultField &&
                intl.formatMessage({ id: `edit ${editingField}` })) ||
              (!disabled && intl.formatMessage({ id: `edit field` }))
            }
          >
            <p>
              <b>
                {defaultField &&
                  editingField !== 'objectId' &&
                  intl.formatMessage({ id: editingField })}
                {editingField === 'objectId' && objectIdName}
                {!defaultField && label}
              </b>
            </p>
            {!editing && (
              <p>
                {(pickedTask[editingField] && pickedTask[editingField].name) ||
                  pickedTask[editingField]}

                {extraFieldValue}
              </p>
            )}
          </Tooltip>
        </p>
        {editing && (
          <div>
            <Form.Item
              validateStatus={error ? 'error' : null}
              help={error ? intl.formatMessage({ id: 'is required' }) : null}
            >
              {fieldType === 'text' && (
                <Input
                  maxLength={40}
                  placeholder={intl.formatMessage({ id: 'name' })}
                  value={
                    value !== undefined
                      ? value
                      : pickedTask[editingField] || extraFieldValue
                  }
                  onChange={event => this.handleValue(event.target.value)}
                />
              )}

              {fieldType === 'textarea' && (
                <Input.TextArea
                  onChange={e => this.handleValue(e.target.value)}
                  value={value !== undefined ? value : pickedTask[editingField]}
                />
              )}
              {fieldType === 'select' && editingField === 'objectId' && (
                <Select
                  showSearch
                  allowClear
                  loading={loading}
                  className='certain-category-search'
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 200 }}
                  style={{ width: '100%' }}
                  value={
                    value
                      ? value.name
                      : (pickedTask[editingField] &&
                          pickedTask[editingField].name) ||
                        undefined
                  }
                  placeholder='Select Object'
                  optionFilterProp='children'
                  notFoundContent={
                    loading ? (
                      <div className='recordings-spinner-container'>
                        <Spin size='small' />
                      </div>
                    ) : null
                  }
                  onFocus={() => this.fetchObjectsOnSearch('')}
                  onSearch={this.handleInputThrottled}
                  onSelect={(value, e) => this.handleValue(e.props.data)}
                >
                  {objects.map(object => (
                    <Option
                      key={object._id}
                      data={object}
                      value={object.name || ''}
                    >
                      {object.name}
                    </Option>
                  ))}
                </Select>
              )}

              {fieldType === 'select' && editingField === 'checklist' && (
                <Select
                  showSearch
                  allowClear
                  loading={loading}
                  className='certain-category-search'
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 200 }}
                  style={{ width: '100%' }}
                  value={
                    value !== undefined
                      ? (value && value.name) || ''
                      : (pickedTask[editingField] &&
                          pickedTask[editingField].name) ||
                        undefined
                  }
                  placeholder='Select checklist'
                  optionFilterProp='children'
                  notFoundContent={
                    loading ? (
                      <div className='recordings-spinner-container'>
                        <Spin size='small' />
                      </div>
                    ) : null
                  }
                  onFocus={() => this.fetchChecklistOnSearch('')}
                  onSearch={this.handleChecklistThrottled}
                  onChange={(value, e) =>
                    this.handleValue((e && e.props.data) || null)
                  }
                >
                  {checklistArray.map(object => (
                    <Option
                      key={object._id}
                      data={object}
                      value={object.name || ''}
                    >
                      {object.name}
                    </Option>
                  ))}
                </Select>
              )}

              {editingField === 'status' && (
                <Select
                  value={value || pickedTask[editingField] || undefined}
                  style={{ width: '100%' }}
                  onChange={value => this.handleValue(value)}
                >
                  {statusOptions.map(item => (
                    <Option key={item} value={item}>
                      {intl.formatMessage({
                        id: item
                      })}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <div className='buttons-cover'>
              <Button htmlType='button' onClick={this.handleCancelEdit}>
                <FormattedMessage id='cancel' />
              </Button>
              <Button key='ok' type='primary' onClick={this.handleSubmit}>
                Ok
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

FieldEdit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  objects: PropTypes.array.isRequired,
  editingField: PropTypes.string,
  pickedTask: PropTypes.object,
  tasksData: PropTypes.object,
  workorderData: PropTypes.object,
  objectIdName: PropTypes.string,
  fieldType: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.any,
  defaultField: PropTypes.any
}

const mapStateToProps = state => {
  return {
    pickedTask: state.tasks.pickedTask,
    tasksData: state.tasks.tasksData,
    workorderData: state.workorder.workorderData,
    objects: state.objects.searchedObjectsList,
    objectIdName: state.settings.data.buildx.objectName
  }
}

export default injectIntl(connect(mapStateToProps)(FieldEdit))
