import React, { useState } from 'react'
import {
  sortableHandle,
  sortableElement,
  sortableContainer
} from 'react-sortable-hoc'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import arrayMove from 'array-move'
import { useSelector } from 'react-redux'
import Zoom from 'react-medium-image-zoom'
import { CloseOutlined } from '@ant-design/icons'
import { createGlobalStyle } from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Row, Col, Input, Select, Collapse, Button } from 'antd'

import config from '../../../config'
import useObjects from '../../../hooks/useObjects'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input
const { Panel } = Collapse

const itemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  style: { marginBottom: '0.6rem' }
}

const StyledContainer = createGlobalStyle`
  body > .drag-item {
    z-index: 9999;
  }
  .actions {
    text-align: center;
    margin: 20px 0 15px;
  }
  .drag-item {
    margin: 10px 0;
    padding: 15px 0;
    background: #fff;
    position: relative;
    border: 2px solid #a9a9a9;
  }
  .drag-handler {
    font-weight: bold;
    letter-spacing: -2px;
  }
  .remove-icon {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #d22222;
    font-size: 16px;
    cursor: pointer;
  }
`

const DragHandle = sortableHandle(() => (
  <span className='drag-handler'>:::</span>
))

const SortableContainer = sortableContainer(({ children }) => (
  <div>{children}</div>
))

const SortableItem = sortableElement(
  ({ prefix, request, onRemove, objects: defaultObjects }) => {
    const intl = useIntl()

    const objectName = useSelector(
      ({
        settings: {
          data: { buildx: { objectName = 'object number' } = {} }
        }
      }) => objectName
    )

    const { images = [], checklist = {} } = request || {}

    const customFields = checklist.fields || []

    const {
      loading: loadingObjects,
      fetch: fetchObjects,
      items: objects
    } = useObjects(defaultObjects, { fetchOnMount: false })

    return (
      <div className='drag-item'>
        <Row type='flex' align='middle'>
          <Col span={4} className='text-center'>
            <DragHandle />
          </Col>
          <Col span={17}>
            <Item name={[...prefix, 'checklist']} noStyle>
              <Input type='hidden' />
            </Item>
            <Item
              {...itemLayout}
              name={[...prefix, 'name']}
              label={intl.formatMessage({ id: 'name' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'this field is required' })
                }
              ]}
            >
              <Input />
            </Item>
            <Item
              {...itemLayout}
              name={[...prefix, 'objectId']}
              label={intl.formatMessage({
                id: objectName,
                defaultMessage: objectName
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'this field is required' })
                }
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={false}
                loading={loadingObjects}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                  id: `choose a ${objectName}`,
                  defaultMessage: `choose a ${objectName}`
                })}
                onSearch={debounce(fetchObjects, 500)}
              >
                {objects.map(({ _id, name }) => (
                  <Option key={_id}>{name}</Option>
                ))}
              </Select>
            </Item>
            <Item
              {...itemLayout}
              name={[...prefix, 'description']}
              label={intl.formatMessage({ id: 'description' })}
            >
              <TextArea />
            </Item>
            {customFields.map(({ key, label }, index) => (
              <div key={key}>
                <Item name={[...prefix, 'extra_fields', index, 'id']} noStyle>
                  <Input type='hidden' />
                </Item>
                <Item
                  {...itemLayout}
                  name={[...prefix, 'extra_fields', index, 'value']}
                  label={intl.formatMessage({
                    id: label,
                    defaultMessage: label
                  })}
                >
                  <Input />
                </Item>
              </div>
            ))}
            {images.length > 0 && (
              <Collapse>
                {images.map(({ _id, comment, imageUrl }, index) => (
                  <Panel
                    key={_id}
                    header={`${intl.formatMessage({
                      id: 'picture'
                    })} ${index + 1}`}
                  >
                    <Item
                      {...itemLayout}
                      label={intl.formatMessage({ id: 'picture' })}
                    >
                      <Zoom zoomMargin={40}>
                        <img
                          src={config.server.url + imageUrl}
                          className='img'
                          alt='comment image'
                          style={{ width: '100%' }}
                        />
                      </Zoom>
                    </Item>
                    <Item
                      {...itemLayout}
                      label={intl.formatMessage({ id: 'comment' })}
                    >
                      <TextArea defaultValue={comment} />
                    </Item>
                  </Panel>
                ))}
              </Collapse>
            )}
          </Col>
        </Row>
        <span onClick={onRemove} className='remove-icon'>
          <CloseOutlined />
        </span>
      </div>
    )
  }
)

const TasksTab = ({ form, request, ...props }) => {
  const { getFieldsValue, setFieldsValue } = form
  const [counter, setCounter] = useState(1)

  const { checklist } = request || {}

  const addTask = () => {
    const { tasks = [] } = getFieldsValue()
    const { _id, fields } = checklist || {}
    setFieldsValue({
      tasks: [
        ...tasks,
        {
          checklist: _id,
          extra_fields: (fields || []).map(({ _id }) => ({ id: _id }))
        }
      ]
    })
    setCounter(counter + 1)
  }

  const removeTask = index => {
    if (counter < 1) return
    const { tasks = [] } = getFieldsValue()
    setFieldsValue({
      tasks: tasks.filter((task, i) => i !== index)
    })
    setCounter(counter - 1)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const { tasks = [] } = getFieldsValue()
    setFieldsValue({
      tasks: arrayMove(tasks, oldIndex, newIndex)
    })
  }

  return (
    <>
      <StyledContainer />
      <SortableContainer useDragHandle onSortEnd={onSortEnd}>
        {[...new Array(counter)].map((value, index) => (
          <SortableItem
            key={`task-${index}`}
            {...{
              index,
              request,
              ...props,
              prefix: ['tasks', index],
              onRemove: () => removeTask(index)
            }}
          />
        ))}
      </SortableContainer>
      <div className='actions'>
        <Button type='primary' onClick={addTask}>
          <FormattedMessage id='add task' />
        </Button>
      </div>
    </>
  )
}

TasksTab.propTypes = {
  objects: PropTypes.array,
  request: PropTypes.object,
  form: PropTypes.object.isRequired
}

export default TasksTab
