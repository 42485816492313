import React, { useRef } from 'react'
import { Button, notification } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import FormChecklist from './FormChecklist'
import checklistService from '../../../services/checklist'
import Popconfirm from '../../../components/Popconfirm'

const ChecklistManagement = ({ visible, checklist, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createChecklist] = useApi(checklistService.addChecklist)
  const [updating, updateChecklist] = useApi(checklistService.updateChecklist)

  const handleSubmit = () => {
    form.current.validateFields(async (errors, { _id, ...record }) => {
      if (errors) return
      try {
        onSubmit(
          _id
            ? await updateChecklist(_id, record)
            : await createChecklist(record)
        )
      } catch (e) {
        notification.open({
          type: 'error',
          message:
            e.error && e.error.indexOf('unique') > -1
              ? intl.formatMessage({ id: 'name already exists' })
              : e.error
        })
      }
    })
  }

  const handleArchive = async () =>
    onSubmit(await updateChecklist(checklist._id, { archived: true }))

  const loading = creating || updating

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (
    scope === 'superadmin' &&
    checklist &&
    !checklist.isVerified &&
    checklist._id
  ) {
    drawerButtons.unshift(
      <Popconfirm
        title={intl.formatMessage({ id: 'confirm archive' })}
        key='archive'
        value={intl.formatMessage({ id: 'archive' })}
        onConfirmAction={handleArchive}
      />
    )
  }
  return (
    <Drawer
      title={intl.formatMessage({
        id: `${checklist ? 'edit' : 'add'} checklist`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormChecklist ref={form} checklist={checklist} />
    </Drawer>
  )
}

ChecklistManagement.propTypes = {
  checklist: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ChecklistManagement.defaultProps = {
  visible: false
}

export default ChecklistManagement
