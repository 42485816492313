import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import saveAs from 'file-saver'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import { useIntl, FormattedMessage } from 'react-intl'
import { Table, Tag, Menu, Dropdown, Button } from 'antd'
import {
  EditOutlined,
  MoreOutlined,
  CheckOutlined,
  UploadOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  ToolOutlined,
  ExportOutlined,
  ImportOutlined,
  DownOutlined
} from '@ant-design/icons'

import SAPModal from './SAPModal'
import ImportModal from './ImportModal'
import useApi from '../../../hooks/useApi'
import BulkChangeModal from './BulkChangeModal'
import DefectManagement from './DefectManagement'
import searchColumn from '../../../utils/searchColumn'
import defectsService from '../../../services/defects'
import usePagination from '../../../hooks/usePagination'
import PageProvider from '../../../providers/PageProvider'
import PageTitle from '../../../components/Global/PageTitle'
import ContactTreeSelect from '../../../components/ContactTreeSelect'

const { Item, Divider } = Menu

const DefectsPage = () => {
  const intl = useIntl()
  const inputSap = useRef()
  const inputName = useRef()
  const inputNumber = useRef()
  const inputObject = useRef()
  const inputReference = useRef()
  const inputProtocolRefExt = useRef()
  const inputSubmittedRefNr = useRef()
  const inputContractSection = useRef()
  const [defect, setDefect] = useState()
  const [data, setData] = useState({})
  const [selected, setSelected] = useState([])
  const [loading, fetchDefects] = useApi(defectsService.listV2)
  const [sapVisible, setSAPVisible] = useState(false)
  const [bulkVisible, setBulkVisible] = useState(false)
  const [importVisible, setImportVisible] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )
  const objectName = useSelector(
    ({
      settings: {
        data: { buildx: { objectName = 'object number' } = {} }
      }
    }) => objectName
  )
  const sapEnabled = useSelector(
    ({
      settings: {
        data: { apps: { sap = false } = {} }
      }
    }) => sap
  )

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt',
    filters: { status: ['open', 'in progress'] }
  })

  const loadData = async () => {
    const response = await fetchDefects({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = defect => {
    setDefect(defect)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setDefect()
    setSAPVisible(false)
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  const handleSAP = defect => {
    setDefect(defect)
    setSAPVisible(true)
  }

  const handleBulkClose = (refresh = false) => {
    setSelected([])
    setBulkVisible(false)
    return refresh && loadData()
  }

  const handleImportClose = (refresh = false) => {
    setImportVisible(false)
    return refresh && loadData()
  }

  const downloadPDF = async ({ _id, docNumber }) =>
    saveAs(
      new Blob([await defectsService.downloadPDF(_id)], {
        type: 'application/pdf'
      }),
      `${docNumber}.pdf`
    )

  const exportCSV = async () => {
    const params = {
      offset,
      sortOrder,
      sortField,
      ...filters
    }
    selected.length > 0 &&
      Object.assign(params, {
        defects: selected
      })
    saveAs(
      new Blob([await defectsService.exportCSV(params)], { type: 'text/csv' }),
      'defects.csv'
    )
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    size: 'default',
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    total: data.totalDocs || 0,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const {
    filterOptions: {
      createdBy: createdByFilters = [],
      assignedTo: assignedToFilters = [],
      protocolResponsible: protocolResponsibleFilters = []
    } = {}
  } = data

  const columns = [
    {
      title: intl.formatMessage({ id: 'number' }),
      dataIndex: 'docNumber',
      sorter: true,
      fixed: true,
      width: 120,
      defaultSortOrder: sortField === 'docNumber' && sortOrder,
      ...searchColumn({ input: inputNumber, intl, value: filters.docNumber })
    },
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      sorter: true,
      width: 300,
      defaultSortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input: inputName, intl, value: filters.name })
    },
    {
      title: objectName,
      dataIndex: ['objectId', 'name'],
      key: 'objectId',
      sorter: true,
      width: 220,
      defaultSortOrder: sortField === 'objectId' && sortOrder,
      ...searchColumn({ input: inputObject, intl, value: filters.objectId })
    },
    {
      title: intl.formatMessage({ id: 'protocolReference' }),
      dataIndex: 'protocolReference',
      width: 180,
      ...searchColumn({
        input: inputReference,
        intl,
        value: filters.protocolReference
      })
    },
    {
      title: intl.formatMessage({ id: 'protocolResponsible' }),
      dataIndex: 'protocolResponsible',
      width: 250,
      filters:
        protocolResponsibleFilters.length > 0 &&
        protocolResponsibleFilters.map(({ _id, company }) => ({
          text: company,
          value: _id
        })),
      render: value => ContactTreeSelect.displayContact(value),
      defaultFilteredValue: filters.protocolResponsible
    },
    {
      title: intl.formatMessage({ id: 'protocolRefExt' }),
      dataIndex: 'protocolRefExt',
      width: 250,
      ...searchColumn({
        input: inputProtocolRefExt,
        intl,
        value: filters.protocolRefExt
      })
    },
    {
      title: intl.formatMessage({ id: 'estimated_cost' }),
      dataIndex: 'estimated_cost',
      align: 'right',
      sorter: true,
      width: 150,
      render: estimatedCost => (
        <NumberFormat
          displayType='text'
          value={estimatedCost}
          thousandSeparator="'"
          prefix='CHF '
        />
      ),
      defaultSortOrder: sortField === 'estimated_cost' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'contract section' }),
      dataIndex: ['contractSection', 'name'],
      key: 'contractSection',
      sorter: true,
      width: 220,
      defaultSortOrder: sortField === 'contractSection' && sortOrder,
      ...searchColumn({
        input: inputContractSection,
        intl,
        value: filters.contractSection
      })
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      sorter: true,
      width: 200,
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      width: 200,
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters:
        createdByFilters.length > 0 &&
        createdByFilters.map(user => {
          const { _id, firstName = '', lastName = '' } = user
          return {
            text: `${firstName} ${lastName}`,
            value: _id
          }
        }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'reference number' }),
      key: 'submittedRefNr',
      width: 250,
      ...searchColumn({
        input: inputSubmittedRefNr,
        intl,
        value: filters.submittedRefNr
      }),
      render: record => {
        const { recording: { docNumber } = {} } = record
        return docNumber ? (
          <Tag
            style={{ cursor: 'pointer' }}
            onClick={() => console.log(record)}
            color='blue'
          >
            <CheckCircleOutlined />
            &nbsp;
            {docNumber}
          </Tag>
        ) : (
          ''
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'assigned to' }),
      dataIndex: 'assignedTo',
      width: 150,
      render: value => ContactTreeSelect.displayContact(value),
      filters:
        assignedToFilters.length > 0 &&
        assignedToFilters.map(({ _id, company }) => ({
          text: company,
          value: _id
        })),
      defaultFilteredValue: filters.assignedTo
    },
    {
      title: 'SAP',
      key: 'sap',
      width: 150,
      sorter: true,
      ...searchColumn({ input: inputSap, intl, value: filters.sap }),
      render: ({ submissions }) => {
        if ((submissions || []).some(({ name }) => name === 'sap')) {
          return (
            <Tag color='green'>
              <CheckOutlined />
              &nbsp;
              {submissions.map(({ reference }, index) => (
                <span key={index}>{reference}</span>
              ))}
            </Tag>
          )
        }
      },
      defaultSortOrder: sortField === 'sap' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'status',
      width: 100,
      filters: [
        {
          text: intl.formatMessage({ id: 'open' }),
          value: 'open'
        },
        {
          text: intl.formatMessage({ id: 'in progress' }),
          value: 'in progress'
        },
        {
          text: intl.formatMessage({ id: 'completed' }),
          value: 'completed'
        }
      ],
      defaultFilteredValue: filters.status,
      render: status => <FormattedMessage id={status} />
    }
  ]

  if (!selectedProject._id) {
    columns.push({
      title: intl.formatMessage({ id: 'project' }),
      key: 'project',
      width: 220,
      dataIndex: ['project', 'projectName']
    })
  }

  columns.push({
    width: 50,
    align: 'right',
    fixed: 'right',
    key: 'actions',
    render: record => {
      const { submissions } = record || {}
      const sapExists = (submissions || []).some(({ name }) => name === 'sap')
      return (
        <Dropdown
          overlay={
            <Menu>
              <Item key='0' onClick={() => handleOpen(record)}>
                <EditOutlined />
                <FormattedMessage id='edit' />
              </Item>

              <Item key='1' onClick={() => downloadPDF(record)}>
                <FilePdfOutlined />
                <FormattedMessage id='generate pdf' />
              </Item>

              {(sapExists || sapEnabled) && <Divider />}

              {sapEnabled && !sapExists && (
                <Item key='3' onClick={() => handleSAP(record)}>
                  <UploadOutlined />
                  <FormattedMessage id='send to sap' />
                </Item>
              )}

              {sapExists && (
                <Item key='3' disabled>
                  <CheckOutlined />
                  <FormattedMessage id='submitted to sap' />
                </Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ fontSize: '2rem', color: '#444' }} />
        </Dropdown>
      )
    }
  })

  const actions = (
    <Menu>
      <Item
        disabled={selected.length === 0}
        onClick={() => setBulkVisible(true)}
      >
        <ToolOutlined />
        <FormattedMessage id='bulk change' />
      </Item>
      <Item disabled={loading} onClick={exportCSV}>
        <ExportOutlined />
        <FormattedMessage id='csv export' />{' '}
        {selected.length > 0
          ? `(${selected.length} ${intl.formatMessage({
              id: 'defects'
            })})`
          : ''}
      </Item>
      <Item disabled={loading} onClick={() => setImportVisible(true)}>
        <ImportOutlined />
        <FormattedMessage id='csv import' />
      </Item>
    </Menu>
  )

  const headerButtons = [
    <Dropdown overlay={actions} key='actions-dropdown'>
      <Button>
        <FormattedMessage id='actions' />
        &nbsp;
        <DownOutlined />
      </Button>
    </Dropdown>,
    <Button
      type='primary'
      key='create_defect'
      style={{ marginLeft: 8 }}
      onClick={() => handleOpen()}
    >
      <FormattedMessage id='create defect' />
    </Button>
  ]

  const dataSource = data.docs || []

  return (
    <PageProvider data={data} setData={setData} setDefect={setDefect}>
      <FormattedMessage id='head.title.defects'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'defects' })}
        buttons={headerButtons}
      />

      <Table
        rowKey='_id'
        size='small'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        scroll={{ x: 2800 }}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        rowSelection={{
          selectedRowKeys: selected,
          onChange: selectedKeys => setSelected(selectedKeys)
        }}
        pagination={pagination}
      />

      <DefectManagement
        defect={defect}
        visible={drawerVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />

      <ImportModal visible={importVisible} onClose={handleImportClose} />

      <SAPModal
        item={defect || {}}
        visible={sapVisible}
        onSubmit={loadData}
        onClose={handleClose}
      />

      <BulkChangeModal
        visible={bulkVisible}
        onClose={handleBulkClose}
        defects={dataSource.filter(({ _id }) => selected.includes(_id))}
      />
    </PageProvider>
  )
}

export default DefectsPage
