import React, { useEffect, useState } from 'react'
import { Input, Button, Form } from 'antd'
import { Icon } from '@ant-design/compatible'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { login } from '../../../../ducks/login'

const { Item } = Form

const FormLogin = props => {
  const [form] = Form.useForm()
  const user = useSelector(state => state.auth.authUser)
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      history.push('/', { login: true })
    }
  }, [user])

  const handleFormSubmit = async () => {
    setLoading(true)
    const values = await form.current.validateFields()
    if (values) dispatch(login(values.email, values.password))
    setTimeout(function () {
      setLoading(false)
    }, 1500)
  }

  return (
    <Form
      ref={form}
      className='login'
      layout='vertical'
      onFinish={handleFormSubmit}
      hideRequiredMark
    >
      <Item
        name='email'
        label={intl.formatMessage({ id: 'email address' })}
        rules={[{ required: true, message: 'this field is required' }]}
      >
        <Input
          disabled={loading}
          autoComplete='username'
          prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={intl.formatMessage({ id: 'email address' })}
        />
      </Item>
      <Item
        name='password'
        label={intl.formatMessage({ id: 'password' })}
        rules={[{ required: true, message: 'this field is required' }]}
      >
        <Input.Password
          disabled={loading}
          autoComplete='current-password'
          prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
          type='password'
          placeholder={intl.formatMessage({ id: 'password' })}
        />
      </Item>
      <Item>
        <Button
          type='primary'
          htmlType='submit'
          disabled={loading}
          loading={loading}
          block
        >
          <span>
            <FormattedMessage id='login' />
          </span>
        </Button>
      </Item>
    </Form>
  )
}

export default FormLogin
