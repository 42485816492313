import React, { useState } from 'react'
import config from 'config'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import { Upload, Spin } from 'antd'
import { useIntl } from 'react-intl'
import { InboxOutlined } from '@ant-design/icons'

import FileItem from './FileItem'
import UploadWrapper from './UploadWrapper'

const { Dragger } = Upload

const FileUpload = ({
  value,
  accept,
  loading,
  multiple,
  onChange,
  onRemove,
  onUpload,
  disabled,
  onDownload,
  defaultValue,
  renderItem: FileItem
}) => {
  const intl = useIntl()
  const [state, setState] = useState(defaultValue || [])

  const handleRemove = async file => {
    await onRemove(file)
    const handler = typeof onChange === 'function' ? onChange : setState
    handler(files.filter(({ _id }) => _id !== file._id))
  }

  const handleDownload = file => {
    if (typeof onDownload === 'function') return onDownload(file)
    const { fileUrl, filename } = file || {}
    const { server: { url } = {} } = config || {}
    saveAs(`${url}${fileUrl}`, filename)
  }

  const beforeUpload = file => {
    ;(async () => {
      const item = await onUpload(file)
      const handler = typeof onChange === 'function' ? onChange : setState
      handler([item, ...files])
    })()

    return false
  }

  const files = value || state

  return (
    <UploadWrapper>
      <Spin size='large' spinning={loading}>
        <Dragger
          fileList={[]}
          accept={accept}
          disabled={disabled}
          multiple={multiple}
          beforeUpload={beforeUpload}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            {intl.formatMessage({ id: 'clickToUpload' })}
          </p>
          <p className='ant-upload-hint'>
            {intl.formatMessage({ id: 'fileSupportInfo' })}
          </p>
        </Dragger>
      </Spin>
      <div className='file-upload-list-cover'>
        {files.map((file, index) => (
          <FileItem
            key={index}
            file={file}
            disabled={disabled}
            onRemove={handleRemove}
            onDownload={handleDownload}
          />
        ))}
      </div>
    </UploadWrapper>
  )
}

FileUpload.propTypes = {
  value: PropTypes.array,
  accept: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDownload: PropTypes.func,
  defaultValue: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  renderItem: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
    .isRequired
}

FileUpload.defaultProps = {
  loading: false,
  disabled: false,
  multiple: false,
  defaultValue: [],
  renderItem: FileItem
}

export default FileUpload
