import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { UploadOutlined } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Modal, notification, Upload } from 'antd'

import useApi from '../../../hooks/useApi'
import defectsService from '../../../services/defects'
import Popconfirm from '../../../components/Popconfirm'

const ImportModal = ({ visible, onClose }) => {
  const intl = useIntl()
  const [files, setFiles] = useState([])
  const [force, setForce] = useState(false)
  const [uploading, uploadCSV] = useApi(defectsService.uploadCSV)

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 2

    if (isLt2M) {
      setFiles([file])
    } else {
      notification.error({
        message:
          intl.formatMessage({
            id: 'image must smaller than'
          }) + ' 2MB'
      })
    }

    return false
  }

  const handleUpload = async () => {
    const [file] = files
    try {
      const response = await uploadCSV(file, force)
      if (response.success) {
        notification.success({
          message: intl.formatMessage({
            id: 'file has been imported successfully'
          })
        })

        return onClose(true)
      } else {
        const { message = intl.formatMessage({ id: 'uploading error' }) } =
          response || {}
        notification.error({ message })
        setForce(true)
      }
    } catch (e) {
      notification.error({
        description: e.error || e.message,
        message: intl.formatMessage({ id: 'uploading error' })
      })
    }
  }

  useLayoutEffect(() => {
    if (!visible) {
      setFiles([])
      setForce(false)
    }
  }, [visible])

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={() => onClose()}
      title={intl.formatMessage({ id: 'upload csv' })}
      footer={
        force ? (
          <Popconfirm
            onConfirm={handleUpload}
            title={intl.formatMessage({ id: 'confirm overwriting' })}
            okText={intl.formatMessage({ id: 'overwrite' })}
            cancelText={intl.formatMessage({ id: 'cancel' })}
          >
            <Button
              type='danger'
              loading={uploading}
              disabled={uploading || files.length === 0}
            >
              <FormattedMessage id='force import' />
              <UploadOutlined />
            </Button>
          </Popconfirm>
        ) : (
          <Button
            type='primary'
            loading={uploading}
            onClick={handleUpload}
            disabled={uploading || files.length === 0}
          >
            <FormattedMessage id='import' />
            <UploadOutlined />
          </Button>
        )
      }
    >
      <Upload
        fileList={files}
        beforeUpload={beforeUpload}
        onRemove={() => setFiles([])}
      >
        <Button>
          <FormattedMessage id='select csv file' />
        </Button>
      </Upload>
    </Modal>
  )
}

ImportModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default ImportModal
