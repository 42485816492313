import config from 'config'
import axios from '../helpers/axios'

export default {
  async list (params = {}) {
    return axios.get(config.server.host + '/1.1/scheduled-workorder', params)
  },
  async addWorkOrder (data) {
    const response = await axios.post('/scheduled-workorder', data)
    return response
  },
  async updateWorkOrder (id, data) {
    if (data.status) {
      delete data.status
    }
    const response = await axios.put(`/scheduled-workorder/${id}`, data)
    return response
  },
  async deleteWorkOrder (id) {
    const response = await axios.delete(`/scheduled-workorder/${id}`)
    return response
  }
}
