import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { Input, Switch } from 'antd'
import { Form } from '@ant-design/compatible'

const { Item } = Form

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const SpecialWorkForm = props => {
  const intl = useIntl()
  const { form, item, updateStateSpecialWork } = props

  return (
    <Form {...formItemLayout}>
      <Item label={intl.formatMessage({ id: 'enabled' })}>
        <Switch
          checked={item.enabled}
          onChange={value => props.updateStateSpecialWork('enabled', value)}
        />
      </Item>

      <Item label={intl.formatMessage({ id: 'name' })}>
        {form.getFieldDecorator('name', {
          initialValue: item.name,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'this field is required'
              })
            }
          ]
        })(
          <Input
            placeholder={intl.formatMessage({ id: 'name' })}
            onChange={event =>
              updateStateSpecialWork('name', event.target.value)
            }
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'title' })}>
        {form.getFieldDecorator('title', {
          initialValue: item.title,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'this field is required'
              })
            }
          ]
        })(
          <Input
            placeholder={intl.formatMessage({ id: 'title' })}
            onChange={event =>
              updateStateSpecialWork('title', event.target.value)
            }
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'description' })}>
        {form.getFieldDecorator('description', {
          initialValue: item.description,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'this field is required'
              })
            }
          ]
        })(
          <Input.TextArea
            rows={8}
            placeholder={intl.formatMessage({ id: 'description' })}
            onChange={event =>
              updateStateSpecialWork('description', event.target.value)
            }
          />
        )}
      </Item>
    </Form>
  )
}

SpecialWorkForm.propTypes = {
  updateStateSpecialWork: PropTypes.func.isRequired,
  form: PropTypes.object,
  item: PropTypes.object
}

export default connect()(SpecialWorkForm)
