import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import FormObjectType from './FormObjectType'
import objectTypesService from '../../../services/objectTypes'
import Popconfirm from '../../../components/Popconfirm'

const ObjectTypeManagement = ({ visible, objectType, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createObjectType] = useApi(objectTypesService.addObjectType)
  const [updating, updateObjectType] = useApi(
    objectTypesService.updateObjectType
  )
  const [archiving, archiveObjectType] = useApi(
    objectTypesService.archiveObjectType
  )

  const handleSubmit = async () => {
    try {
      const { _id } = objectType || {}
      const values = await form.current.validateFields()
      onSubmit(
        await (_id ? updateObjectType(_id, values) : createObjectType(values))
      )
    } catch (error) {}
  }

  const handleArchive = async () =>
    onSubmit(await archiveObjectType(objectType._id))

  const loading = creating || updating || archiving

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && objectType) {
    drawerButtons.unshift(
      <Popconfirm
        title={intl.formatMessage({
          id: 'do you want to archive this entry?'
        })}
        value={<FormattedMessage id='archive' />}
        onConfirmAction={handleArchive}
      />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${objectType ? 'edit' : 'add'} objectType`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormObjectType ref={form} objectType={objectType} />
    </Drawer>
  )
}

ObjectTypeManagement.propTypes = {
  objectType: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ObjectTypeManagement.defaultProps = {
  visible: false
}

export default ObjectTypeManagement
