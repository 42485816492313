import config from 'config'
import axios from '../helpers/axios'

export default {
  async getList () {
    const response = await axios.get('/field-definition')
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/field-definition', params)
  },
  async add (data) {
    const response = await axios.post('/field-definition', data)
    return response
  },
  async archive (id) {
    const response = await axios.put(`/field-definition/${id}`, {
      archived: true
    })
    return response
  },
  async update (id, data) {
    const response = await axios.put(`/field-definition/${id}`, data)
    return response
  }
}
