import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { Table, Button, Tag } from 'antd'
import { Icon } from '@ant-design/compatible'
import { useIntl, FormattedMessage } from 'react-intl'

import useApi from '../../../hooks/useApi'
import searchColumn from '../../../utils/searchColumn'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'
import ChecklistManagement from './ChecklistManagement'
import checklistService from '../../../services/checklist'

const ChecklistPage = () => {
  const intl = useIntl()
  const input = useRef()
  const [data, setData] = useState({})
  const [checklist, setChecklist] = useState()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, fetchChecklist] = useApi(checklistService.listV2)

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })
  const loadData = async () => {
    const response = await fetchChecklist({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = checklist => {
    setChecklist(checklist)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setChecklist()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const { filterOptions: { createdBy: createdByFilters = [] } = {} } = data

  const columns = [
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input, intl, value: filters.name })
    },
    {
      title: intl.formatMessage({ id: 'fields' }),
      key: 'fields',
      dataIndex: 'fields',
      sorter: true,
      width: 600,
      render: fields => {
        return fields.map(value => <Tag key={value.key}>{value.label}</Tag>)
      }
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      sortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { _id, firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: _id
        }
      }),
      filteredValue: filters.createdBy || [],
      sortOrder: sortField === 'createdBy' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'archived' }),
      key: 'archived',
      dataIndex: 'archived',
      align: 'center',
      render: active =>
        active && <Icon type='check' style={{ color: '#005591' }} />,
      filters: [
        {
          text: intl.formatMessage({
            id: 'show all'
          }),
          value: 'showAll'
        },
        {
          text: intl.formatMessage({
            id: 'show unarchived'
          }),
          value: 'showUnarchived'
        },
        {
          text: intl.formatMessage({
            id: 'show archived'
          }),
          value: 'showArchived'
        }
      ],
      filterMultiple: false,
      filteredValue: filters.archived || ['showUnarchived']
    }
  ]

  columns.push({
    width: 65,
    align: 'right',
    key: 'actions',
    render: record => (
      <Button onClick={() => handleOpen(record)}>
        <Icon
          type={record.isVerified || record.isInvalid ? 'info-circle' : 'edit'}
        />
      </Button>
    )
  })

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.checklists'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'checklists' })}
        buttons={[
          <Button
            type='primary'
            key='create_checklist'
            onClick={() => handleOpen({})}
          >
            <FormattedMessage id='create checklist' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      <ChecklistManagement
        visible={drawerVisible}
        checklist={checklist}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  )
}

export default ChecklistPage
