import React, { forwardRef } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Form, Input, Select } from 'antd'

import useProjects from '../../../hooks/useProjects'

const { Item } = Form
const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormContractSection = forwardRef(({ contractSection }, ref) => {
  const intl = useIntl()

  const { name, project } = contractSection || {}
  const values = { name, project: project && project._id }

  const {
    loading: loadingProjects,
    fetch: fetchProjects,
    items: projects
  } = useProjects()

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Item
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'set a name'
          })}
        />
      </Item>

      <Item name='project' label={intl.formatMessage({ id: 'project' })}>
        <Select
          showSearch
          allowClear
          filterOption={false}
          loading={loadingProjects}
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({
            id: 'choose a project'
          })}
          onSearch={debounce(fetchProjects, 500)}
        >
          {projects.map(({ _id, projectName }) => (
            <Option key={_id}>{projectName}</Option>
          ))}
        </Select>
      </Item>
    </StyledForm>
  )
})

FormContractSection.propTypes = {
  contractSection: PropTypes.object
}

export default FormContractSection
