import React, { forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Select, Switch } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'

const { Item } = Form
const { Option } = Select
const { Password } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormUser = forwardRef(({ user }, ref) => {
  const intl = useIntl()
  const didMount = useRef(false)

  const [form] = Form.useForm()
  const { validateFields } = form
  const [passwordRequired, setPasswordRequired] = useState(!user)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return
    }
    validateFields(['password', 'retypePassword'])
  }, [passwordRequired])

  const { scope } = user || {}
  const defaultValues = { activated: true }

  return (
    <StyledForm
      ref={ref}
      form={form}
      {...formLayout}
      initialValues={
        user
          ? {
              ...user,
              scope: Array.isArray(scope) ? scope[0] : scope
            }
          : defaultValues
      }
      onValuesChange={(changed, { password, retypePassword }) => {
        setPasswordRequired(!user || !!password || !!retypePassword)
      }}
    >
      <Item
        name='email'
        label={intl.formatMessage({ id: 'email' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'set an email address'
          })}
        />
      </Item>

      <Item name='firstName' label={intl.formatMessage({ id: 'first name' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a first name'
          })}
        />
      </Item>
      <Item name='lastName' label={intl.formatMessage({ id: 'last name' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a last name'
          })}
        />
      </Item>
      <Item name='function' label={intl.formatMessage({ id: 'function' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a function'
          })}
        />
      </Item>
      <Item
        name='employeeNumber'
        label={intl.formatMessage({ id: 'employee number' })}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'set an employee number'
          })}
        />
      </Item>
      <Item
        name='password'
        label={intl.formatMessage({ id: 'password' })}
        rules={[
          {
            required: passwordRequired,
            message: intl.formatMessage({
              id: 'please set a password'
            })
          },
          { min: 4, max: 200 }
        ]}
        hasFeedback={passwordRequired}
      >
        <Password
          placeholder={intl.formatMessage({
            id: 'set a password'
          })}
        />
      </Item>
      <Item
        name='retypePassword'
        dependencies={['password']}
        label={intl.formatMessage({ id: 'retype password' })}
        rules={[
          {
            required: passwordRequired,
            message: intl.formatMessage({
              id: 'please confirm your password'
            })
          },
          { min: 4, max: 200 },
          ({ getFieldValue }) => ({
            async validator (rule, value) {
              if (!value || value.length < 4 || value.length > 200) return
              if (passwordRequired && getFieldValue('password') !== value) {
                throw intl.formatMessage({
                  id: 'the two passwords that you entered do not match'
                })
              }
            }
          })
        ]}
        hasFeedback={passwordRequired}
      >
        <Password
          placeholder={intl.formatMessage({
            id: 'confirm password'
          })}
        />
      </Item>
      <Item name='phone' label={intl.formatMessage({ id: 'phone' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set phone'
          })}
        />
      </Item>
      <Item
        name='scope'
        label={intl.formatMessage({ id: 'roles' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Select
          placeholder={intl.formatMessage({
            id: 'set roles'
          })}
        >
          <Option value='user'>
            <FormattedMessage id='standard user' />
          </Option>
          <Option value='admin'>
            <FormattedMessage id='administrator' />
          </Option>
          <Option value='superadmin'>
            <FormattedMessage id='superadmin' />
          </Option>
        </Select>
      </Item>
      <Item
        name='activated'
        valuePropName='checked'
        label={intl.formatMessage({ id: 'activated' })}
      >
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Item>
    </StyledForm>
  )
})

FormUser.propTypes = {
  user: PropTypes.object
}

export default FormUser
