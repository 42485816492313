import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Table, Button } from 'antd'
import { Helmet } from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import ProductManagement from './ProductManagement'
import searchColumn from '../../../utils/searchColumn'
import PriceInput from '../../../components/PriceInput'
import productService from '../../../services/products'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'
import ManufacturerManagement from '../Manufacturers/ManufacturerManagement'
import CategoryManagement from '../Categories/CategoryManagement'

const ProductsPage = () => {
  const intl = useIntl()
  const input = useRef()
  const [product, setProduct] = useState()
  const [data, setData] = useState({})
  const [loading, fetchProducts] = useApi(productService.listV2)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerManufacturerVisible, setDrawerManufacturerVisible] = useState(
    false
  )
  const [drawerCategoryVisible, setDrawerCategoryVisible] = useState(false)

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchProducts({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = product => {
    setProduct(product)
    setDrawerVisible(true)
  }

  const handleManufacturerOpen = () => {
    setDrawerManufacturerVisible(true)
  }

  const handleCategoryOpen = () => {
    setDrawerCategoryVisible(true)
  }

  const handleClose = () => {
    setProduct()
    setDrawerVisible(false)
  }

  const handleManufacturerClose = manufacturer => {
    setProduct({ ...product, manufacturer })
    setDrawerManufacturerVisible(false)
  }

  const handleCategoryClose = category => {
    setProduct({ ...product, category })
    setDrawerCategoryVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  const handleManufacturerSubmit = manufacturer => {
    handleManufacturerClose(manufacturer)
  }

  const handleCategorySubmit = category => {
    handleCategoryClose(category)
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const {
    filterOptions: {
      category: categoryFilters = [],
      createdBy: createdByFilters = [],
      manufacturer: manufacturerFilters = []
    } = {}
  } = data

  const columns = [
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input, intl, value: filters.name })
    },
    {
      title: intl.formatMessage({ id: 'article number' }),
      dataIndex: 'ordernumber'
    },
    {
      title: intl.formatMessage({ id: 'manufacturer' }),
      key: 'manufacturer',
      dataIndex: 'manufacturer',
      filterMultiple: false,
      filters: manufacturerFilters.map(({ _id, name }) => ({
        text: name,
        value: _id
      })),
      defaultFilteredValue: filters.manufacturer,
      render: manufacturer => manufacturer && manufacturer.name
    },
    {
      title: intl.formatMessage({ id: 'category' }),
      key: 'category',
      dataIndex: 'category',
      filters: categoryFilters.map(({ _id, name }) => ({
        text: name,
        value: _id
      })),
      sorter: true,
      defaultFilteredValue: filters.category,
      defaultSortOrder: sortField === 'category' && sortOrder,
      render: category => category && category.name
    },
    {
      title: intl.formatMessage({ id: 'active' }),
      dataIndex: 'active',
      render: active => active && <CheckOutlined style={{ color: '#005591' }} />
    },
    {
      title: intl.formatMessage({ id: 'price' }),
      dataIndex: 'price',
      render: price => (
        <PriceInput
          value={price}
          displayType='text'
          renderText={value => `CHF ${value}`}
        />
      ),
      sorter: true,
      defaultSortOrder: sortField === 'price' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      render: value => {
        const { firstName = '', lastName = '' } = value || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { _id, firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: _id
        }
      }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    },
    {
      width: 65,
      align: 'right',
      key: 'actions',
      render: record => (
        <Button onClick={() => handleOpen(record)}>
          <EditOutlined />
        </Button>
      )
    }
  ]

  const dataSource = data.docs || []

  const renderManufacturerManagement = () => (
    <ManufacturerManagement
      visible={drawerManufacturerVisible}
      onSubmit={handleManufacturerSubmit}
      onClose={handleManufacturerClose}
    />
  )

  const renderCategoryManagement = () => (
    <CategoryManagement
      visible={drawerCategoryVisible}
      onSubmit={handleCategorySubmit}
      onClose={handleCategoryClose}
    />
  )

  return (
    <div>
      <FormattedMessage id='head.title.products'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'products' })}
        buttons={[
          <Button
            type='primary'
            key='create_product'
            onClick={() => handleOpen()}
          >
            <FormattedMessage id='create product' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        scroll={{ x: true }}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      <ProductManagement
        product={product}
        visible={drawerVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
        createManufacturer={handleManufacturerOpen}
        createCategory={handleCategoryOpen}
        manufacturerManagement={renderManufacturerManagement}
        categoryManagement={renderCategoryManagement}
      />
    </div>
  )
}

export default ProductsPage
