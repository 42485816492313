import React from 'react'

import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'

import { Button, notification, Tabs } from 'antd'
import { Form } from '@ant-design/compatible'

import ObjectForm from './FormObject'
import locationService from 'services/location'
import DrawerComponent from 'components/Drawer'
import PropTypes from 'prop-types'
import Specification from './Specification'
import projectService from '../../../services/project'
import PhotosTab from './Photos'
import ImageService from '../../../services/image'

const { TabPane } = Tabs

export class ObjectsDrawer extends React.Component {
  state = {
    projects: [],
    updatedImages: []
  }

  fetchLocations = async value => {
    const { selectedProject } = this.props
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const params = {
          name: [value],
          limit: 100
        }
        if (selectedProject && selectedProject._id) {
          params.project = selectedProject._id
        }
        const response = await locationService.listV2(params)

        this.setState({
          locations: response.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  updateObject = (field, value) => {
    let name = this.props.object.name

    if (field === 'name') {
      name = value
    }

    this.props.form.setFieldsValue({
      name
    })

    this.props.updateObject(field, value)
  }

  fetchProjects = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  updateImage = image => {
    const { updatedImages } = this.state

    const updatedList = [
      ...updatedImages.filter(item => item._id !== image._id),
      image
    ]
    this.setState({
      updatedImages: updatedList
    })
  }

  handleSaveObject = async form => {
    const { updatedImages } = this.state
    try {
      const promises = updatedImages.map(({ _id, comment }) => {
        const formData = new FormData()
        formData.append('comment', comment)
        return ImageService.update(_id, formData)
      })
      await Promise.all(promises)
      this.setState({
        updatedImages: []
      })
      this.props.handleSaveObject(form)
    } catch (e) {
      notification.error({
        message: 'Images upload Error',
        description: e.error
      })
    }
  }

  handleHideDrawer = () => {
    this.setState({
      updatedImages: []
    })
    this.props.handleHideDrawer()
  }

  render () {
    const { object, visible, form } = this.props
    const {
      submitting,
      projects,
      locations,
      formLoading,
      readOnlyNewProject
    } = this.state
    const drawerButtons = [
      <Button
        key={637343432}
        onClick={this.handleHideDrawer}
        style={{ marginRight: 8 }}
      >
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        key={843262447}
        onClick={() => this.handleSaveObject(form)}
        type='primary'
        loading={submitting}
        disabled={submitting}
      >
        <FormattedMessage id='save' />
      </Button>
    ]

    return (
      <>
        <DrawerComponent
          title={
            object._id
              ? object.name
              : this.props.intl.formatMessage({ id: 'create object' })
          }
          onClose={this.props.handleHideDrawer}
          visible={!!visible}
          footerButtons={drawerButtons}
        >
          <Tabs style={{ paddingBottom: 50 }} defaultActiveKey='form'>
            <TabPane
              tab={this.props.intl.formatMessage({
                id: 'informations'
              })}
              key='form'
            >
              <ObjectForm
                object={object}
                projects={projects}
                formLoading={formLoading}
                readOnlyNewProject={readOnlyNewProject}
                fetchProjects={this.fetchProjects}
                fetchLocations={this.fetchLocations}
                updateObject={this.updateObject}
                locations={locations}
                objectName={
                  (this.props.settings.data.buildx &&
                    this.props.settings.data.buildx.objectName) ||
                  ''
                }
                form={this.props.form}
              />
            </TabPane>

            {object.images && object.images.length > 0 && (
              <TabPane
                tab={this.props.intl.formatMessage({ id: 'photos' })}
                key='photos'
              >
                <PhotosTab object={object} updateImage={this.updateImage} />
              </TabPane>
            )}

            {object.objectType &&
              object.objectType.fields &&
              object.objectType.fields.length && (
                <TabPane
                  tab={this.props.intl.formatMessage({
                    id: 'specification'
                  })}
                  key='extraFields'
                >
                  <Specification
                    updateObject={this.updateObject}
                    object={object}
                  />
                </TabPane>
              )}
          </Tabs>
        </DrawerComponent>
      </>
    )
  }
}

ObjectsDrawer.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  selectedProject: PropTypes.object,
  settings: PropTypes.object.isRequired,
  object: PropTypes.object,
  updateObject: PropTypes.func,
  visible: PropTypes.bool,
  handleHideDrawer: PropTypes.func,
  handleSaveObject: PropTypes.func
}

const mapStateToProps = state => ({
  settings: state.settings,
  projects: state.project.projectList,
  selectedProject: state.project.pickedProject
})

export default injectIntl(
  Form.create({ name: 'object_form' })(connect(mapStateToProps)(ObjectsDrawer))
)
