import React, { forwardRef, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Zoom from 'react-medium-image-zoom'
import { useIntl, FormattedMessage } from 'react-intl'
import { Form, Input, Radio, Select, Tabs } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'

import config from '../../../config'
import useObjects from '../../../hooks/useObjects'
import useChecklists from '../../../hooks/useChecklists'

const { Item } = Form
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormRequest = forwardRef(
  ({ request, disabled, onObject, onChecklist }, ref) => {
    const intl = useIntl()
    const [form] = Form.useForm()
    const { setFieldsValue } = form

    const {
      name,
      objectId,
      checklist,
      description,
      images = [],
      priority = 0,
      extra_fields: extraFields = []
    } = request || {}

    const objectName = useSelector(
      ({
        settings: {
          data: { buildx: { objectName = 'object number' } = {} }
        }
      }) => objectName
    )

    const defaultChecklist = useSelector(
      ({
        settings: {
          data: { mainx: { checklist } = {} }
        }
      }) => checklist
    )

    const values = {
      name,
      priority,
      description,
      objectId: objectId && objectId._id,
      checklist: (checklist || defaultChecklist || {})._id
    }

    const {
      loading: loadingObjects,
      fetch: fetchObjects,
      items: objects
    } = useObjects(objectId, { fetchOnMount: !disabled })

    const {
      loading: loadingChecklists,
      fetch: fetchChecklists,
      items: checklists
    } = useChecklists(checklist || defaultChecklist, {
      fetchOnMount: !disabled
    })

    const [checklistId, setChecklistId] = useState(values.checklist)
    const currentChecklist =
      checklists.find(({ _id }) => _id === checklistId) || {}
    const customFields = currentChecklist.fields || []

    useEffect(() => {
      setFieldsValue({
        extra_fields: customFields.map(({ _id }) => ({
          id: _id,
          value: (extraFields.find(({ id }) => id === _id) || {}).value
        }))
      })
      onChecklist(currentChecklist)
    }, [currentChecklist, customFields])
    extraFields.forEach(item => {
      values[`extra_fields_${item.id}`] = item.value
    })

    return (
      <StyledForm
        ref={ref}
        form={form}
        {...formLayout}
        initialValues={values}
        hideRequiredMark={disabled}
      >
        <Tabs defaultActiveKey='information'>
          <TabPane
            key='information'
            tab={intl.formatMessage({ id: 'information' })}
          >
            <Item
              name='name'
              label={intl.formatMessage({ id: 'name' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'this field is required' })
                }
              ]}
            >
              <Input disabled={disabled} />
            </Item>
            <Item
              name='objectId'
              label={intl.formatMessage({
                id: objectName,
                defaultMessage: objectName
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'this field is required' })
                }
              ]}
              getValueFromEvent={objectId => {
                onObject(objects.find(({ _id }) => _id === objectId))
                return objectId
              }}
            >
              <Select
                showSearch
                allowClear
                filterOption={false}
                loading={loadingObjects}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                  id: `choose a ${objectName}`,
                  defaultMessage: `choose a ${objectName}`
                })}
                onSearch={debounce(fetchObjects, 500)}
                disabled={disabled}
              >
                {objects.map(({ _id, name }) => (
                  <Option key={_id}>{name}</Option>
                ))}
              </Select>
            </Item>
            <Item
              name='description'
              label={intl.formatMessage({ id: 'description' })}
            >
              <TextArea disabled={disabled} />
            </Item>
            <Item
              name='priority'
              label={intl.formatMessage({ id: 'priority' })}
            >
              <Radio.Group buttonStyle='solid' disabled={disabled}>
                <Radio.Button value={0}>
                  <FormattedMessage id='none' />
                </Radio.Button>
                <Radio.Button value={1}>
                  <ArrowDownOutlined /> <FormattedMessage id='low' />
                </Radio.Button>
                <Radio.Button value={2}>
                  <FormattedMessage id='medium' />
                </Radio.Button>
                <Radio.Button value={3}>
                  <ArrowUpOutlined /> <FormattedMessage id='high' />
                </Radio.Button>
              </Radio.Group>
            </Item>
          </TabPane>
          <TabPane
            key='checklistFields'
            tab={intl.formatMessage({ id: 'checklist fields' })}
          >
            <Item
              name='checklist'
              label={intl.formatMessage({ id: 'checklist' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'this field is required' })
                }
              ]}
              getValueFromEvent={checklistId => {
                setChecklistId(checklistId)
                return checklistId
              }}
            >
              <Select
                showSearch
                allowClear
                filterOption={false}
                style={{ width: '100%' }}
                loading={loadingChecklists}
                placeholder={intl.formatMessage({
                  id: 'choose a checklist',
                  defaultMessage: 'choose a checklist'
                })}
                onSearch={debounce(fetchChecklists, 500)}
                disabled={disabled}
              >
                {checklists.map(({ _id, name }) => (
                  <Option key={_id}>{name}</Option>
                ))}
              </Select>
            </Item>
            {customFields.map(({ key, label }, index) => (
              <div key={key}>
                <Item name={`extra_fields_${key}`} noStyle>
                  <Input type='hidden' />
                </Item>
                <Item
                  name={`extra_fields_${key}`}
                  label={intl.formatMessage({
                    id: label,
                    defaultMessage: label
                  })}
                >
                  <Input disabled={disabled} />
                </Item>
              </div>
            ))}
          </TabPane>
          {images.length > 0 && (
            <TabPane
              key='pictures'
              tab={intl.formatMessage({ id: 'pictures' })}
            >
              {images.map(({ _id, imageUrl, comment }) => (
                <div key={_id}>
                  <Item label={intl.formatMessage({ id: 'picture' })}>
                    <Zoom zoomMargin={40}>
                      <img
                        alt='picture'
                        src={config.server.url + imageUrl}
                        className='img'
                        style={{ width: '100%' }}
                      />
                    </Zoom>
                  </Item>
                  <Item label={intl.formatMessage({ id: 'comment' })}>
                    <TextArea disabled={disabled} defaultValue={comment} />
                  </Item>
                </div>
              ))}
            </TabPane>
          )}
        </Tabs>
      </StyledForm>
    )
  }
)

FormRequest.propTypes = {
  disabled: PropTypes.bool.isRequired,
  request: PropTypes.object.isRequired,
  onObject: PropTypes.func.isRequired,
  onChecklist: PropTypes.func.isRequired
}

FormRequest.defaultProps = {
  disabled: false,
  onObject: () => {},
  onChecklist: () => {}
}

export default FormRequest
