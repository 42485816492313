import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { PageHeader, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'

import './styles.scss'
import FileList from './List'
import PropTypes from 'prop-types'

const { Dragger } = Upload

class FileUpload extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fileList: [],
      deleteFile: {},
      deleteDrawerVisible: false
    }
    this.uploadCover = React.createRef()
  }

  onDelete = file => {
    const { handleDelete, updateItem, defect } = this.props
    handleDelete(file)
      .then(response => {
        if (response === 'success') {
          this.setState({
            deleteFile: {},
            deleteDrawerVisible: false
          })
        }
        if (
          defect &&
          defect.attachedDocument &&
          defect.attachedDocument._id === file._id
        ) {
          updateItem('attachedDocument', null)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  onChange = info => {
    this.setState({
      image: { ...info.file, fileName: info.file.name }
    })
  }

  customRequest = async option => {
    const { handleAdd } = this.props
    await new Promise(resolve => this.waitUntilImageLoaded(resolve)) // in the next section
    const { image } = this.state
    handleAdd(image)
      .then(response => {
        if (response === 'success') {
          this.setState({
            image: undefined
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  waitUntilImageLoaded = resolve => {
    setTimeout(() => {
      this.state.image
        ? resolve() // from onChange method
        : this.waitUntilImageLoaded(resolve)
    }, 10)
  }

  handleDownload = file => {
    saveAs(file.url, file.filename)
  }

  render () {
    const { files, intl, action, disabled } = this.props

    const draggerProps = {
      listType: 'picture',
      fileList: [],
      beforeUpload: file =>
        this.setState(state => ({
          image: file
        })),
      onPreview: file => {
        this.handleDownload(file)
      }
    }
    return (
      <>
        <PageHeader
          onBack={action}
          title={intl.formatMessage({
            id: 'Upload files'
          })}
        />
        <div className='dragger-cover' ref={this.uploadCover}>
          <Dragger
            disabled={disabled}
            {...draggerProps}
            customRequest={this.customRequest}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              {intl.formatMessage({ id: 'clickToUpload' })}
            </p>
            <p className='ant-upload-hint'>
              {intl.formatMessage({ id: 'fileSupportInfo' })}
            </p>
          </Dragger>
          <FileList
            disabled={disabled}
            files={files || []}
            handleDelete={this.onDelete}
          />
        </div>
      </>
    )
  }
}

FileUpload.propTypes = {
  intl: PropTypes.object.isRequired,
  defect: PropTypes.object,
  handleDelete: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  files: PropTypes.array,
  action: PropTypes.func,
  updateItem: PropTypes.func,
  disabled: PropTypes.bool
}

export default injectIntl(connect()(FileUpload))
