import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ContactsListItem } from './ContactsListItem'
import './styles.scss'
import { Button, Dropdown, Menu, Select, Spin } from 'antd'
import { Icon } from '@ant-design/compatible'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { fetchContacts } from '../../../../ducks/contacts'

const { Option } = Select

class ContactsTab extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      list: [],
      personLinkingShown: false,
      pickedContact: {}
    }
    this.handleInputThrottled = debounce(this.fetchContactsOnSearch, 300)
  }

  handleLinkPerson = () => {
    this.setState({
      personLinkingShown: true
    })
  }

  onPick = pickedContact => {
    this.setState({
      pickedContact
    })
  }

  fetchContactsOnSearch = async searchQueue => {
    const { dispatch } = this.props
    this.setState()
    dispatch(
      fetchContacts(
        {
          'company[]': searchQueue,
          'contact_type[]': 'person',
          limit: 1000,
          sortField: 'company',
          sortOrder: 'ascend'
        },
        true
      )
    ).then(() => {
      this.setState({
        loading: false
      })
    })
  }

  handleSubmitContact = () => {
    const { updateContact, contactsList } = this.props
    const { pickedContact } = this.state
    let duplicatedContact = false

    const outputList = [...contactsList]
    contactsList.forEach(item => {
      const duplicated =
        item.contact_sub &&
        pickedContact.contact_sub &&
        item.contact_sub._id === pickedContact.contact_sub._id &&
        ((item.contact_sup &&
          pickedContact.contact_sup &&
          item.contact_sup._id === pickedContact.contact_sup._id) ||
          (!item.contact_sup && !pickedContact.contact_sup))

      if (duplicated) {
        duplicatedContact = true
      }
    })

    if (!duplicatedContact) {
      outputList.push(pickedContact)
    }
    updateContact('contacts_sub', outputList)
    this.setState({
      personLinkingShown: false,
      pickedContact: {}
    })
  }

  onRemoveContact = contact => {
    const { updateContact, contactsList } = this.props
    updateContact(
      'contacts_sub',
      contactsList.filter(item => item._id !== contact._id)
    )
  }

  render () {
    const { contactsList, handleCreateContact, intl, contacts } = this.props
    const { personLinkingShown, pickedContact, loading } = this.state

    const menu = (
      <Menu>
        <Menu.Item onClick={handleCreateContact}>
          <Icon type='tool' />
          &nbsp;
          <FormattedMessage id='new person' />
        </Menu.Item>
        <Menu.Item onClick={this.handleLinkPerson}>
          <Icon type='export' />
          &nbsp;
          <FormattedMessage id='link to person' />
        </Menu.Item>
      </Menu>
    )
    return (
      <div className='contact-list-cover'>
        {(contactsList || []).map(item => (
          <ContactsListItem
            key={item._id}
            handleRemove={this.onRemoveContact}
            contactData={item}
          />
        ))}
        <Dropdown
          className='add-contacts-action-button'
          overlay={menu}
          key='actions-dropdown'
        >
          <Button>
            <FormattedMessage id='add person' />
            <Icon type='down' style={{ marginLeft: 16 }} />
          </Button>
        </Dropdown>
        {personLinkingShown && (
          <div className='contact-search-cover'>
            <Select
              showSearch
              style={{ width: '100%' }}
              notFoundContent={
                loading && (
                  <div className='project-spinner-container'>
                    <Spin size='small' />
                  </div>
                )
              }
              placeholder={intl.formatMessage({ id: 'select field' })}
              optionFilterProp='children'
              onFocus={() => this.fetchContactsOnSearch('')}
              onSearch={this.handleInputThrottled}
              onChange={(data, event) =>
                this.onPick({ contact_sub: event.props.data })
              }
            >
              {(contacts || []).map(contact => {
                return (
                  <Option
                    key={contact._id}
                    data={{ ...contact }}
                    value={contact._id}
                  >
                    {contact.fullName ||
                      `${contact.firstName || ''} ${contact.lastName || ''}`}
                  </Option>
                )
              })}
            </Select>
            <div className='contact-add-buttons-block'>
              <Button
                className='action-button'
                disabled={
                  !(pickedContact.contact_sub && pickedContact.contact_sub._id)
                }
                onClick={this.handleSubmitContact}
              >
                <Icon type='check' className='ok-button' />
              </Button>
              <Button
                className='action-button'
                onClick={() =>
                  this.setState({
                    personLinkingShown: false,
                    pickedContact: {}
                  })
                }
              >
                <Icon type='close' className='close-button' />
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ContactsTab.propTypes = {
  contactsList: PropTypes.array,
  updateContact: PropTypes.func.isRequired,
  handleCreateContact: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired
}
const mapStateToProps = state => ({
  projects: state.project.searchedProjectsList,
  objects: state.objects.searchedObjectsList,
  contacts: state.contacts.searchedContacts
})

export default connect(mapStateToProps, null)(injectIntl(ContactsTab))
