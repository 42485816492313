import React, { useLayoutEffect, useState } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Input, Modal, notification, Select, Table } from 'antd'

import defectsService from '../../../services/defects'
import useContractSections from '../../../hooks/useContractSections'
import ContactTreeSelect from '../../../components/ContactTreeSelect'

const { Item } = Form
const { Option } = Select

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 12 },
  wrapperCol: { span: 12 }
}

const StyledForm = styled(Form)`
  label {
    margin: 0;
  }
  margin-top: 2rem;
  .ant-form-item {
    margin-bottom: 1rem;
  }
`

const StyledModal = styled(Modal)`
  width: 90% !important;
  max-width: 1200px;
  max-height: 50vh;
`

const statusOptions = ['open', 'in progress', 'completed']

const BulkChangeModal = ({ visible, defects, onClose }) => {
  const intl = useIntl()
  const [names, setNames] = useState({})
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    if (!visible) {
      setNames({})
      setState({})
    }
  }, [visible])

  const {
    loading: loadingContractSections,
    fetch: fetchContractSections,
    items: contractSections
  } = useContractSections()

  const handleSubmit = async () => {
    if (!Object.values(state).some(value => !!value)) return onClose()
    setLoading(true)
    try {
      await Promise.all(
        defects.map(({ _id }) => defectsService.update(_id, state))
      )
      notification.success({
        message: `${intl.formatMessage({
          id: 'updated successfully'
        })}`
      })
      onClose(true)
    } catch (e) {
      notification.error({
        message: intl.formatMessage({ id: 'saving error' }),
        description: e.error || e.message
      })
    }
    setLoading(false)
  }

  const onValuesChange = (changed, values) => {
    // eslint-disable-next-line no-prototype-builtins
    if (changed.hasOwnProperty('contractSection')) {
      const { name: contractSection } =
        contractSections.find(({ _id }) => _id === changed.contractSection) ||
        {}
      setNames({ ...names, contractSection })
    }
    setState(values)
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'number' }),
      dataIndex: 'docNumber',
      ellipsis: true
    },
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: intl.formatMessage({ id: 'assigned to' }),
      dataIndex: 'assignedTo',
      ellipsis: true,
      render: assignedTo =>
        ContactTreeSelect.displayContact(state.assignedTo || assignedTo)
    },
    {
      title: intl.formatMessage({ id: 'protocolReference' }),
      dataIndex: 'protocolReference',
      ellipsis: true,
      render: protocolReference => state.protocolReference || protocolReference
    },
    {
      title: intl.formatMessage({ id: 'protocolResponsible' }),
      dataIndex: 'protocolResponsible',
      ellipsis: true,
      render: protocolResponsible =>
        ContactTreeSelect.displayContact(
          state.protocolResponsible || protocolResponsible
        )
    },
    {
      title: intl.formatMessage({ id: 'protocolRefExt' }),
      dataIndex: 'protocolRefExt',
      ellipsis: true,
      render: protocolRefExt => state.protocolRefExt || protocolRefExt
    },
    {
      title: intl.formatMessage({ id: 'protocolExtResponsible' }),
      dataIndex: 'protocolExtResponsible',
      ellipsis: true,
      render: protocolExtResponsible =>
        ContactTreeSelect.displayContact(
          state.protocolExtResponsible || protocolExtResponsible
        )
    },
    {
      title: intl.formatMessage({ id: 'contract section' }),
      dataIndex: ['contractSection', 'name'],
      ellipsis: true,
      render: contractSection => names.contractSection || contractSection
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'status',
      ellipsis: true,
      render: status => {
        const text = state.status || status
        return text && intl.formatMessage({ id: text })
      }
    }
  ]

  return (
    <StyledModal
      destroyOnClose
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => onClose()}
      okButtonProps={{ loading }}
      okText={<FormattedMessage id='submit' />}
      cancelText={<FormattedMessage id='cancel' />}
      title={<FormattedMessage id='bulk change button' />}
    >
      <Table
        rowKey='_id'
        size='small'
        columns={columns}
        pagination={false}
        tableLayout='fixed'
        dataSource={defects}
      />

      <StyledForm {...formItemLayout} onValuesChange={onValuesChange}>
        <Item
          name='assignedTo'
          label={intl.formatMessage({ id: 'change assignment' })}
        >
          <ContactTreeSelect
            disabled={loading}
            placeholder={intl.formatMessage({ id: 'choose a new contact' })}
          />
        </Item>
        <Item name='status' label={intl.formatMessage({ id: 'change status' })}>
          <Select
            disabled={loading}
            placeholder={intl.formatMessage({ id: 'choose a new status' })}
          >
            {statusOptions.map(status => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name='protocolReference'
          label={intl.formatMessage({ id: 'protocol reference' })}
        >
          <Input
            disabled={loading}
            placeholder={intl.formatMessage({
              id: 'choose a new protocol reference'
            })}
          />
        </Item>
        <Item
          name='protocolResponsible'
          label={intl.formatMessage({ id: 'protocol responsible' })}
        >
          <ContactTreeSelect
            disabled={loading}
            placeholder={intl.formatMessage({
              id: 'choose protocol responsible'
            })}
          />
        </Item>
        <Item
          name='protocolRefExt'
          label={intl.formatMessage({ id: 'protocol reference external' })}
        >
          <Input
            disabled={loading}
            placeholder={intl.formatMessage({
              id: 'choose a new external protocol protocol reference'
            })}
          />
        </Item>
        <Item
          name='protocolExtResponsible'
          label={intl.formatMessage({ id: 'protocolExtResponsible' })}
        >
          <ContactTreeSelect
            disabled={loading}
            placeholder={intl.formatMessage({ id: 'protocolExtResponsible' })}
          />
        </Item>
        <Item
          name='contractSection'
          label={intl.formatMessage({ id: 'contract section' })}
        >
          <Select
            showSearch
            allowClear
            disabled={loading}
            filterOption={false}
            loading={loadingContractSections}
            placeholder={intl.formatMessage({
              id: 'contract section'
            })}
            onSearch={debounce(fetchContractSections, 500)}
          >
            {contractSections.map(({ _id, name }) => (
              <Option key={_id} value={_id}>
                {name}
              </Option>
            ))}
          </Select>
        </Item>
      </StyledForm>
    </StyledModal>
  )
}

BulkChangeModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  defects: PropTypes.array.isRequired
}

export default BulkChangeModal
