import React from 'react'
import PropTypes from 'prop-types'

import config from 'config'
import { Icon } from '@ant-design/compatible'
import { injectIntl } from 'react-intl'
import Popconfirm from '../../../components/Popconfirm'
import { saveAs } from 'file-saver'
import '../styles.scss'

import moment from 'moment'

export class ListItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      comment: ''
    }
  }

  handleDownload = () => {
    const { fileData } = this.props
    saveAs(
      config.server.url + (fileData.imageUrl || fileData.fileUrl),
      fileData.filename
    )
  }

  render () {
    const { handleDelete, fileData, disabled } = this.props
    let imageSrc = ''
    if (fileData.type === 'image/png' || fileData.type === 'image/jpg') {
      imageSrc = config.server.url + (fileData.imageUrl || fileData.fileUrl)
    } else if (fileData.type === 'text/csv') {
      imageSrc = 'images/file.svg'
    } else if (fileData.type === 'application/pdf') {
      imageSrc = 'images/pdf.svg'
    } else {
      imageSrc = 'images/doc.svg'
    }
    return (
      <div onClick={this.handleDownload} className='file-upload-list-item'>
        <div className='file-image-cover'>
          <img src={imageSrc} alt='uploaded by  user' />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            height: '100%',
            lineHeight: 1.2,
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <p style={{ width: '100%', marginBottom: 0 }}>
            <b>{fileData.filename}</b>
          </p>
          <p style={{ width: '100%', marginBottom: 0 }}>
            {moment(fileData.createdAt).format('DD.MM.YYYY, HH:mm')}
          </p>
          <p style={{ marginBottom: 0 }}>{fileData.createdBy.fullName}</p>
        </div>
        <div
          onClick={event => event.stopPropagation()}
          className='confirm-delete-cover'
        >
          <Popconfirm
            style={{ float: 'none', textAlign: 'center' }}
            readOnly={disabled}
            value={
              <Icon
                type='delete'
                style={{ color: '#196b9e', fontSize: 16, marginLeft: 4 }}
              />
            }
            onConfirmAction={() => handleDelete(fileData)}
          />
        </div>
      </div>
    )
  }
}

ListItem.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  fileData: PropTypes.object,
  disabled: PropTypes.bool
}

export default injectIntl(ListItem)
