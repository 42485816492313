import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { Input, Radio, Select, Switch, Spin } from 'antd'
import { Icon, Form } from '@ant-design/compatible'
import AddButton from 'components/WorkOrders/AddButton'
import '../styles.scss'
import PropTypes from 'prop-types'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

const { Option } = Select

class Informations extends React.Component {
  renderSelect = array => {
    // eslint-disable-next-line no-unused-vars
    let key = 0
    const onlyEnabled = array.filter(item => item.enabled === true)
    const selectItems = onlyEnabled.map(item => {
      key += 1
      return (
        <Option key={item._id} id={item._id} value={item.name}>
          {item.name}
        </Option>
      )
    })

    const specialWork = this.props.workOrder.specialWork || []
    const specialWorkNames = specialWork.map(item => {
      return item.name
    })

    return (
      <Select
        mode='multiple'
        autoClearSearchValue
        defaultValue={specialWorkNames}
        notFoundContent={
          this.props.formLoading ? (
            <div className='workorder-spinner-container'>
              <Spin size='small' />
            </div>
          ) : null
        }
        style={{ width: '100%' }}
        placeholder={this.props.intl.formatMessage({
          id: 'choose special work'
        })}
        onFocus={() => this.props.fetchSpecialWork('')}
        onSearch={value => this.props.fetchSpecialWork(value)}
        onChange={(value, e) => this.assignSpecialWorkHandler(value, e)}
      >
        {selectItems}
      </Select>
    )
  }

  assignSpecialWorkHandler = (value, e) => {
    this.props.updateStateSpecialWork(value)
  }

  onPriorityChanged = e => {
    let priority = 0
    switch (e.target.value) {
      case 'low':
        priority = 1
        break
      case 'medium':
        priority = 2
        break
      case 'high':
        priority = 3
        break
      default:
        priority = 0
    }

    this.props.updateStateWorkOrder('priority', priority)
  }

  setPriorityDefaultValue = () => {
    switch (this.props.workOrder.priority) {
      case 0:
        return 'none'
      case 1:
        return 'low'
      case 2:
        return 'medium'
      case 3:
        return 'high'
      default:
        return 'none'
    }
  }

  assignUserHandler = value => {
    const { updateAssignedUser } = this.props
    if (value === undefined) {
      updateAssignedUser(null)
      return false
    }
    updateAssignedUser(value.props.data)
  }

  renderUserSelect = () => {
    const { workOrder, formLoading, fetchUsersHandler, users } = this.props

    return (
      <Select
        showSearch
        allowClear
        value={
          workOrder.executedBy && workOrder.executedBy.fullName
            ? `${workOrder.executedBy.fullName} ( ${workOrder.executedBy.email} )`
            : ''
        }
        style={{ width: '100%' }}
        optionFilterProp='children'
        notFoundContent={
          formLoading ? (
            <div className='workorder-spinner-container'>
              <Spin size='small' />
            </div>
          ) : null
        }
        onSearch={fetchUsersHandler}
        onChange={(value, e) => this.assignUserHandler(e)}
        onFocus={() => fetchUsersHandler('')}
        filterOption={(input, option) => {
          const cuttedValue = option.props.value.substring(
            0,
            option.props.value.indexOf('(')
          )
          return cuttedValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
      >
        {users.map((user, i) => (
          <Option
            key={i}
            data={user}
            value={`${user.fullName} ( ${user.email} )`}
          >
            {`${user.fullName} (${user.email})`}
          </Option>
        ))}
      </Select>
    )
  }

  render () {
    const {
      action,
      workOrder,
      intl,
      updateStateWorkOrder,
      specialWork
    } = this.props

    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Form.Item {...formItemLayout} label='Active'>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={workOrder.active}
            onChange={value => updateStateWorkOrder('active', value)}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label='Name'>
          {getFieldDecorator('name', {
            initialValue: workOrder.name,
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'this field is required'
                })
              }
            ]
          })(
            <Input
              onChange={event =>
                updateStateWorkOrder('name', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'description'
          })}
        >
          <Input.TextArea
            value={workOrder.description}
            onChange={event =>
              updateStateWorkOrder('description', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'assignee'
          })}
        >
          {this.renderUserSelect()}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'priority'
          })}
        >
          <Radio.Group
            defaultValue={this.setPriorityDefaultValue()}
            buttonStyle='solid'
          >
            <Radio.Button
              active
              value='none'
              onChange={e => this.onPriorityChanged(e)}
            >
              {intl.formatMessage({ id: 'none' })}
            </Radio.Button>
            <Radio.Button value='low' onChange={e => this.onPriorityChanged(e)}>
              <Icon type='arrow-down' />
              {intl.formatMessage({ id: 'low' })}
            </Radio.Button>
            <Radio.Button
              value='medium'
              onChange={e => this.onPriorityChanged(e)}
            >
              <Icon type='arrow-down' />
              {intl.formatMessage({ id: 'medium' })}
            </Radio.Button>
            <Radio.Button
              value='high'
              onChange={e => this.onPriorityChanged(e)}
            >
              <Icon type='arrow-up' />
              {intl.formatMessage({ id: 'high' })}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'special work required'
          })}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={workOrder.specialWorkRequired}
            onChange={value =>
              updateStateWorkOrder('specialWorkRequired', value)
            }
          />
        </Form.Item>

        {workOrder.specialWorkRequired && (
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'select special work'
            })}
          >
            {this.renderSelect(specialWork)}
          </Form.Item>
        )}

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'signature required'
          })}
        >
          <Switch
            checked={workOrder.signatureRequired}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
            onChange={value => updateStateWorkOrder('signatureRequired', value)}
          />
        </Form.Item>
        {workOrder._id && (
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'archive'
            })}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={workOrder.archived}
              defaultChecked
              onChange={value => updateStateWorkOrder('archived', value)}
            />
          </Form.Item>
        )}

        <AddButton
          title={intl.formatMessage({ id: 'manage tasks' })}
          action={action}
          avatar={
            <Icon
              type='solution'
              theme='outlined'
              style={{ fontSize: '33px', color: '#196b9e' }}
            />
          }
          description={intl.formatMessage({
            id: 'manage tasks description'
          })}
        />
      </>
    )
  }
}

Informations.propTypes = {
  intl: PropTypes.object.isRequired,
  workOrder: PropTypes.object.isRequired,
  formLoading: PropTypes.bool,
  fetchSpecialWork: PropTypes.func.isRequired,
  updateStateSpecialWork: PropTypes.func.isRequired,
  updateStateWorkOrder: PropTypes.func.isRequired,
  updateAssignedUser: PropTypes.func.isRequired,
  fetchUsersHandler: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  specialWork: PropTypes.array,
  form: PropTypes.object.isRequired,
  auth: PropTypes.object,
  objectName: PropTypes.object,
  action: PropTypes.func
}

export default injectIntl(connect()(Informations))
