import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import FormWorkOrder from './FormWorkOrder'
import Drawer from '../../../components/Drawer'
import tasksService from '../../../services/tasks'
import workOrdersService from '../../../services/workOrders'

const WorkOrderManagement = ({ request, visible, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()

  const [creatingTask, createTask] = useApi(tasksService.saveTask)
  const [creatingWorkOreder, createWorkOrder] = useApi(
    workOrdersService.addWorkOrder
  )

  const handleSubmit = async () => {
    try {
      const { tasks = [], ...workOrder } = await form.current.validateFields()
      const { _id: workorder } = await createWorkOrder(workOrder)
      for (const task of tasks) await createTask({ workorder, ...task })
      onSubmit()
    } catch (e) {}
  }

  const loading = creatingTask || creatingWorkOreder

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  return (
    <Drawer
      title={intl.formatMessage({ id: 'create workorder' })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormWorkOrder ref={form} request={request} />
    </Drawer>
  )
}

WorkOrderManagement.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired
}

WorkOrderManagement.defaultProps = {
  visible: false
}

export default WorkOrderManagement
