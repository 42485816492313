import config from 'config'
import axios from '../helpers/axios'

export default {
  async getList () {
    const response = await axios.get('/project')
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/project', params)
  },
  async addProject (data) {
    const response = await axios.post('/project', data)
    return response
  },
  async archiveProject (id) {
    const response = await axios.put(`/project/${id}`, { archived: true })
    return response
  },
  async updateProject (id, data) {
    const response = await axios.put(`/project/${id}`, data)
    return response
  }
}
