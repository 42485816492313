import React, { forwardRef, useMemo } from 'react'
import config from 'config'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Checkbox, Space } from 'antd'

const { Item } = Form
const { TextArea } = Input
const { Group } = Checkbox

const CheckboxImageStyled = styled('div')`
  width: 220px;
  padding: 5px;
  margin: 5px 0;
  overflow: hidden;
  max-height: 300px;
  border: 1px solid #9d9d9d;
  .checkbox-image {
    position: relative;
    text-align: center;
    margin-bottom: 5px;
    img {
      max-width: 100%;
      max-height: 200px;
    }
    label {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      .ant-checkbox {
        right: 0;
        position: absolute;
      }
    }
  }
`

const CheckboxImage = ({ image, disabled }) => {
  const { _id, comment, imageUrl } = image || {}

  return (
    <CheckboxImageStyled>
      <div className='checkbox-image'>
        <Checkbox value={_id} disabled={disabled} />
        <img src={`${config.server.url}${imageUrl}`} alt='checkbox image' />
      </div>
      <TextArea value={comment} disabled={disabled} />
    </CheckboxImageStyled>
  )
}

CheckboxImage.propTypes = {
  disabled: PropTypes.bool,
  image: PropTypes.object.isRequired
}

const FormAttachPictures = forwardRef(({ recording }, ref) => {
  const { values, images } = useMemo(() => {
    const { objectId, images } = recording || {}
    return {
      values: {
        images: ((objectId || {}).images || []).map(({ _id }) => _id)
      },
      images: (images || []).filter(({ deleted }) => !deleted)
    }
  }, [recording])

  return (
    <Form ref={ref} initialValues={values}>
      <Item name='images'>
        <Group>
          <Space style={{ flexWrap: 'wrap' }} align='center'>
            {images.map((image, index) => (
              <CheckboxImage
                key={index}
                image={image}
                disabled={values.images.includes(image._id)}
              />
            ))}
          </Space>
        </Group>
      </Item>
    </Form>
  )
})

FormAttachPictures.propTypes = {
  recording: PropTypes.object.isRequired
}

export default FormAttachPictures
