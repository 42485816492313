import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import { Input, Spin, Select, Switch } from 'antd'
import { fetchObjectTypeList } from '../../../ducks/objectTypes'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'

class ObjectForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      isVerified: false
    }
    this.handleInputThrottled = debounce(this.fetchObjectTypesOnSearch, 300)
  }

  componentDidMount () {
    if (this.props.object.isVerified) {
      this.setState({
        isVerified: true
      })
    }
  }

  fetchObjectTypesOnSearch = value => {
    const { dispatch } = this.props
    this.setState({ loading: true })
    dispatch(fetchObjectTypeList({ 'name[]': value }, true)).then(() => {
      this.setState({ loading: false })
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { loading, isVerified } = this.state
    const {
      object,
      objectName,
      projects = [],
      readOnlyNewProject,
      objectTypeList = [],
      intl,
      formLoading,
      fetchProjects,
      fetchLocations,
      locations = [],
      updateObject
    } = this.props
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    if (object.location && object.location._id) {
      if (!locations.some(({ _id }) => _id === object.location._id)) {
        locations.push(object.location)
      }
    }

    if (object.project && object.project._id) {
      if (!projects.some(({ _id }) => _id === object.project._id)) {
        projects.push(object.project)
      }
    }

    if (object.objectType && object.objectType._id) {
      if (!objectTypeList.some(({ _id }) => _id === object.objectType._id)) {
        objectTypeList.push(object.objectType)
      }
    }

    return (
      <Form onSubmit={() => {}} className='objectForm'>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              {objectName}
              <span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('name', {
            initialValue: object.name,
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'name is required'
                })
              }
            ]
          })(
            <Input
              disabled={!!object._id}
              onChange={event => updateObject('name', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'project' })}
        >
          {getFieldDecorator('project', {
            initialValue: object.project && object.project._id
          })(
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp='children'
              notFoundContent={
                formLoading ? (
                  <div className='objects-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              onFocus={() => fetchProjects('')}
              onSearch={value => fetchProjects(value)}
              onChange={projectID => updateObject('project', projectID)}
              // defaultValue={object.project ? object.project.projectName : ""}
              disabled={readOnlyNewProject}
            >
              {projects.map(project => (
                <Select.Option key={project._id} value={project._id}>
                  {project.projectName}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'location' })}
        >
          {getFieldDecorator('location', {
            initialValue: object.location && object.location._id
          })(
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp='children'
              notFoundContent={
                formLoading ? (
                  <div className='objects-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              disabled={readOnlyNewProject}
              onFocus={() => fetchLocations('')}
              onSearch={value => fetchLocations(value)}
              onChange={value => {
                updateObject(
                  'location',
                  locations.find(location => location._id === value)
                )
              }}
            >
              {(locations || []).map(location => (
                <Select.Option key={location._id} value={location._id}>
                  {location.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          {getFieldDecorator('description', {
            initialValue: object.description
          })(
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={event =>
                updateObject('description', event.target.value)
              }
              // value={object.description}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'comment' })}
        >
          {getFieldDecorator('comment', {
            initialValue: object.comment
          })(
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={event => updateObject('comment', event.target.value)}
              // value={object.comment}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'verified'
          })}
        >
          {getFieldDecorator('isVerified', {
            initialValue: object.isVerified ? 'checked' : false
          })(
            <Switch
              // defaultChecked={object.isVerified}
              checked={isVerified}
              onChange={value => {
                this.setState({ isVerified: !isVerified })
                updateObject('isVerified', !isVerified)
              }}
            />
          )}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          required={false}
          label={intl.formatMessage({ id: 'object type' })}
        >
          {getFieldDecorator('objectType', {
            initialValue:
              (object.objectType && object.objectType._id) || undefined
          })(
            <Select
              showSearch
              style={{ width: '100%' }}
              notFoundContent={
                loading && (
                  <div className='project-spinner-container'>
                    <Spin size='small' />
                  </div>
                )
              }
              placeholder={intl.formatMessage({ id: 'select form' })}
              optionFilterProp='children'
              onSearch={this.handleInputThrottled}
              onFocus={() => this.handleInputThrottled('')}
              onChange={value => {
                updateObject(
                  'objectType',
                  objectTypeList.find(obj => obj._id === value)
                )
              }}
            >
              {(objectTypeList || []).map(objectType => {
                return (
                  <Select.Option
                    key={objectType._id}
                    data={objectType}
                    value={objectType._id}
                  >
                    {objectType.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      </Form>
    )
  }
}

ObjectForm.propTypes = {
  object: PropTypes.object.isRequired,
  updateObject: PropTypes.func.isRequired,
  setObjectType: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  objectName: PropTypes.string,
  form: PropTypes.object.isRequired,
  projects: PropTypes.array,
  objectTypeList: PropTypes.array,
  readOnlyNewProject: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  formLoading: PropTypes.bool,
  fetchProjects: PropTypes.func,
  fetchLocations: PropTypes.func,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  objectTypeList: state.objectType.searchedObjectTypeList
})

export default injectIntl(connect(mapStateToProps, null)(ObjectForm))
