import React, { forwardRef } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Select } from 'antd'
import { useIntl } from 'react-intl'

import useFieldDefinitions from '../../../hooks/useFieldDefinitions'

const { Item } = Form
const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormObjectType = forwardRef(({ objectType }, ref) => {
  const intl = useIntl()

  const { status, archived, name, fields } = objectType || {}

  const values = {
    status,
    archived,
    name,
    fields: fields && fields.map(({ _id }) => _id)
  }

  const {
    loading: loadingFieldDefinitions,
    fetch: fetchFieldDefinitions,
    items: fieldDefinitions
  } = useFieldDefinitions()

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Item
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'name' })} />
      </Item>

      <Item name='fields' label={intl.formatMessage({ id: 'fields' })}>
        <Select
          mode='multiple'
          style={{ width: '100%' }}
          loading={loadingFieldDefinitions}
          placeholder={intl.formatMessage({
            id: 'choose fields'
          })}
          onSearch={debounce(fetchFieldDefinitions, 500)}
        >
          {fieldDefinitions.map(({ _id, label }) => (
            <Option key={_id}>{label}</Option>
          ))}
        </Select>
      </Item>
    </StyledForm>
  )
})

FormObjectType.propTypes = {
  objectType: PropTypes.object
}

export default FormObjectType
