import React from 'react'
import { Form, Input } from 'antd'
import { useIntl } from 'react-intl'

import ContactTreeSelect from '../../../../components/ContactTreeSelect'

const { Item } = Form

const itemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
}

const ProtocolInfo = () => {
  const intl = useIntl()

  return (
    <>
      <Item
        {...itemLayout}
        name='protocolResponsible'
        label={intl.formatMessage({ id: 'protocolResponsible' })}
      >
        <ContactTreeSelect
          placeholder={intl.formatMessage({ id: 'select contact' })}
        />
      </Item>
      <Item
        {...itemLayout}
        name='protocolReference'
        label={intl.formatMessage({ id: 'protocolReference' })}
      >
        <Input placeholder={intl.formatMessage({ id: 'protocolReference' })} />
      </Item>
      <Item
        {...itemLayout}
        name='protocolExtResponsible'
        label={intl.formatMessage({ id: 'protocolExtResponsible' })}
      >
        <ContactTreeSelect
          placeholder={intl.formatMessage({ id: 'select contact' })}
        />
      </Item>
      <Item
        {...itemLayout}
        name='protocolRefExt'
        label={intl.formatMessage({ id: 'protocolRefExt' })}
      >
        <Input placeholder={intl.formatMessage({ id: 'protocolRefExt' })} />
      </Item>
    </>
  )
}

export default ProtocolInfo
