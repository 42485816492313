import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import { Table, Button, Badge, Typography } from 'antd'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined
} from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import RequestManagement from './RequestManagement'
import searchColumn from '../../../utils/searchColumn'
import requestsService from '../../../services/requests'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'

const { Text } = Typography

const RequestsPage = () => {
  const intl = useIntl()
  const inputName = useRef()
  const inputNumber = useRef()
  const inputObject = useRef()
  const [request, setRequest] = useState()
  const [data, setData] = useState({})
  const [loading, fetchRequests] = useApi(requestsService.listV2)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )
  const objectName = useSelector(
    ({
      settings: {
        data: { buildx: { objectName = 'object number' } = {} }
      }
    }) => objectName
  )

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt',
    filters: { status: ['1'] }
  })

  const loadData = async () => {
    const response = await fetchRequests({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = request => {
    setRequest(request)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setRequest()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const { filterOptions: { createdBy: createdByFilters = [] } = {} } = data

  const statusValues = {
    1: {
      text: intl.formatMessage({ id: 'open' }),
      backgroundColor: '#015590',
      value: 1
    },
    2: {
      text: intl.formatMessage({ id: 'approved' }),
      backgroundColor: '#40c353',
      value: 2
    },
    3: {
      text: intl.formatMessage({ id: 'rejected' }),
      backgroundColor: '#c53e3d',
      value: 3
    }
  }

  const priorityLabels = {
    0: (
      <Text style={{ color: 'black' }}>
        <FormattedMessage id='none' />
      </Text>
    ),
    1: (
      <Text style={{ color: 'blue' }}>
        <ArrowDownOutlined /> <FormattedMessage id='low' />
      </Text>
    ),
    2: (
      <Text style={{ color: 'gray' }}>
        <FormattedMessage id='medium' />
      </Text>
    ),
    3: (
      <Text style={{ color: 'red' }}>
        <ArrowUpOutlined /> <FormattedMessage id='high' />
      </Text>
    )
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'number' }),
      dataIndex: 'docNumber',
      sorter: true,
      defaultSortOrder: sortField === 'docNumber' && sortOrder,
      ...searchColumn({ input: inputNumber, intl, value: filters.docNumber })
    },
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input: inputName, intl, value: filters.name })
    },
    {
      title: intl.formatMessage({ id: objectName, defaultMessage: objectName }),
      dataIndex: ['objectId', 'name'],
      key: 'objectId',
      sorter: true,
      defaultSortOrder: sortField === 'objectId' && sortOrder,
      ...searchColumn({ input: inputObject, intl, value: filters.objectId })
    },
    {
      title: intl.formatMessage({ id: 'priority' }),
      dataIndex: 'priority',
      render: (priority = 0) => priorityLabels[priority],
      filters: [
        { text: intl.formatMessage({ id: 'none' }), value: 0 },
        { text: intl.formatMessage({ id: 'low' }), value: 1 },
        { text: intl.formatMessage({ id: 'medium' }), value: 2 },
        { text: intl.formatMessage({ id: 'high' }), value: 3 }
      ],
      defaultFilteredValue: filters.priority
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'status',
      sorter: true,
      defaultSortOrder: sortField === 'status' && sortOrder,
      render: status => {
        const { text, backgroundColor } =
          statusValues[status] || statusValues[1]
        return <Badge count={text} style={{ backgroundColor }} />
      },
      filters: Object.values(statusValues).map(({ text, value }) => ({
        text,
        value
      })),
      defaultFilteredValue: filters.status
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      sorter: true,
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { _id, firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: _id
        }
      }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    }
  ]

  if (!selectedProject._id) {
    columns.push({
      title: intl.formatMessage({ id: 'project' }),
      key: 'project',
      dataIndex: 'project.projectName'
    })
  }

  columns.push({
    width: 65,
    align: 'right',
    key: 'actions',
    render: record => (
      <Button onClick={() => handleOpen(record)}>
        <EditOutlined />
      </Button>
    )
  })

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.requests'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle title={intl.formatMessage({ id: 'requests' })} />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        scroll={{ x: true }}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      {request && (
        <RequestManagement
          request={request}
          visible={drawerVisible}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      )}
    </div>
  )
}

export default RequestsPage
