import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import Button from 'components/Global/Button'

import { Table, notification, Input, Tabs } from 'antd'
import { Form, Icon } from '@ant-design/compatible'

import ContactForm from './components/ContactsForm'
import PageTitle from 'components/Global/PageTitle'
import Popconfirm from 'components/Popconfirm'
import contactService from 'services/contact'
import DrawerComponent from 'components/Drawer'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'
import PropTypes from 'prop-types'

import ContactsTab from './components/ContactsTab'

const { TabPane } = Tabs

export class ContactPage extends React.Component {
  state = {
    items: [],
    item: {},
    subItem: {},
    drawer: false,
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    createdByFilters: [],
    activeFilters: [],
    readOnlyNewProject: false,
    params: {},
    user: {},
    action: '',
    submitting: false
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }

      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project && project.project) {
      paramsObject.project = project.project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchContacts()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (
      params.project &&
      !prevProps.project.projectList.length &&
      project.projectList.length
    ) {
      dispatch(
        setPickedProject(
          project.projectList.find(item => item._id === params.project)
        )
      )
    }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchContacts()
    }
  }

  fetchContacts = async () => {
    const params = this.getParams()
    if (params) {
      delete params.project
    }
    this.setState({
      loading: true
    })
    try {
      const contacts = await contactService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = contacts.totalDocs

      this.setState({
        items: contacts.docs,
        createdByFilters: contacts.filterOptions.createdBy,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: contacts.limit,
          current: contacts.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'contacts'
        })} - download error`
      })

      this.setState({
        items: [],
        drawer: false,
        loading: false
      })
    }
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this[`searchInput${dataIndex}`] = node
            }}
            placeholder={this.props.intl.formatMessage({ id: 'search' })}
            value={
              this.state[dataIndex + 'Search'] !== undefined
                ? this.state[dataIndex + 'Search']
                : this.state.params[dataIndex]
            }
            onChange={e =>
              this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, dataIndex, confirm)
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
            icon='search'
            size='small'
            disabled={!this.state[dataIndex + 'Search']}
            value='Suchen'
            style={{ width: 90, marginRight: 8 }}
          />

          <Button
            onClick={() => this.handleReset(dataIndex, clearFilters)}
            size='small'
            type='secondary'
            value='Reset'
            style={{ width: 90 }}
          />
        </div>
      )
    },
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  showDrawer = (contact, action) => {
    if (_.isEmpty(contact, true)) {
      this.setState({
        drawer: true,
        item: {
          ...contact
        },
        action
      })
    } else {
      this.setState({
        drawer: true,
        item: {
          ...contact
        },
        action
      })
    }
  }

  handleSubDrawer = () => {
    this.setState({
      subDrawer: !this.state.subDrawer,
      subItem: {}
    })
  }

  handleHideDrawer = () => {
    this.setState({
      drawer: false,
      item: {}
    })
  }

  handleSaveContact = async () => {
    const { subItem, item, subDrawer } = this.state
    this.props.form.validateFields(async err => {
      if (!err) {
        this.setState({
          submitting: true
        })

        try {
          const savedContact = await contactService.save(
            subDrawer ? { ...subItem, contact_type: 'user' } : item
          )
          this.fetchContacts()
          if (this.state.item._id && !subDrawer) {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'updated successfully'
              })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'created successfully'
              })}`
            })
          }
          setTimeout(() => this.setState({ submitting: false }), 1500)

          if (subDrawer) {
            this.setState({
              subDrawer: false,
              subItem: {},
              items: [
                ...this.state.items.filter(
                  item => item._id !== savedContact._id
                ),
                savedContact
              ],
              item: {
                ...item,
                contacts_sub: [
                  ...item.contacts_sub,
                  { contact_sub: savedContact }
                ]
              }
            })
          } else {
            this.setState({
              drawer: false,
              item: {},
              items: [
                ...this.state.items.filter(
                  item => item._id !== savedContact._id
                ),
                savedContact
              ]
            })
          }
        } catch (error) {
          this.setState({
            submitting: false
          })
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  removeContact = async contact => {
    try {
      await contactService.remove(contact)

      this.setState({
        drawer: false,
        item: {},
        items: [...this.state.items.filter(item => item._id !== contact._id)]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error'
        }),
        description: error.error
      })
    }
  }

  updateContact = (field, value, subContact) => {
    this.setState(state => ({
      [subContact ? 'subItem' : 'item']: {
        ...state[subContact ? 'subItem' : 'item'],
        [field]: value
      }
    }))
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  formDrawerButtons = (action, drawer) => {
    const { intl } = this.props
    const { item, submitting } = this.state
    if (action === 'edit') {
      return [
        <Popconfirm key={0} onConfirmAction={() => this.removeContact(item)} />,
        <Button
          key={65493152}
          onClick={this.handleHideDrawer}
          style={{ marginRight: 8 }}
        >
          <FormattedMessage id='cancel' />
        </Button>,
        <Button
          key={237323536}
          onClick={this.handleSaveContact}
          type='primary'
          loading={submitting}
          disabled={submitting}
        >
          {intl.formatMessage({ id: 'save' })}
        </Button>
      ]
    } else {
      return [
        <Button
          key={65493152}
          onClick={
            drawer === 'subDrawer'
              ? this.handleSubDrawer
              : this.handleHideDrawer
          }
          style={{ marginRight: 8 }}
        >
          <FormattedMessage id='cancel' />
        </Button>,
        <Button
          loading={submitting}
          disabled={submitting}
          key={237323536}
          onClick={this.handleSaveContact}
          type='primary'
        >
          {intl.formatMessage({ id: 'save' })}
        </Button>
      ]
    }
  }

  render () {
    const {
      items,
      item,
      pagination,
      drawer,
      subDrawer,
      loading,
      formLoading,
      subItem,
      action
    } = this.state
    const { intl, form } = this.props

    const columns = [
      {
        key: 'icon',
        render: record =>
          !record.company || record.contact_type === 'user' ? (
            <Icon type='user' style={{ fontSize: 16 }} />
          ) : (
            <Icon type='home' style={{ fontSize: 16 }} />
          )
      },
      {
        title: intl.formatMessage({ id: 'name' }),
        key: 'company',
        defaultSortOrder: 'ascend',
        sorter: true,
        render: record =>
          record.company
            ? record.company
            : `${record.firstName || ''} ${record.lastName || ''}`,
        ...this.getColumnSearchProps('company')
      },
      {
        title: intl.formatMessage({ id: 'zip' }),
        key: 'postcode',
        dataIndex: 'postcode'
      },
      {
        title: intl.formatMessage({ id: 'city' }),
        key: 'city',
        dataIndex: 'city'
      },
      {
        title: intl.formatMessage({ id: 'street' }),
        key: 'street',
        dataIndex: 'street'
      },
      {
        title: intl.formatMessage({ id: 'phone' }),
        key: 'phone',
        dataIndex: 'phone'
      },
      {
        title: intl.formatMessage({ id: 'email' }),
        key: 'email',
        dataIndex: 'email'
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}
          >
            <>
              <Button onClick={() => this.showDrawer(record, 'edit')}>
                <Icon type='edit' />
              </Button>
            </>
          </div>
        )
      }
    ]

    const headerButtons = [
      <Button
        key={238397323536}
        type='primary'
        onClick={() => this.showDrawer({}, 'create')}
      >
        <FormattedMessage id='create contact' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.contacts'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'contacts' })}
          buttons={headerButtons}
        />

        <Table
          size='small'
          rowKey={record => record._id}
          columns={columns}
          dataSource={items}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record, 'edit')
            }
          })}
          loading={loading}
          onChange={this.handleTableChange}
          defaultPageSize={pagination.defaultPageSize}
          pagination={pagination}
        />

        <DrawerComponent
          title={
            item._id
              ? intl.formatMessage({ id: 'update contact' })
              : intl.formatMessage({ id: 'create contact' })
          }
          onClose={this.handleHideDrawer}
          visible={!!drawer}
          footerButtons={this.formDrawerButtons('create')}
        >
          {action === 'edit' ? (
            <Tabs defaultActiveKey='form'>
              <TabPane
                tab={intl.formatMessage({
                  id: 'informations'
                })}
                key='form'
                className='recordings-popup-wrap'
              >
                <ContactForm
                  contact={item}
                  formLoading={formLoading}
                  updateContact={this.updateContact}
                  form={form}
                  action={action}
                />
              </TabPane>
              {item && item.contact_type === 'company' && (
                <TabPane
                  tab={intl.formatMessage({
                    id: 'people'
                  })}
                  key='people'
                  className='recordings-popup-wrap'
                >
                  <ContactsTab
                    data={item}
                    updateContact={this.updateContact}
                    // eslint-disable-next-line react/jsx-handler-names
                    handleCreateContact={this.handleSubDrawer}
                    contactsList={item.contacts_sub}
                  />
                </TabPane>
              )}
            </Tabs>
          ) : (
            <ContactForm
              contact={item}
              formLoading={formLoading}
              updateContact={this.updateContact}
              form={form}
              action={action}
            />
          )}

          <DrawerComponent
            title={intl.formatMessage({ id: 'create contact' })}
            onClose={this.handleSubDrawer}
            visible={!!subDrawer}
            footerButtons={this.formDrawerButtons('create', 'subDrawer')}
          >
            <ContactForm
              contact={subItem}
              formLoading={formLoading}
              updateContact={(field, value) =>
                this.updateContact(field, value, true)
              }
              form={form}
              action={action}
              subDrawer
            />
          </DrawerComponent>
        </DrawerComponent>
      </div>
    )
  }
}

ContactPage.propTypes = {
  project: PropTypes.object,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  project: state.project
})

export default injectIntl(
  Form.create({ name: 'contact_form' })(connect(mapStateToProps)(ContactPage))
)
