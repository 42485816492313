import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import {
  HomeOutlined,
  TagsOutlined,
  SolutionOutlined,
  SafetyOutlined,
  DeploymentUnitOutlined,
  BookOutlined,
  SelectOutlined,
  ForkOutlined,
  ToolOutlined,
  CalendarOutlined,
  PullRequestOutlined,
  ControlOutlined,
  CheckSquareOutlined,
  AuditOutlined,
  SettingOutlined,
  EnvironmentOutlined,
  SnippetsOutlined,
  TeamOutlined,
  QrcodeOutlined,
  ProjectOutlined
} from '@ant-design/icons'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import config from 'config'

import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import AccountArea from '../../../components/AccountArea'
import { setWorkorderShowCompleted } from '../../../ducks/workorder'

const Sidebar = props => {
  const roles = props.auth.authUser.scope || []
  const nonStandarUser = roles[0] === 'admin' || roles[0] === 'superadmin'
  const timeStamp = useSelector(state => state.settings.timeStamp)
  const [imageError, setError] = useState(false)
  useEffect(() => {
    setError(false)
  }, [timeStamp])
  const dispatch = useDispatch()
  const openedSubMenus = []
  if (
    [
      '/forms',
      '/projects',
      '/settings',
      '/users',
      '/field-definition',
      '/contacts',
      '/protocol',
      '/object-types'
    ].includes(window.location.pathname)
  ) {
    openedSubMenus.push('settingsSubMenu')
  }

  if (
    ['/recordings', '/acceptances', '/objects'].includes(
      window.location.pathname
    )
  ) {
    openedSubMenus.push('buildxSubMenu')
  }

  if (
    [
      '/requests',
      '/workorders',
      '/specialwork',
      '/scheduled-workorders',
      '/checklist',
      '/defects'
    ].includes(window.location.pathname)
  ) {
    openedSubMenus.push('mainxSubMenu')
  }

  if (
    [
      '/products',
      '/manufacturers',
      '/categories',
      '/contract-sections',
      '/locations'
    ].includes(window.location.pathname)
  ) {
    openedSubMenus.push('dataxSubMenu')
  }

  const modules = {
    buildx: (props.settings.data.buildx || {}).enabled,
    mainx: (props.settings.data.mainx || {}).enabled,
    datax: (props.settings.data.datax || {}).enabled,
    doclink: (props.settings.data.doclink || {}).enabled
  }
  return (
    <>
      <div className='logo'>
        <img
          onError={() => setError(true)}
          src={
            imageError
              ? '/images/ddSuite-icon.svg'
              : `${config.server.url}/uploads/settings/logoWeb.png?${timeStamp}`
          }
          alt='logo'
        />
      </div>

      <Menu
        theme='dark'
        mode='inline'
        selectedKeys={[window.location.pathname]}
        defaultOpenKeys={openedSubMenus}
      >
        <Menu.Item key='/'>
          <NavLink to='/' className='nav-text'>
            <HomeOutlined />
            <span>Home</span>
          </NavLink>
        </Menu.Item>

        {modules.buildx && (
          <Menu.SubMenu
            title={
              <span>
                <TagsOutlined />
                <span>BuildX</span>
              </span>
            }
            key='buildxSubMenu'
          >
            <Menu.Item key='/recordings'>
              <NavLink to='/recordings' className='nav-text'>
                <SolutionOutlined />
                <span className='nav-text'>
                  {(props.settings.data.buildx || {}).recordingsName ? (
                    (props.settings.data.buildx || {}).recordingsName
                  ) : (
                    <FormattedMessage id='recordings' />
                  )}
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/acceptances'>
              <NavLink to='/acceptances' className='nav-text'>
                <SafetyOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='acceptances' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {modules.datax && (
          <Menu.SubMenu
            title={
              <span>
                <BookOutlined />
                <span>DataX</span>
              </span>
            }
            key='dataxSubMenu'
          >
            <Menu.Item key='/objects'>
              <NavLink to='/objects' className='nav-text'>
                <DeploymentUnitOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='objects' />
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/locations'>
              <NavLink to='/locations' className='nav-text'>
                <EnvironmentOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='locations' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/contract-sections'>
              <NavLink to='/contract-sections' className='nav-text'>
                <SnippetsOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='contract sections' />
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/products'>
              <NavLink to='/products' className='nav-text'>
                <BookOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='products' />
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/manufacturers'>
              <NavLink to='/manufacturers' className='nav-text'>
                <BookOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='manufacturers' />
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/categories'>
              <NavLink to='/categories' className='nav-text'>
                <BookOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='categories' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {modules.doclink && (
          <Menu.Item key='/doclink'>
            <NavLink to='/doclink' className='nav-text'>
              <SelectOutlined />
              <span className='nav-text'>DOClink</span>
            </NavLink>
          </Menu.Item>
        )}

        {modules.mainx && (
          <Menu.SubMenu
            title={
              <span>
                <ForkOutlined />
                <span>MainX</span>
              </span>
            }
            key='mainxSubMenu'
          >
            <Menu.Item key='/defects'>
              <NavLink to='/defects' className='nav-text'>
                <ToolOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='defects' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key='/workorders'
              onClick={() => dispatch(setWorkorderShowCompleted(false))}
            >
              <NavLink to='/workorders' className='nav-text'>
                <ToolOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='workorders' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/scheduled-workorders'>
              <NavLink to='/scheduled-workorders' className='nav-text'>
                <CalendarOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='scheduled-workorders' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/requests'>
              <NavLink to='/requests' className='nav-text'>
                <PullRequestOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='requests' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/specialwork'>
              <NavLink to='/specialwork' className='nav-text'>
                <ControlOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='specialwork' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/checklist'>
              <NavLink to='/checklist' className='nav-text'>
                <CheckSquareOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='checklists' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {nonStandarUser && (
          <Menu.SubMenu
            title={
              <span>
                <SettingOutlined />
                <span>
                  <FormattedMessage id='settings' />
                </span>
              </span>
            }
            key='settingsSubMenu'
          >
            <Menu.Item key='/object-types'>
              <NavLink to='/object-types' className='nav-text'>
                <DeploymentUnitOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='object types' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/field-definition'>
              <NavLink to='/field-definition' className='nav-text'>
                <DeploymentUnitOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='field definition' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/users'>
              <NavLink to='/users' className='nav-text'>
                <TeamOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='users' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/contacts'>
              <NavLink to='/contacts' className='nav-text'>
                <TeamOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='contacts' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/forms'>
              <NavLink to='/forms' className='nav-text'>
                <QrcodeOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='forms' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/projects'>
              <NavLink to='/projects' className='nav-text'>
                <ProjectOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='projects' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/protocol'>
              <NavLink to='/protocol' className='nav-text'>
                <AuditOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='protocol' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/settings'>
              <NavLink to='/settings' className='nav-text'>
                <SettingOutlined />
                <span className='nav-text'>
                  <FormattedMessage id='global settings' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        <AccountArea />
      </Menu>
    </>
  )
}

Sidebar.propTypes = {
  auth: PropTypes.object,
  settings: PropTypes.object.isRequired
}

export default Sidebar
