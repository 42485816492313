import { createReducer, createAction } from 'redux-act'

export const REDUCER = 'checklist'
const NS = `@@${REDUCER}/`

export const setPickedChecklist = createAction(`${NS}SET_PICKED_CHECKLIST`)
export const setSearchedChecklists = createAction(`${NS}SET_SEARCHED_CHECKLIST`)

export const initialState = {
  checklistData: {},
  searchedChecklistList: [],
  pickedChecklist: {}
}

export const updatePickedChecklist = data => (dispatch, getState) => {
  const currentState = { ...getState().checklist.pickedChecklist }
  dispatch(setPickedChecklist({ ...currentState, ...data }))
}

export default createReducer(
  {
    [setPickedChecklist]: (state, pickedChecklist) => ({
      ...state,
      pickedChecklist
    }),
    [setSearchedChecklists]: (state, searchedChecklistList) => ({
      ...state,
      searchedChecklistList
    })
  },
  initialState
)
