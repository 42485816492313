import React, { forwardRef, useEffect } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Form, Input, Select, Tabs, Switch, Button } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'

import PriceInput from '../../../components/PriceInput'
import useCategories from '../../../hooks/useCategories'
import useManufacturers from '../../../hooks/useManufacturers'

const { Item } = Form
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const SelectWrapper = styled('div')`
  display: flex;
  & > .ant-btn {
    margin-left: 10px;
  }
  & > .ant-select {
    flex: 1;
  }
`

const SelectWithButton = ({ children, onClick, ...props }) => (
  <SelectWrapper>
    <Select {...props}>{children}</Select>
    <Button type='primary' onClick={onClick}>
      <PlusOutlined />
    </Button>
  </SelectWrapper>
)

SelectWithButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired
}

const FormProduct = forwardRef(
  ({ product, createManufacturer, createCategory }, ref) => {
    const intl = useIntl()

    const {
      name,
      ordernumber,
      description,
      ean,
      width,
      height,
      depth,
      supplierNumber,
      length,
      price,
      active,
      category,
      manufacturer
    } = product || {}

    const values = {
      name,
      ordernumber,
      description,
      ean,
      width,
      height,
      depth,
      supplierNumber,
      length,
      price,
      active,
      category: category && category._id,
      manufacturer: manufacturer && manufacturer._id
    }

    const {
      loading: loadingCategories,
      fetch: fetchCategories,
      items: categories
    } = useCategories(category)

    const {
      loading: loadingManufacturers,
      fetch: fetchManufacturers,
      items: manufacturers
    } = useManufacturers(manufacturer)

    useEffect(() => {
      if (values.category) {
        ref.current.setFieldsValue({
          category: values.category
        })
      }

      if (values.manufacturer) {
        ref.current.setFieldsValue({
          manufacturer: values.manufacturer
        })
      }
    }, [category, manufacturer])

    return (
      <StyledForm ref={ref} {...formLayout} initialValues={values}>
        <Tabs defaultActiveKey='informations'>
          <TabPane
            tab={intl.formatMessage({ id: 'informations' })}
            key='informations'
          >
            <Item
              name='name'
              label={intl.formatMessage({ id: 'name' })}
              rules={[{ required: true }]}
            >
              <Input placeholder={intl.formatMessage({ id: 'name' })} />
            </Item>

            <Item
              name='manufacturer'
              label={intl.formatMessage({ id: 'manufacturer' })}
            >
              <SelectWithButton
                showSearch
                allowClear
                filterOption={false}
                style={{ width: '100%' }}
                loading={loadingManufacturers}
                placeholder={intl.formatMessage({
                  id: 'choose a manufacturer'
                })}
                onClick={createManufacturer}
                onSearch={debounce(fetchManufacturers, 500)}
              >
                {manufacturers.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </SelectWithButton>
            </Item>

            <Item
              name='ordernumber'
              label={intl.formatMessage({ id: 'article number' })}
            >
              <Input
                placeholder={intl.formatMessage({ id: 'article number' })}
              />
            </Item>

            <Item
              name='description'
              label={intl.formatMessage({ id: 'description' })}
            >
              <TextArea
                placeholder={intl.formatMessage({ id: 'description' })}
              />
            </Item>

            <Item
              name='category'
              label={intl.formatMessage({ id: 'category' })}
            >
              <SelectWithButton
                showSearch
                allowClear
                filterOption={false}
                style={{ width: '100%' }}
                loading={loadingCategories}
                placeholder={intl.formatMessage({
                  id: 'choose a category'
                })}
                onClick={createCategory}
                onSearch={debounce(fetchCategories, 500)}
              >
                {categories.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </SelectWithButton>
            </Item>

            <Item name='price' label={intl.formatMessage({ id: 'price' })}>
              <PriceInput
                customInput={PriceInput.CustomInput({ prefix: 'CHF' })}
              />
            </Item>

            <Item
              name='active'
              valuePropName='checked'
              label={intl.formatMessage({ id: 'active' })}
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked='checked'
              />
            </Item>
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: 'settings' })} key='settings'>
            <Item name='ean' label={intl.formatMessage({ id: 'ean' })}>
              <Input placeholder={intl.formatMessage({ id: 'ean' })} />
            </Item>
            <Item name='width' label={intl.formatMessage({ id: 'width' })}>
              <Input placeholder={intl.formatMessage({ id: 'width' })} />
            </Item>
            <Item name='height' label={intl.formatMessage({ id: 'height' })}>
              <Input placeholder={intl.formatMessage({ id: 'height' })} />
            </Item>
            <Item name='depth' label={intl.formatMessage({ id: 'depth' })}>
              <Input placeholder={intl.formatMessage({ id: 'depth' })} />
            </Item>
            <Item
              name='supplierNumber'
              label={intl.formatMessage({ id: 'supplier number' })}
            >
              <Input
                placeholder={intl.formatMessage({ id: 'supplier number' })}
              />
            </Item>
            <Item name='length' label={intl.formatMessage({ id: 'length' })}>
              <Input placeholder={intl.formatMessage({ id: 'length' })} />
            </Item>
          </TabPane>
        </Tabs>
      </StyledForm>
    )
  }
)

FormProduct.propTypes = {
  product: PropTypes.object,
  createManufacturer: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired
}

FormProduct.defaultProps = {
  createManufacturer: () => {},
  createCategory: () => {}
}

export default FormProduct
