import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { AcceptanceAPI } from '../services'
import axios from 'axios'
import config from 'config'
import { handleDownloadResponse } from './recordings'

import moment from 'moment'

export const REDUCER = 'acceptance'
const NS = `@@${REDUCER}/`

export const setAcceptancesData = createAction(`${NS}SET_ACCEPTANCES_DATA`)
export const setAcceptanceQueryParams = createAction(
  `${NS}SET_ACCEPTANCE_QUERY_PARAMS`
)
export const setPickedAcceptance = createAction(`${NS}SET_PICKED_ACCEPTANCE`)

const AcceptanceApi = new AcceptanceAPI()

export const initialState = {
  acceptancesData: [],
  queryParams: {},
  pickedAcceptance: {},
  acceptanceDefectCreationData: {}
}

export const updatePickedAcceptance = data => (dispatch, getState) => {
  const currentState = { ...getState().acceptance.pickedAcceptance }
  dispatch(setPickedAcceptance({ ...currentState, ...data }))
}

export const submitPickedAcceptance = () => (dispatch, getState) => {
  const acceptance = { ...getState().acceptance.pickedAcceptance }

  const payload = {
    ...acceptance
  }
  if (acceptance.location) {
    payload.location = acceptance.location._id
  }

  const params = {
    name:
      payload.name ||
      `Abnahme vom ${moment(acceptance.createdAt).format('DD.MM.YYYY HH:ss')}`,
    project: payload.project || null,
    participant: payload.participant,
    archived: payload.archived,
    location: payload.location
  }

  if (acceptance.locationName) {
    params.location = acceptance.locationName
  }

  return AcceptanceApi.save(acceptance._id, params)
    .then(response => {
      if (!response.error) {
        return true
      } else {
        return response
      }
    })
    .catch(error => {
      return error
    })
}

export const fetchAcceptance = (params = {}) => (dispatch, getState) => {
  const pickedProject = getState().project.pickedProject._id
  const queryParams = getState().acceptance.queryParams
  if (pickedProject) {
    params.project = pickedProject
  } else {
    delete params.project
    delete queryParams.project
  }
  return AcceptanceApi.listV2({ ...queryParams, ...params })
    .then(response => {
      dispatch(setAcceptancesData(response))
      dispatch(setAcceptanceQueryParams(params))
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export const deleteAcceptance = () => (dispatch, getState) => {
  const pickedAcceptance = getState().acceptance.pickedAcceptance
  const acceptanceData = getState().acceptance.acceptancesData

  return AcceptanceApi.delete(pickedAcceptance._id)
    .then(result => {
      if (!result.error) {
        dispatch(
          setAcceptancesData({
            ...acceptanceData,
            docs: acceptanceData.docs.filter(
              item => item._id !== pickedAcceptance._id
            )
          })
        )
        dispatch(setPickedAcceptance({}))
      }
      return result
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export const downloadPDF = (data, acceptance = true) => (
  dispatch,
  getState
) => {
  axios({
    method: 'get',
    url: `${config.server.url}/${acceptance ? 'acceptance' : 'recording'}/${
      data._id
    }/pdf`,
    responseType: 'arraybuffer',
    headers: {
      authorization: localStorage.getItem('authToken')
    }
  }).then(function (response) {
    handleDownloadResponse(response, 'application/pdf', `${data.docNumber}.pdf`)
  })
}

export default createReducer(
  {
    [setAcceptancesData]: (state, acceptancesData) => ({
      ...state,
      acceptancesData
    }),
    [setAcceptanceQueryParams]: (state, queryParams) => ({
      ...state,
      queryParams
    }),
    [setPickedAcceptance]: (state, pickedAcceptance) => ({
      ...state,
      pickedAcceptance
    })
  },
  initialState
)
