import axios from 'axios'
import config from 'config'

class Axios {
  config () {
    const token = localStorage.getItem('authToken')
    const serverUrl = config.server.url

    const requestParams = {
      baseURL: serverUrl,
      responseType: 'json',
      headers: {}
    }

    if (token) {
      requestParams.headers.authorization = token
    }

    return requestParams
  }

  async request (extraConfig = {}) {
    const config = Object.assign(this.config(), extraConfig)

    try {
      const response = await axios.request(config)

      return response.data
    } catch (error) {
      throw this.error(error)
    }
  }

  error (error) {
    let message = error.message

    if (error && error.response && error.response.status === 401) {
      localStorage.setItem('authToken', '')
      return false
    }

    if (error.response && error.response.data) {
      if (error.response.data.message) {
        message = error.response.data.message
      }
    }

    return {
      error: message
    }
  }

  get (url, query = {}, options = {}) {
    return this.request({
      method: 'get',
      url,
      params: query,
      ...options
    })
  }

  post (url, body = {}, options = {}) {
    return this.request({
      method: 'post',
      url,
      data: body,
      ...options
    })
  }

  put (url, body = {}, options = {}) {
    return this.request({
      method: 'put',
      url,
      data: body,
      ...options
    })
  }

  delete (url, query = {}, options = {}) {
    return this.request({
      method: 'delete',
      url,
      params: query,
      ...options
    })
  }
}

const axiosClient = new Axios()

export default axiosClient
