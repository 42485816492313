import React from 'react'
import { connect } from 'react-redux'

import { injectIntl } from 'react-intl'
import { Form, Tabs } from 'antd'

import InformationsTab from './Tabs/Informations/'
import RecurringTab from './Tabs/Recurring/'
import Updates from '../../../../../../components/CommentsTab/'
import FileUploadTab from '../../../../../../components/FileUploadTab/'
import {
  updateWorkorderField,
  addFile,
  deleteFile,
  addComment
} from '../../../../../../ducks/workorder'
import './styles.scss'
import PropTypes from 'prop-types'

const { TabPane } = Tabs

class WorkOrderForm extends React.Component {
  onDelete = file => {
    const { dispatch } = this.props
    return dispatch(deleteFile(file._id))
      .then(() => {
        return 'success'
      })
      .catch(() => {
        return 'error'
      })
  }

  onAdd = async image => {
    const { workorder, dispatch } = this.props
    const formData = new FormData()
    formData.append('file', image)
    return dispatch(addFile(workorder.income._id, formData))
      .then(() => {
        return 'success'
      })
      .catch(error => {
        console.log(error)
        return 'error'
      })
  }

  onUpdateComment = (field, array) => {
    const { dispatch } = this.props
    dispatch(updateWorkorderField({ [field]: array }))
  }

  onCommentAdd = (id, data) => {
    const { dispatch } = this.props
    return dispatch(addComment(id, data))
  }

  render () {
    const { intl, action, workorder } = this.props
    return (
      <Form className='workOrdersForm'>
        <Tabs defaultActiveKey='informations'>
          <TabPane
            tab={intl.formatMessage({
              id: 'informations'
            })}
            key='informations'
          >
            {workorder.income && <InformationsTab />}
          </TabPane>
          {workorder.income && workorder.income._id && (
            <TabPane
              tab={this.props.intl.formatMessage({ id: 'files' })}
              key='files'
            >
              <FileUploadTab
                action={action}
                files={
                  (workorder.value && workorder.value.files) ||
                  (workorder.income && workorder.income.files) ||
                  []
                }
                handleDelete={this.onDelete}
                handleAdd={this.onAdd}
                disabled={workorder.income.status === 5}
              />
            </TabPane>
          )}

          {this.props.scheduled ? (
            <TabPane
              tab={this.props.intl.formatMessage({
                id: 'recurring'
              })}
              key='recurring'
              className='workorder-tab-recurring'
            >
              <RecurringTab
                updateStateWorkOrder={this.props.updateStateWorkOrder}
                workOrder={this.props.workOrder}
              />
            </TabPane>
          ) : null}

          {workorder.income && workorder.income._id && (
            <TabPane
              tab={this.props.intl.formatMessage({
                id: 'updates'
              })}
              key='updates'
            >
              <Updates
                addComment={this.onCommentAdd}
                updateItem={this.onUpdateComment}
                data={
                  workorder.value.comments ? workorder.value : workorder.income
                }
                disabled={workorder.income.status === 5}
              />
            </TabPane>
          )}
        </Tabs>
      </Form>
    )
  }
}

WorkOrderForm.propTypes = {
  workorder: PropTypes.object,
  workOrder: PropTypes.object,
  dispatch: PropTypes.func,
  intl: PropTypes.object,
  action: PropTypes.object,
  scheduled: PropTypes.bool,
  updateStateWorkOrder: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    workorder: state.workorder.pickedWorkorder
  }
}
export default injectIntl(connect(mapStateToProps)(WorkOrderForm))
