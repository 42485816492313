import React, { useContext } from 'react'
import config from 'config'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import Zoom from 'react-medium-image-zoom'
import { Form, Input, PageHeader } from 'antd'

import useApi from '../../../../hooks/useApi'
import imageService from '../../../../services/image'
import defectsService from '../../../../services/defects'
import FileUpload from '../../../../components/FileUpload'
import Popconfirm from '../../../../components/Popconfirm'
import { PageContext } from '../../../../providers/PageProvider'

const { Item } = Form
const { TextArea } = Input

const ImageItemWrapper = styled('div')`
  width: 49%;
  display: flex;
  padding: 10px;
  margin-top: 10px;
  flex-direction: column;
  border: 1px solid #d9d9d9;

  .ant-form-item {
    margin: 0 !important;
  }

  .uploader-image-cover {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }

  .confirm-delete-cover {
    top: 0;
    right: 0;
    z-index: 1;
    position: absolute;
  }

  .image-small {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
`

const itemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

const ImageItem = ({ file, disabled, onRemove }) => {
  const intl = useIntl()
  const { _id, imageUrl, fileUrl } = file
  const { server: { url } = {} } = config || {}

  return (
    <ImageItemWrapper>
      <div className='uploader-image-cover'>
        <div className='confirm-delete-cover'>
          <Popconfirm
            value='X'
            disabled={disabled}
            onConfirmAction={() => onRemove(file)}
          />
        </div>
        <Zoom zoomMargin={40}>
          <img
            alt='image'
            className='img image-small'
            style={{ maxWidth: '100%' }}
            src={`${url}${imageUrl || fileUrl}`}
          />
        </Zoom>
      </div>
      <Item
        {...itemLayout}
        name={['images', _id, 'comment']}
        label={intl.formatMessage({ id: 'description' })}
      >
        <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
      </Item>
    </ImageItemWrapper>
  )
}

ImageItem.propTypes = {
  disabled: PropTypes.bool,
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired
}

const Images = ({ defect }) => {
  const intl = useIntl()
  const { _id, images } = defect
  const { data, setData, setDefect } = useContext(PageContext)

  const [, deleteImage] = useApi(imageService.delete)
  const [uploading, uploadImage] = useApi(defectsService.addImage)

  const handleUpload = image => {
    const formData = new FormData()
    formData.append('image', image)
    return uploadImage(_id, formData)
  }

  const handleRemove = ({ _id }) => deleteImage(_id)

  const handleChange = images => {
    const item = { ...defect, images }
    const docs = Array.from((data || {}).docs || [])
    docs.splice(
      docs.findIndex(({ _id }) => _id === item._id),
      1,
      item
    )

    setData({ ...data, docs })
    setDefect({ ...defect, images })
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'images upload'
        })}
      />
      <FileUpload
        value={images}
        loading={uploading}
        renderItem={ImageItem}
        onChange={handleChange}
        onUpload={handleUpload}
        onRemove={handleRemove}
      />
    </>
  )
}

Images.propTypes = {
  defect: PropTypes.object.isRequired
}

export default Images
