import React from 'react'
import { useIntl } from 'react-intl'
import { Input, Select } from 'antd'
import { Form } from '@ant-design/compatible'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const { Item } = Form
const { Option } = Select

const FieldDefinitionForm = props => {
  const intl = useIntl()
  const { getFieldDecorator } = props.form
  const { pickedField } = props

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    className: 'formItem'
  }
  return (
    <Form {...formItemLayout}>
      <Item label={intl.formatMessage({ id: 'key' })}>
        {getFieldDecorator('key', {
          initialValue: pickedField.key,
          rules: [
            {
              required: true,
              pattern: new RegExp('^[a-zA-Z0-9_]{1,40}$'),
              message: `${intl.formatMessage({
                id: 'you can only use letters, numbers and underscore'
              })}`
            }
          ]
        })(<Input disabled={pickedField._id} />)}
      </Item>

      <Item label={intl.formatMessage({ id: 'type' })}>
        {getFieldDecorator('type', {
          initialValue: pickedField.type || 'text',
          rules: [
            {
              required: true,
              message: `Type ${intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Select
            showSearch
            disabled={pickedField._id}
            style={{ width: '100%' }}
            placeholder='Select a type'
          >
            <Option value='text'>text</Option>
          </Select>
        )}
      </Item>

      <Item label={intl.formatMessage({ id: 'label' })}>
        {getFieldDecorator('label', {
          initialValue: pickedField.label,
          rules: [
            {
              required: true,
              message: `Label ${intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(<Input />)}
      </Item>
    </Form>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  drawerVisible: state.fields.drawerVisible,
  pickedField: state.fields.pickedField
})

FieldDefinitionForm.propTypes = {
  form: PropTypes.object.isRequired,
  pickedField: PropTypes.object
}

export default Form.create({ name: 'field' })(
  connect(mapStateToProps, null)(FieldDefinitionForm)
)
