import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Avatar, Comment, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons'

import Editor from './Editor'
import Popconfirm from '../Popconfirm'
import useApi from '../../hooks/useApi'

const CommentItem = ({ comment, disabled, onUpdate, onRemove }) => {
  const intl = useIntl()

  const { _id, createdBy, createdAt, text: defaultText } = comment

  const { id: userId, fullName } = createdBy || {}

  const [text, setText] = useState(defaultText)
  const [edit, setEdit] = useState(false)
  const [updating, updateComment] = useApi(onUpdate)

  const currentUserId = useSelector(
    ({
      auth: {
        authUser: { id }
      }
    }) => id
  )

  const handleSubmit = async () => {
    await updateComment({ _id, text })
    setEdit(false)
  }

  const handleCancel = () => {
    setText(defaultText)
    setEdit(false)
  }

  const handleDelete = async () => {
    await onRemove(comment)
    setEdit(false)
  }

  const actions = !edit &&
    userId === currentUserId && [
      <span key='edit-action'>
        <Tooltip title={intl.formatMessage({ id: 'edit comment' })}>
          <EditOutlined onClick={() => setEdit(true)} />
        </Tooltip>
      </span>,
      <span key='remove-action'>
        <Popconfirm
          disabled={disabled}
          value={<DeleteOutlined />}
          onConfirmAction={() => handleDelete()}
        />
      </span>
    ]

  return (
    <Comment
      actions={actions}
      author={fullName}
      avatar={<Avatar icon={<UserOutlined />} />}
      content={
        edit ? (
          <Editor
            isEdit
            value={text}
            loading={updating}
            onChange={setText}
            disabled={disabled}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        ) : (
          <p>{text}</p>
        )
      }
      datetime={moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
    />
  )
}

CommentItem.propTypes = {
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired
}

export default CommentItem
