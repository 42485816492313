import axios from '../helpers/axios'
import config from 'config'
const axiosCustom = require('axios')

export default {
  async list () {
    return axios.get('/field-definition')
  },

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/field-definition', params)
  },

  async searchFields (limit, offset, name) {
    const token = localStorage.getItem('authToken')
    const response = await axiosCustom.get(
      `${config.server.host}/1.1/field-definition?limit=${limit}&label[]=${name}`,
      {
        headers: {
          authorization: token
        }
      }
    )
    return response.data
  },

  async save (field) {
    const params = {
      key: field.key,
      label: field.label
    }

    if (field._id) {
      return axios.put(`/field-definition/${field._id}`, params)
    } else {
      return axios.post('/field-definition', params)
    }
  }
}
