import React, { useContext } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Avatar, Comment } from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { UserOutlined } from '@ant-design/icons'

import Comments from '../../../../components/Comments'
import commentService from '../../../../services/comment'
import defectsService from '../../../../services/defects'
import { PageContext } from '../../../../providers/PageProvider'

const UpdatesComponent = styled('div')`
  padding-bottom: 30px;
`

const Updates = ({ defect }) => {
  const { _id, comments, createdBy, createdAt } = defect
  const { data, setData, setDefect } = useContext(PageContext)

  const handleCreate = comment => defectsService.addComment(_id, comment)

  const handleUpdate = ({ _id, ...comment }) =>
    commentService.update(_id, comment)

  const handleDelete = ({ _id }) => commentService.delete(_id)

  const handleChange = comments => {
    const item = { ...defect, comments }
    const docs = Array.from((data || {}).docs || [])
    docs.splice(
      docs.findIndex(({ _id }) => _id === item._id),
      1,
      item
    )

    setData({ ...data, docs })
    setDefect(item)
  }

  const { fullName = '' } = createdBy || {}

  return (
    <UpdatesComponent>
      <Comments
        value={comments}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onRemove={handleDelete}
        onChange={handleChange}
      />

      <Comment
        author={fullName}
        avatar={<Avatar icon={<UserOutlined />} />}
        content={<FormattedMessage id='defect created' />}
        datetime={moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
      />
    </UpdatesComponent>
  )
}

Updates.propTypes = {
  defect: PropTypes.object.isRequired
}

export default Updates
