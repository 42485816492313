import React from 'react'
import { Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useLocation, useHistory } from 'react-router-dom'

import { fetchProjectList, setPickedProject } from '../../../ducks/project'
import queryString from 'query-string'

const mapStateToProps = state => ({
  settings: state.settings,
  auth: state.auth,
  projects: state.project.projectList,
  project: state.project.pickedProject
})

const Header = () => {
  const { projects, project } = useSelector(mapStateToProps)

  const location = useLocation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()
  const hiddenProjectSelection = [
    '/projects',
    '/settings',
    '/forms',
    '/products',
    '/categories',
    '/manufacturers',
    '/users',
    '/contacts',
    '/object-types',
    '/field-definition',
    '/checklist'
  ]

  const changeProject = (projectID, e) => {
    if (e && e.props && e.props.data) {
      dispatch(setPickedProject(e.props.data))
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify(
          { project: e.props.data._id },
          { arrayFormat: 'bracket' }
        )
      })
    } else {
      dispatch(setPickedProject({}))
      history.push({
        pathname: history.location.pathname
      })
    }
  }

  return (
    <>
      {projects.length > 0 &&
        !hiddenProjectSelection.includes(location.pathname) && (
          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            placeholder={intl.formatMessage({
              id: 'select a project'
            })}
            optionFilterProp='children'
            onSearch={value => fetchProjectList(value)}
            onChange={changeProject}
            value={
              project
                ? project.projectName
                : intl.formatMessage({
                    id: 'select a project'
                  })
            }
          >
            {projects.map(project => (
              <Select.Option
                key={project._id}
                data={project}
                value={project._id}
              >
                {project.projectName}
              </Select.Option>
            ))}
          </Select>
        )}
    </>
  )
}

export default Header
