import React, { useState, useRef } from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'

import useApi from '../../../hooks/useApi'
import usersService from '../../../services/users'

import FormForgotPassword from './Form/FormForgotPassword'

const ForgotPasswordPage = () => {
  const intl = useIntl()
  const form = useRef()
  const [showResult, setShowResult] = useState(null)

  const [loading, sendToken] = useApi(usersService.sendToken)

  const handleSubmit = async () => {
    try {
      const values = await form.current.validateFields()
      await sendToken(values.email)
      setShowResult('success')
    } catch (error) {
      setShowResult('error')
    }
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      {!showResult && (
        <>
          <h4>
            <FormattedMessage id='forgot password' />
          </h4>
          <FormForgotPassword
            ref={form}
            finishAction={handleSubmit}
            loading={loading}
          />
        </>
      )}

      {showResult === 'success' && (
        <Result
          status='success'
          title={intl.formatMessage({ id: 'email sent' })}
          subTitle={intl.formatMessage({
            id:
              'if the user was found in our database, we have sent an email to the provided address.'
          })}
        />
      )}

      {showResult === 'error' && (
        <Result
          status='error'
          title={intl.formatMessage({ id: 'something went wrong.' })}
          subTitle={intl.formatMessage({
            id: 'something went wrong. please contact DDC Schweiz AG'
          })}
        />
      )}

      <Link to='/login'>
        <FormattedMessage id='login' />
      </Link>
    </>
  )
}

export default ForgotPasswordPage
