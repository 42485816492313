import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Button, Input } from 'antd'
import { Form, Icon } from '@ant-design/compatible'
import specialWorkService from 'services/specialWork'
import { findIndexInArray } from 'helpers/utils'

import SpecialWorkForm from './FormSpecialwork'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import Popconfirm from 'components/Popconfirm'

import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'

export class SpecialWorkPage extends React.Component {
  state = {
    items: [],
    item: {},
    new: false,
    visibleDrawer: false,
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    params: {},
    submitting: false
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project && project.project) {
      paramsObject.project = project.project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchSpecialWork()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (
      params.project &&
      !prevProps.project.projectList.length &&
      project.projectList.length
    ) {
      dispatch(
        setPickedProject(
          project.projectList.find(item => item._id === params.project)
        )
      )
    }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }

    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchSpecialWork()
    }
  }

  fetchSpecialWork = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const specialWork = await specialWorkService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = specialWork.totalDocs

      this.setState({
        items: specialWork.docs,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: specialWork.limit,
          current: specialWork.page || 1
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'specialwork'
        })} - download error`
      })

      this.setState({
        visibleDrawer: false,
        loading: false
      })
    }
  }

  handleShowDrawerAdd = async item => {
    this.setState({
      visibleDrawer: true,
      new: true,
      item
    })
  }

  showDrawerEdit = async item => {
    this.setState({
      visibleDrawer: true,
      new: false,
      item
    })
  }

  handleHideDrawer = () => {
    this.setState({
      visibleDrawer: false,
      item: {}
    })
  }

  handleAddSpecialWork = async () => {
    const { item, items } = this.state
    this.setState({
      submitting: true
    })
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name: item.name,
            title: item.title,
            description: item.description,
            enabled: item.enabled
          }

          const specialWorkResponse = await specialWorkService.add(payload)

          this.fetchSpecialWork()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'created successfully'
            })}`
          })

          const updatedArray = [...items]
          updatedArray.unshift(specialWorkResponse)

          this.setState({
            visibleDrawer: false,
            item: {},
            items: [...updatedArray]
          })
          setTimeout(() => this.setState({ submitting: false }), 1500)
        } catch (error) {
          this.setState({
            submitting: false
          })
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      } else {
        this.setState({
          submitting: false
        })
      }
    })
  }

  handleSaveSpecialWork = async () => {
    const { item, items } = this.state
    this.setState({
      submitting: true
    })
    try {
      const payload = {
        name: item.name,
        title: item.title,
        description: item.description,
        enabled: item.enabled
      }

      await specialWorkService.update(item._id, payload)

      this.fetchSpecialWork()
      notification.success({
        message: `${this.props.intl.formatMessage({
          id: 'updated successfully'
        })}`
      })

      const updateIndex = findIndexInArray(items, item._id)
      const updatedArray = [...items]
      updatedArray[updateIndex] = item
      this.setState({
        visibleDrawer: false,
        item: {},
        items: [...updatedArray]
      })
      setTimeout(() => this.setState({ submitting: false }), 1500)
    } catch (error) {
      this.setState({
        submitting: false
      })
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error'
        }),
        description: error.message
      })
    }
  }

  handleDeleteSpecialWork = async () => {
    try {
      await specialWorkService.delete(this.state.item._id)

      notification.success({
        message: this.props.intl.formatMessage({
          id: 'deleted successfully'
        })
      })

      this.setState({
        visibleDrawer: false,
        item: {},
        items: [
          ...this.state.items.filter(item => item._id !== this.state.item._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error'
        }),
        description: error.message
      })
    }
  }

  updateStateSpecialWork = (field, value) => {
    this.setState({
      item: {
        ...this.state.item,
        [field]: value
      }
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          disabled={!this.state[dataIndex + 'Search']}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    // onFilter: (value, record) => String(record[dataIndex])
    //   .toString()
    //   .toLowerCase()
    //   .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const { intl } = this.props
    const { submitting } = this.state
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: intl.formatMessage({ id: 'active' }),
        dataIndex: 'enabled',
        key: 'enabled',
        render: active =>
          active && (
            <Icon
              type='check'
              style={{ color: '#005591', display: 'flex', alignSelf: 'center' }}
            />
          ),
        filters: [
          {
            text: intl.formatMessage({ id: 'active' }),
            value: true
          },
          {
            text: intl.formatMessage({ id: 'inactive' }),
            value: false
          }
        ],
        filteredValue: this.state.params.enabled
      },
      {
        title: intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: intl.formatMessage({ id: 'created by' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        sorter: true,
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={() => this.showDrawerEdit(record)}>
              <Icon type='edit' />
            </Button>
          </div>
        )
      }
    ]

    const headerButtons = [
      <Button key={59934324} type='primary' onClick={this.handleShowDrawerAdd}>
        <FormattedMessage id='create special work' />
      </Button>
    ]

    const footerDrawerButtons = [
      <Button
        key={0}
        onClick={this.handleHideDrawer}
        style={{ marginRight: 8 }}
      >
        <FormattedMessage id='cancel' />
      </Button>
    ]

    if (
      ['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      this.state.item &&
      !this.state.new
    ) {
      footerDrawerButtons.unshift(
        <Popconfirm key={1} onConfirmAction={this.handleDeleteSpecialWork} />
      )
    }

    footerDrawerButtons.push(
      <Button
        key={2}
        onClick={this.handleAddSpecialWork}
        type='primary'
        loading={submitting}
        disabled={submitting}
      >
        <FormattedMessage id='save' />
      </Button>
    )

    return (
      <div>
        <FormattedMessage id='head.title.specialwork'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'specialwork' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          columns={columns}
          dataSource={this.state.items}
          loading={this.state.loading}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawerEdit(record)
            }
          })}
          expandedRowRender={record => {
            return (
              <div>
                <p style={{ fontWeight: 'bold' }}>{record.title}</p>
                <p>{record.description}</p>
              </div>
            )
            // return (<Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(record.description)))} readOnly={true} />)
          }}
          rowKey={record => record._id}
          onChange={this.handleTableChange}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={
            this.state.new === true
              ? intl.formatMessage({
                  id: 'create special work'
                })
              : this.props.intl.formatMessage({
                  id: 'edit special work'
                })
          }
          onClose={this.handleHideDrawer}
          visible={this.state.visibleDrawer}
          footerButtons={footerDrawerButtons}
        >
          <SpecialWorkForm
            item={this.state.item}
            editorState={this.state.editorState}
            // onEditorStateChange={this.handleEditorStateChange}
            updateStateSpecialWork={this.updateStateSpecialWork}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

SpecialWorkPage.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object.isRequired,
  project: PropTypes.object,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  project: state.project
})

export default injectIntl(
  Form.create({ name: 'specialwork_page' })(
    connect(mapStateToProps)(SpecialWorkPage)
  )
)
