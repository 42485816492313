import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormWorkspace from './FormWorkspace'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import workspacesService from '../../../services/workspaces'
import Popconfirm from '../../../components/Popconfirm'

const WorkspaceManagement = ({ visible, workspace, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createWorkspace] = useApi(workspacesService.addWorkspace)
  const [updating, updateWorkspace] = useApi(workspacesService.updateWorkspace)
  const [deleting, deleteWorkspace] = useApi(workspacesService.deleteWorkspace)

  const handleSubmit = async () => {
    try {
      const { _id } = workspace || {}
      const values = await form.current.validateFields()
      onSubmit(
        await (_id ? updateWorkspace(_id, values) : createWorkspace(values))
      )
    } catch (error) {}
  }

  const handleDelete = async () =>
    onSubmit(await deleteWorkspace(workspace._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && workspace && !workspace.isVerified) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${workspace ? 'edit' : 'add'} workspace`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormWorkspace ref={form} workspace={workspace} />
    </Drawer>
  )
}

WorkspaceManagement.propTypes = {
  workspace: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

WorkspaceManagement.defaultProps = {
  visible: false
}

export default WorkspaceManagement
