import React, { useState, useRef, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Button, notification } from 'antd'
import PropTypes from 'prop-types'

import {
  setRecordingDefectCreationData,
  createRecordingDefect
} from '../../ducks/recordings'

import RecordingPreview from './RecordingPreview'
import DrawerComponent from 'components/Drawer'

import DefectForm from './Form'
import ObjectsDrawer from '../../pages/SettingsPages/Objects/ObjectsDrawer'
import objectService from 'services/object'

const ModalDefectCreation = props => {
  const refSpecific = useRef()
  const [formValue, setFormValue] = useState({})
  const [errors, setErrors] = useState({})
  const [object, setObject] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [drawerObjectVisible, setDrawerObjectVisible] = useState(false)

  const handleNextRecord = () => {
    const { dispatch, defectData } = props

    const currentIndex = defectData.step.index
    if (currentIndex + 1 < defectData.step.total) {
      const incomeData = { ...defectData }
      const stateValue = formValue
      const propsValue = defectData.income

      const outputData = { ...incomeData.list[currentIndex + 1] }

      if (stateValue.protocolRefExt || propsValue.protocolRefExt) {
        outputData.protocolRefExt =
          stateValue.protocolRefExt || propsValue.protocolRefExt
      }
      if (stateValue.protocolResponsible || propsValue.protocolResponsible) {
        outputData.protocolResponsible =
          stateValue.protocolResponsible || propsValue.protocolResponsible
      }
      if (stateValue.assignedTo || propsValue.assignedTo) {
        outputData.assignedTo = stateValue.assignedTo || propsValue.assignedTo
      }

      if (stateValue.contractSection || propsValue.contractSection) {
        outputData.contractSection =
          stateValue.contractSection || propsValue.contractSection
      }

      const actualSituation =
        (outputData.images &&
          outputData.images.map(image => image.comment).join('; ')) ||
        ''

      dispatch(
        setRecordingDefectCreationData({
          ...incomeData,
          income: { ...outputData, actualSituation },
          step: { ...defectData.step, index: currentIndex + 1 }
        })
      )
      setFormValue({})
    } else {
      dispatch(setRecordingDefectCreationData({}))
    }
  }

  const handleSaveDefect = () => {
    const {
      dispatch,
      defectData: { income },
      defectData,
      intl
    } = props
    const payload = { ...formValue }

    setConfirmLoading(true)

    if (Object.keys(object).length) {
      income.objectId = object
    }

    if (
      !payload.name ||
      !(payload.name && payload.name.length > 0) ||
      (!payload.objectId && !income.objectId)
    ) {
      setErrors({
        name: !payload.name || payload.name.length === 0,
        objectId:
          !(payload.objectId && payload.objectId.length === 0) &&
          !income.objectId
      })
      setConfirmLoading(false)
      return
    }

    dispatch(createRecordingDefect(payload, formValue)).then(response => {
      setTimeout(() => setConfirmLoading(true), 1500)

      if (!response.error) {
        notification.success({
          message: `${response.docNumber} ${intl.formatMessage({
            id: 'created successfully'
          })} \n ${intl.formatMessage({
            id: 'recording archived'
          })}`
        })
        if (defectData.type !== 'acceptance') {
          setTimeout(() => dispatch(setRecordingDefectCreationData({})), 1000)
        } else {
          handleNextRecord()
        }
      } else {
        setConfirmLoading(false)
        notification.error({
          message: response.error
        })
      }
    })
  }

  const handleObjectClose = () => {
    setDrawerObjectVisible(false)
  }

  const updateStateDefect = (key, value) => {
    setFormValue({
      ...formValue,
      [key]: value
    })
    setErrors({})
  }

  const handleClose = () => {
    const { dispatch } = props
    dispatch(setRecordingDefectCreationData({}))
    setFormValue({})
    setErrors({})
    setConfirmLoading(false)
  }

  const handleObjectOpen = () => {
    setDrawerObjectVisible(true)
  }

  const updateObject = (field, value) => {
    setObject({
      ...object,
      [field]: value !== undefined ? value : null
    })
  }

  const onSaveObject = async form => {
    const { intl } = props

    form.validateFields(async (err, values) => {
      if (!err) {
        console.log('err')
        console.log(err)
        try {
          const savedObject = await objectService.save({
            ...object,
            ...values
          })
          notification.success({
            message: `${intl.formatMessage({
              id: 'created successfully'
            })}`
          })

          setDrawerObjectVisible(false)
          setObject({
            ...object,
            ...savedObject
          })
        } catch (error) {
          console.log('error')
          console.log(error)
          if (error.error.indexOf('to be unique') > -1) {
            notification.error({
              message: intl.formatMessage({
                id: 'object already exists'
              })
            })
          } else {
            notification.error({
              message: intl.formatMessage({
                id: 'saving error'
              }),
              description: error.error
            })
          }
        }
      }
    })
  }

  const FooterButtons = () => (
    <>
      <Button onClick={handleClose}>
        <FormattedMessage id='cancel' />
      </Button>
      {defectData.type === 'acceptance' && (
        <Button onClick={handleNextRecord}>
          <FormattedMessage id='skip' />
        </Button>
      )}

      <Button
        key='ok'
        type='primary'
        onClick={handleSaveDefect}
        loading={confirmLoading}
        disabled={confirmLoading}
      >
        <FormattedMessage id='create defect' />
      </Button>
    </>
  )

  const { defectData, intl } = props

  useEffect(() => {
    if (defectData.income && object._id) {
      defectData.income.objectId = object
    }
  }, [object, defectData])

  return (
    <DrawerComponent
      title={`${intl.formatMessage({
        id: 'defect creation'
      })} ${
        defectData.step
          ? defectData.step.index + 1 + '/' + defectData.step.total
          : ''
      }`}
      onClose={handleClose}
      onCancel={handleClose}
      visible={Object.keys(defectData).length > 0}
      hideFooter={defectData.type === 'acceptance'}
      footerButtons={<FooterButtons />}
    >
      <p>{intl.formatMessage({ id: 'completeInfoBelow' })}</p>
      {defectData.income &&
        defectData.income.images &&
        defectData.income.images.length > 0 && (
          <RecordingPreview recording={defectData.income} />
        )}

      <DefectForm
        data={
          defectData.income
            ? object._id
              ? { ...defectData.income, objectId: object }
              : defectData.income
            : {}
        }
        updateStateDefect={updateStateDefect}
        errors={errors}
        formValue={formValue}
        handleObjectOpen={handleObjectOpen}
        refSpecific={refSpecific}
      />

      <ObjectsDrawer
        object={object}
        visible={drawerObjectVisible}
        updateObject={updateObject}
        handleSaveObject={onSaveObject}
        handleHideDrawer={handleObjectClose}
      />
    </DrawerComponent>
  )
}

ModalDefectCreation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defectData: PropTypes.object,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    defectData: state.recordings.recordingDefectCreationData
  }
}

export default injectIntl(connect(mapStateToProps)(ModalDefectCreation))
