import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Button } from 'antd'
import { FormattedMessage } from 'react-intl'

const { Item } = Form
const { TextArea } = Input

const itemLayout = {
  wrapperCol: { span: 24 }
}

const EditorWrapper = styled('div')`
  .ant-form-item .ant-btn {
    margin-right: 7px;
  }
`

const Editor = ({
  value,
  isEdit,
  loading,
  disabled,
  onChange,
  onSubmit,
  onCancel,
  defaultValue
}) => {
  const [state, setState] = useState(defaultValue || '')

  const handleChange = ({ target: { value } }) => {
    typeof onChange === 'function' ? onChange(value) : setState(value)
  }

  const comment = value || state

  return (
    <EditorWrapper>
      <Item {...itemLayout}>
        <TextArea
          value={comment}
          onChange={handleChange}
          disabled={disabled || loading}
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
      </Item>
      <Item {...itemLayout}>
        <Button
          type='primary'
          loading={loading}
          onClick={onSubmit}
          disabled={!comment || disabled}
        >
          <FormattedMessage id={isEdit ? 'edit comment' : 'add comment'} />
        </Button>
        {isEdit && (
          <Button onClick={onCancel} disabled={disabled || loading}>
            <FormattedMessage id='cancel' />
          </Button>
        )}
      </Item>
    </EditorWrapper>
  )
}

Editor.propTypes = {
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string
}

export default Editor
