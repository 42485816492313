import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormProduct from './FormProduct'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import productService from '../../../services/products'
import Popconfirm from '../../../components/Popconfirm'

const ProductManagement = ({
  visible,
  product,
  onSubmit,
  onClose,
  createManufacturer,
  createCategory,
  manufacturerManagement,
  categoryManagement
}) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createProduct] = useApi(productService.addProduct)
  const [updating, updateProduct] = useApi(productService.updateProduct)
  const [deleting, deleteProduct] = useApi(productService.deleteProduct)

  const handleSubmit = async () => {
    try {
      const { _id } = product || {}
      const values = await form.current.validateFields()

      onSubmit(await (_id ? updateProduct(_id, values) : createProduct(values)))
    } catch (error) {}
  }

  const handleDelete = async () => onSubmit(await deleteProduct(product._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (
    ['admin', 'superadmin'].includes(scope) &&
    product && product._id &&
    !product.isVerified
  ) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${product && product._id ? 'edit' : 'add'} product`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormProduct
        ref={form}
        product={product}
        createManufacturer={createManufacturer}
        createCategory={createCategory}
      />

      {manufacturerManagement()}
      {categoryManagement()}
    </Drawer>
  )
}

ProductManagement.propTypes = {
  product: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  createManufacturer: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  manufacturerManagement: PropTypes.func.isRequired,
  categoryManagement: PropTypes.func.isRequired
}

ProductManagement.defaultProps = {
  visible: false
}

export default ProductManagement
