import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Table, Button } from 'antd'
import { Helmet } from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'
import { EditOutlined } from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import CategoryManagement from './CategoryManagement'
import searchColumn from '../../../utils/searchColumn'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'
import categoriesService from '../../../services/categories'

const CategoriesPage = () => {
  const intl = useIntl()
  const input = useRef()
  const [category, setCategory] = useState()
  const [data, setData] = useState({})
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, fetchCategories] = useApi(categoriesService.listV2)

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchCategories({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = category => {
    setCategory(category)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setCategory()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const { filterOptions: { createdBy: createdByFilters = [] } = {} } = data

  const columns = [
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input, intl, value: filters.name })
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: user._id
        }
      }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    },
    {
      width: 65,
      align: 'right',
      key: 'actions',
      render: record => (
        <Button onClick={() => handleOpen(record)}>
          <EditOutlined />
        </Button>
      )
    }
  ]

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.categories'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'categories' })}
        buttons={[
          <Button
            type='primary'
            key='create_category'
            onClick={() => handleOpen()}
          >
            <FormattedMessage id='create category' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      <CategoryManagement
        visible={drawerVisible}
        category={category}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  )
}

export default CategoriesPage
