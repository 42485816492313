import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormDefect from './FormDefect'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import imageService from '../../../services/image'
import defectsService from '../../../services/defects'
import Popconfirm from '../../../components/Popconfirm'

const DefectManagement = ({ visible, defect, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createDefect] = useApi(defectsService.create)
  const [updating, updateDefect] = useApi(defectsService.update)
  const [deleting, deleteDefect] = useApi(defectsService.delete)

  const handleSubmit = async () => {
    try {
      const { _id, images, ...values } = await form.current.validateFields()

      await Promise.all(
        Object.entries(images || {})
          .filter(([_id]) =>
            form.current.isFieldTouched(['images', _id, 'comment'])
          )
          .map(([_id, { comment }]) => {
            const formData = new FormData()
            formData.append('comment', comment)
            return imageService.update(_id, formData)
          })
      )

      onSubmit(await (_id ? updateDefect(_id, values) : createDefect(values)))
    } catch (error) {}
  }

  const handleDelete = async () => onSubmit(await deleteDefect(defect._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (['admin', 'superadmin'].includes(scope) && !!defect) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  const { docNumber } = defect || {}

  return (
    <Drawer
      title={
        docNumber ||
        intl.formatMessage({
          id: 'create defect'
        })
      }
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormDefect ref={form} defect={defect} />
    </Drawer>
  )
}

DefectManagement.propTypes = {
  defect: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

DefectManagement.defaultProps = {
  visible: false
}

export default DefectManagement
