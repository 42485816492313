import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Radio } from 'antd'

import '../../../styles.scss'
import { updateWorkorderField } from '../../../../../../../../../ducks/workorder'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

const priorityOptions = [
  { value: 0, label: 'none' },
  { value: 1, label: 'low' },
  { value: 2, label: 'medium' },
  { value: 3, label: 'high' }
]

class Priority extends React.Component {
  onPriorityChanged = value => {
    this.props.dispatch(updateWorkorderField({ priority: value }))
  }

  render () {
    const { workorder, intl } = this.props

    return (
      <>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'priority'
          })}
        >
          <Radio.Group
            defaultValue={workorder.income.priority}
            buttonStyle='solid'
            disabled={workorder.income.status === 5}
          >
            {priorityOptions.map(priority => (
              <Radio.Button
                key={priority.value}
                value={priority.value}
                onChange={e => this.onPriorityChanged(e.target.value)}
              >
                {intl.formatMessage({ id: priority.label })}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      </>
    )
  }
}

Priority.propTypes = {
  intl: PropTypes.object.isRequired,
  workorder: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = state => {
  return {
    workorder: state.workorder.pickedWorkorder
  }
}
export default injectIntl(connect(mapStateToProps)(Priority))
