import config from 'config'
import axios from '../helpers/axios'

const defectsService = {
  listV2: (params = {}) =>
    axios.get(config.server.host + '/1.1/defect', params),

  completeTask: (id, data = {}) => axios.post(`/defect/${id}/complete`, data),

  submit: id => axios.post(`/defect/${id}/submit`),

  create: data => axios.post('/defect', data),

  delete: id => axios.delete(`/defect/${id}`),

  update: (id, data) => axios.put(`/defect/${id}`, data),

  addComment: (id, data) => axios.post(`/defect/${id}/comment`, data),

  addImage: (id, data) => axios.post(`/defect/${id}/image`, data),

  addFile: (id, data) => axios.post(`/defect/${id}/file`, data),

  uploadCSV: (file, force = false) => {
    const data = new FormData()
    data.append('file', file)
    return axios.post(`/defect/importcsv${force ? '?force=true' : ''}`, data)
  },

  downloadPDF: id =>
    axios.get(
      `/defect/${id}/pdf`,
      {},
      {
        responseType: 'arraybuffer'
      }
    ),

  exportCSV: (params = {}) =>
    axios.get('/defect-csv', params, {
      responseType: 'arraybuffer'
    })
}

export default defectsService
