import useService from './useService'
import userService from '../services/user'

const useUsers = (defaultValue, options = {}) =>
  useService(userService.listV2, defaultValue, {
    getArgs: value => ({
      fullName: [value],
      limit: 100
    }),
    ...options
  })

export default useUsers
