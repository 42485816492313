import config from 'config'
import axios from '../helpers/axios'

export default {
  async list (params = {}) {
    return axios.get('/task', params)
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/task', params)
  },
  async saveTask (data) {
    const response = await axios.post('/task', data)
    return response
  },
  async completeTask (id, data = {}) {
    const response = await axios.post(`/task/${id}/complete`, data)
    return response
  },
  async deleteTask (id) {
    const response = await axios.delete(`/task/${id}`)
    return response
  },
  async updateTask (id, data) {
    const response = await axios.put(`/task/${id}`, data)
    return response
  }
}
