import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Avatar, Menu, Dropdown } from 'antd'
import {
  UserOutlined,
  UpOutlined,
  PoweroffOutlined,
  GlobalOutlined,
  LeftCircleOutlined,
  RightCircleOutlined
} from '@ant-design/icons'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { logout } from '../ducks/login'
import { setLanguage } from '../ducks/settings'

const { SubMenu } = Menu

const StyledLink = styled.a`
  padding: 2.5rem 2rem;
  width: 100%;
  display: inline-block;
  text-decoration: none !important;
`

const StyledDiv = styled.div`
  display: flex;
`

const AccountAreaWrapper = styled.div`
  background: #002140;
  color: white;

  &:hover {
    background: #02315e;
  }
`

const StyledAvatar = styled(Avatar)`
  background: #189eff;
  margin-right: 1rem;
`

const StyledAvatarText = styled.div`
  flex-grow: 1;
  padding-left: 1rem;
`

const StyledAvatarName = styled.div`
  color: white;
  font-weight: bold;
`
const StyledAvatarEmail = styled.div`
  color: rgba(255, 255, 255, 0.65);
`

const GlobalStyle = createGlobalStyle`
  .ant-layout-sider-trigger {
    bottom: 84px;
    color: #737373;
    line-height: 38px;
    background: transparent;
    .anticon-left-circle {
      margin-right: 7px;
    }
  }
  .account-area-dropdown .ant-dropdown-menu {
    bottom: -4px;
    border-radius: 0;
  }
  .ant-layout-sider {
    overflow: hidden;
    padding-bottom: 84px;
    .ant-layout-sider-children {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .account-area {
      margin: 0;
      bottom: 0;
      width: 100%;
      height: 84px;
      position: absolute;
      line-height: normal;
      padding: 0!important;
    }
    &.ant-layout-sider-collapsed {
      .ant-layout-sider-trigger {
        & > div {
          display: none;
        }
      }
      .account-area {
        .ant-menu-submenu-title {
          margin: 0;
          padding: 0;
          height: 100%;
          line-height: 84px;
          text-align: center;
          .ant-avatar {
            margin-right: 0;
          }
        }
        &.ant-menu-item {
          display: none;
        }
      }
    }
    &:not(.ant-layout-sider-collapsed) {
      .ant-layout-sider-trigger {
        & > span {
          display: none;
        }
      }
      .ant-menu-submenu.account-area {
        display: none;
      }
    }
  }
  .ant-layout-sider-has-trigger {
    padding-bottom: 130px;
  }
`

const mapStateToProps = state => ({
  auth: state.auth
})

const AccountArea = props => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { auth } = useSelector(mapStateToProps)

  const menu = [
    <Menu.Item key='account-settings'>
      <span>
        <UserOutlined />
        <span>{intl.formatMessage({ id: 'account settings' })}</span>
      </span>
    </Menu.Item>,
    <SubMenu
      key='language'
      title={
        <span>
          <GlobalOutlined />
          <span>{intl.formatMessage({ id: 'language' })}</span>
        </span>
      }
    >
      <Menu.Item onClick={() => dispatch(setLanguage('de'))}>
        <span>
          <span>Deutsch</span>
        </span>
      </Menu.Item>
      <Menu.Item onClick={() => dispatch(setLanguage('en'))}>
        <span>
          <span>English</span>
        </span>
      </Menu.Item>
    </SubMenu>,
    <Menu.Item key='sign-out' onClick={() => dispatch(logout())}>
      <span>
        <PoweroffOutlined />
        <span>{intl.formatMessage({ id: 'sign out' })}</span>
      </span>
    </Menu.Item>
  ]

  return (
    <>
      <GlobalStyle />
      <SubMenu
        {...props}
        className='account-area'
        title={<StyledAvatar icon={<UserOutlined />} />}
      >
        {menu}
      </SubMenu>
      <Menu.Item {...props} className='account-area'>
        <AccountAreaWrapper>
          <Dropdown
            trigger='click'
            overlay={<Menu>{menu}</Menu>}
            overlayClassName='account-area-dropdown'
          >
            <StyledLink onClick={e => e.preventDefault()}>
              <StyledDiv>
                <StyledAvatar icon={<UserOutlined />} />
                <StyledAvatarText>
                  <StyledAvatarName>{auth.authUser.fullName}</StyledAvatarName>
                  <StyledAvatarEmail>{auth.authUser.email}</StyledAvatarEmail>
                </StyledAvatarText>
                <UpOutlined style={{ color: 'rgba(255, 255, 255, 0.65)' }} />
              </StyledDiv>
            </StyledLink>
          </Dropdown>
        </AccountAreaWrapper>
      </Menu.Item>
    </>
  )
}

const Trigger = () => (
  <>
    <RightCircleOutlined />
    <div>
      <LeftCircleOutlined />
      <FormattedMessage id='collapse sidebar' />
    </div>
  </>
)

AccountArea.Trigger = Trigger

export default AccountArea
