import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { Input, Button } from 'antd'
import { Icon, Form } from '@ant-design/compatible'

import { PasswordInput } from 'antd-password-input-strength'

import passwordValidator from '../../../../utils/passwordValidator'
import passwordService from 'services/password'

const FormResetPassword = props => {
  const intl = useIntl()
  const { getFieldDecorator } = props.form

  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(false)

  const handleFormSubmit = e => {
    setLoading(true)
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        const { token, password } = values
        passwordService
          .reset(password, token)
          .then(res => {
            props.finishAction('success')
            setLoading(false)
          })
          .catch(error => {
            console.log(error)
            props.finishAction('error')
            setLoading(false)
          })
      }
    })
  }

  const handleFormChange = (rule, value, callback) => {
    props.form.validateFields((err, values) => {
      if (!err && values.password === values.password2) {
        setButtonActive(true)
        callback()
      }

      setButtonActive(false)
      callback(intl.formatMessage({ id: 'the passwords do not match' }))
    })
  }

  const compareNewPassword = (rule, value, callback) => {
    if (value && value !== props.form.getFieldValue('password'))
      callback(intl.formatMessage({ id: 'the passwords do not match' }))
    callback()
  }

  const handleValidatePassword = (rule, value, callback) => {
    if (passwordValidator.validate(value)) {
      callback()
    } else {
      callback(intl.formatMessage({ id: 'your password is not secure' }))
    }
  }

  return (
    <Form
      layout='vertical'
      onSubmit={handleFormSubmit}
      onChange={handleFormChange}
    >
      <Form.Item>
        {getFieldDecorator('token')(<Input type='hidden' />)}{' '}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'password' })}>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'please set a password' })
            },
            { validator: handleValidatePassword }
          ]
        })(
          <PasswordInput
            autoComplete='new-password'
            prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
            type='password'
            placeholder={intl.formatMessage({ id: 'set password' })}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator('password2', {
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'please set a password' })
            },
            {
              validator: compareNewPassword
            }
          ]
        })(
          <Input.Password
            autoComplete='new-password'
            prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={intl.formatMessage({ id: 'confirm password' })}
          />
        )}
      </Form.Item>

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          disabled={!buttonActive || loading}
          loading={loading}
          block
        >
          <span>
            <FormattedMessage id='set password' />
          </span>
        </Button>
      </Form.Item>
    </Form>
  )
}

FormResetPassword.propTypes = {
  form: PropTypes.object.isRequired,
  finishAction: PropTypes.func.isRequired
}

export default Form.create({
  name: 'form_auth_reset_password',
  mapPropsToFields (props) {
    return {
      token: Form.createFormField({
        ...props.token,
        value: props.token
      })
    }
  }
})(FormResetPassword)
