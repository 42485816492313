import React, { useState } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const PriceInput = ({
  value,
  onChange,
  defaultValue,
  decimalScale,
  ...props
}) => {
  const [state, setState] = useState(value || defaultValue)

  const onValueChange = ({ floatValue }) =>
    typeof onChange === 'function' ? onChange(floatValue) : setState(floatValue)

  return (
    <NumberFormat
      {...props}
      value={state}
      decimalScale={decimalScale}
      onValueChange={onValueChange}
      isNumericString={typeof state === 'string'}
    />
  )
}

PriceInput.CustomInput = defaultProps => props => (
  <Input {...{ ...defaultProps, ...props }} />
)

PriceInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  decimalScale: PropTypes.number
}

PriceInput.defaultProps = {
  decimalScale: 2,
  customInput: Input
}

export default PriceInput
