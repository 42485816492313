import config from 'config'
import axios from 'helpers/axios'

export default {
  async getList (params = {}) {
    const response = await axios.get('/workspace', params)
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/workspace', params)
  },
  async addWorkspace (data) {
    const response = await axios.post('/workspace', data)
    return response
  },
  async deleteWorkspace (id) {
    const response = await axios.delete(`/workspace/${id}`)
    return response
  },
  async updateWorkspace (id, data) {
    const response = await axios.put(`/workspace/${id}`, data)
    return response
  }
}
