import React from 'react'
import PropTypes from 'prop-types'
import { Popconfirm as AntdPopconfirm } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'components/Global/Button'

const Popconfirm = props => {
  const intl = useIntl()

  const { value, title, onConfirmAction, icon, style, disabled } = props

  return (
    <AntdPopconfirm
      key='delete'
      title={title}
      disabled={disabled}
      onConfirm={onConfirmAction}
      okText={intl.formatMessage({ id: 'yes' })}
      cancelText={intl.formatMessage({ id: 'no' })}
    >
      <Button
        type='danger'
        value={value}
        icon={icon}
        style={{
          float: 'left',
          ...style
        }}
      />
    </AntdPopconfirm>
  )
}

Popconfirm.propTypes = {
  disabled: PropTypes.bool,
  onConfirmAction: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string,
  style: PropTypes.object
}

Popconfirm.defaultProps = {
  value: <FormattedMessage id='delete' />,
  title: <FormattedMessage id='please confirm the deletion' />
}

export default Popconfirm
