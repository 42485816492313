import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import RRuleGenerator from 'react-rrule-generator'
import translations from 'translations/rruleGenerator'
import PropTypes from 'prop-types'

class RecurringTab extends React.Component {
  getTranslation = () =>
    this.props.intl.locale === 'de' ? translations.de : translations.en

  render () {
    const { updateStateWorkOrder, workOrder } = this.props
    return (
      <>
        <RRuleGenerator
          config={{ hideStart: false }}
          value={workOrder.rrule}
          translations={this.getTranslation()}
          onChange={rrule => updateStateWorkOrder('rrule', rrule)}
        />
      </>
    )
  }
}

RecurringTab.propTypes = {
  updateStateWorkOrder: PropTypes.func.isRequired,
  workOrder: PropTypes.object,
  intl: PropTypes.object.isRequired
}

export default injectIntl(connect()(RecurringTab))
