import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormLocation from './FormLocation'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import locationsService from '../../../services/locations'
import Popconfirm from '../../../components/Popconfirm'

const LocationManagement = ({ visible, location, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createLocation] = useApi(locationsService.addLocation)
  const [updating, updateLocation] = useApi(locationsService.updateLocation)
  const [deleting, deleteLocation] = useApi(locationsService.deleteLocation)

  const handleSubmit = async () => {
    try {
      const { _id } = location || {}
      const values = await form.current.validateFields()
      onSubmit(
        await (_id ? updateLocation(_id, values) : createLocation(values))
      )
    } catch (error) {}
  }

  const handleDelete = async () => onSubmit(await deleteLocation(location._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && location && !location.isVerified) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${location ? 'edit' : 'add'} location`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormLocation ref={form} location={location} />
    </Drawer>
  )
}

LocationManagement.propTypes = {
  location: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

LocationManagement.defaultProps = {
  visible: false
}

export default LocationManagement
