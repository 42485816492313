import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Avatar, Comment } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import Editor from './Editor'
import useApi from '../../hooks/useApi'
import CommentItem from './CommentItem'

const Wrapper = styled('div')`
  .ant-comment-actions {
    .anticon {
      font-size: 14px;
    }
    .ant-btn {
      border: 0;
      padding: 0;
      height: auto;
      box-shadow: none;
      line-height: normal;
      float: none !important;
      background: transparent;
      color: #f5222d !important;
    }
  }
`

const Comments = ({
  value,
  disabled,
  onCreate,
  onUpdate,
  onRemove,
  onChange,
  defaultValue
}) => {
  const [text, setText] = useState('')
  const [state, setState] = useState(defaultValue || [])

  const [creating, createComment] = useApi(onCreate)
  const handler = typeof onChange === 'function' ? onChange : setState

  const handleCreate = async () => {
    const item = await createComment({ text })
    handler([item, ...comments])
    setText('')
  }

  const handleUpdate = async comment => {
    const item = await onUpdate(comment)
    const items = [...comments]
    items.splice(
      items.findIndex(({ _id }) => _id === item._id),
      1,
      item
    )
    handler(items)
  }

  const handleDelete = async comment => {
    await onRemove(comment)
    handler(comments.filter(({ _id }) => _id !== comment._id))
  }

  const comments = value || state

  return (
    <Wrapper>
      <Comment
        avatar={<Avatar icon={<UserOutlined />} />}
        content={
          <Editor
            value={text}
            loading={creating}
            onChange={setText}
            disabled={disabled}
            onSubmit={handleCreate}
          />
        }
      />
      {comments.map(comment => (
        <CommentItem
          key={comment._id}
          comment={comment}
          disabled={disabled}
          onRemove={handleDelete}
          onUpdate={handleUpdate}
        />
      ))}
    </Wrapper>
  )
}

Comments.propTypes = {
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default Comments
