import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Select, Spin } from 'antd'
import { Form } from '@ant-design/compatible'
import { injectIntl, FormattedMessage, useIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContacts } from '../../../../ducks/contacts'
import ChecklistService from '../../../../services/checklist'

const MainX = props => {
  const { getFieldDecorator } = props.form
  const contacts = useSelector(state => state.contacts.searchedContacts)

  const { data, updateMainX } = props
  const [loading, setLoading] = useState(false)
  const [checklistArray, setChecklistArray] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
    className: 'formItem'
  }

  const fetchFormFieldsOnSearch = async (value = '') => {
    setLoading(true)
    const checklistData = await ChecklistService.listV2({
      'name[]': value,
      limit: 100
    })
    if (checklistData.docs) {
      setChecklistArray(checklistData.docs)
    }
    setLoading(false)
  }

  const fetchContactsOnSearch = async searchQueue => {
    dispatch(
      fetchContacts(
        {
          'company[]': searchQueue,
          limit: 1000,
          sortField: 'company',
          sortOrder: 'ascend'
        },
        true
      )
    )
  }

  const handlePick = e => {
    if (e && e.props && e.props.data) {
      updateMainX('checklist', e.props.data)
    } else {
      updateMainX('checklist', {})
    }
  }

  const handleDataString = data => {
    let contactEmailString = ''
    if (data.contact_type === 'company') {
      contactEmailString += `${data.company} `
    } else {
      contactEmailString += `${(data.firstName || '') +
        ((data.lastName && ' ' + data.lastName) || '')}`
    }

    if (data.email) {
      contactEmailString += data.email ? ` (${data.email})` : ''
    }
    return contactEmailString
  }

  return (
    <>
      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <FormattedMessage id='supportEmail' />
          </span>
        }
        required={false}
      >
        <Select
          showSearch
          style={{ display: 'block', flex: 1 }}
          value={handleDataString(data.contactPerson || {})}
          onSelect={(data, e) =>
            updateMainX('contactPerson', (e && e.props.data) || undefined)
          }
          onFocus={() => fetchContactsOnSearch('')}
          notFoundContent={
            loading ? (
              <div className='workorder-spinner-container'>
                <Spin size='small' />
              </div>
            ) : null
          }
          filterOption={(string, { props: { data } }) =>
            (data.company &&
              data.company.toLowerCase().indexOf(string.toLowerCase()) > -1) ||
            (data.firstName &&
              data.firstName.toLowerCase().indexOf(string.toLowerCase()) >
                -1) ||
            (data.lastName &&
              data.lastName.toLowerCase().indexOf(string.toLowerCase()) > -1)
          }
        >
          {(contacts || []).map(item => (
            <Select.Option key={item._id} data={item} value={item._id}>
              {handleDataString(item || {})}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <FormattedMessage id='checklist' />
          </span>
        }
      >
        {getFieldDecorator('checklist', {
          initialValue: (data.checklist && data.checklist.name) || undefined
        })(
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            notFoundContent={
              loading && (
                <div className='project-spinner-container'>
                  <Spin size='small' />
                </div>
              )
            }
            placeholder={intl.formatMessage({ id: 'select checklist' })}
            optionFilterProp='children'
            onFocus={({ target: { value } }) => fetchFormFieldsOnSearch(value)}
            onSearch={debounce(fetchFormFieldsOnSearch, 300)}
            onChange={(data, event) => handlePick(event)}
          >
            {(checklistArray || []).map(field => {
              return (
                <Select.Option
                  key={field._id}
                  data={{ ...field }}
                  value={field.name}
                >
                  {field.name}
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Form.Item>
    </>
  )
}

MainX.propTypes = {
  formItemLayout: PropTypes.object,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateMainX: PropTypes.func.isRequired
}

export default injectIntl(MainX)
