import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { Modal, notification } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

import useApi from '../../../../../hooks/useApi'
import FormAttachPictures from './FormAttachPictures'
import recordingService from '../../../../../services/recording'
import {
  handleRecordingPick,
  replaceRecording
} from '../../../../../ducks/recordings'

const ModalAttachPicturesToObject = () => {
  const form = useRef()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [loading, attachImages] = useApi(recordingService.attachImages)

  const { visible, recording } = useSelector(
    ({ recordings: { pickedRecording, modalAttachVisible } }) => ({
      recording: pickedRecording,
      visible: modalAttachVisible
    })
  )

  const handleSubmit = async () => {
    try {
      const { images } = await form.current.validateFields()
      dispatch(replaceRecording(await attachImages(recording._id, { images })))
      notification.success({
        message: intl.formatMessage({
          id: 'pictures successfully attached to the object'
        })
      })
    } catch (e) {
      console.error(e)
    }
    dispatch(handleRecordingPick({}))
  }

  const handleCancel = () => dispatch(handleRecordingPick({}))

  const { name } = recording.objectId || {}

  return (
    <Modal
      destroyOnClose
      closable={false}
      visible={visible}
      title={intl.formatMessage(
        {
          id:
            'select which pictures of the recording to attach to object {name}:'
        },
        { name }
      )}
      onCancel={handleCancel}
      okText={intl.formatMessage({ id: 'save' })}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <FormAttachPictures ref={form} recording={recording} />
    </Modal>
  )
}

export default ModalAttachPicturesToObject
