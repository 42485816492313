import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormUser from './FormUser'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import usersService from '../../../services/users'
import Popconfirm from '../../../components/Popconfirm'

const UserManagement = ({ visible, user, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createUser] = useApi(usersService.addUser)
  const [updating, updateUser] = useApi(usersService.updateUser)
  const [deleting, deleteUser] = useApi(usersService.deleteUser)

  const handleSubmit = async () => {
    try {
      const { _id } = user || {}
      const values = await form.current.validateFields()
      Object.assign(values, { retypePassword: undefined })
      onSubmit(await (_id ? updateUser(_id, values) : createUser(values)))
    } catch (error) {}
  }

  const handleDelete = async () => onSubmit(await deleteUser(user._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && user) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${user ? 'edit' : 'add'} user`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormUser ref={form} user={user} />
    </Drawer>
  )
}

UserManagement.propTypes = {
  user: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

UserManagement.defaultProps = {
  visible: false
}

export default UserManagement
