import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { fetchProjectList, setPickedProject } from '../../ducks/project'
import { fetchSettings } from '../../ducks/settings'
import Header from './Header'
import Sidebar from './Sidebar'

import queryString from 'query-string'
import AccountArea from '../../components/AccountArea'

class MainLayout extends React.Component {
  state = {
    projects: []
  }

  async componentDidMount () {
    const { dispatch, location } = this.props
    if (location.state && location.state.login) {
      dispatch(fetchSettings())
    }
    dispatch(fetchProjectList())
  }

  componentDidUpdate (prevProps) {
    const { path, history, project, projects, dispatch } = this.props
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
    }
    if (path !== prevProps.path && project._id) {
      history.push({
        pathname: path,
        search: queryString.stringify(
          { project: project._id },
          { arrayFormat: 'bracket' }
        )
      })
    }
    if (
      !project._id &&
      searchQuery &&
      searchQuery.project &&
      projects.length > 0
    ) {
      const currentProject = projects.find(
        item => item._id === searchQuery.project
      )

      if (currentProject) {
        dispatch(setPickedProject(currentProject))
      }
    }
  }

  render () {
    return (
      <Layout style={{ height: '100vh' }}>
        <Helmet titleTemplate='%s | ddSuite'>
          <title>App</title>
        </Helmet>
        <Layout.Sider
          collapsible
          breakpoint='lg'
          width={300}
          trigger={<AccountArea.Trigger />}
        >
          <Sidebar auth={this.props.auth} settings={this.props.settings} />
        </Layout.Sider>

        <Layout>
          <Layout.Header
            style={{
              background: '#fff'
            }}
          >
            <Header />
          </Layout.Header>

          <Layout.Content>
            <Layout.Content>
              <div
                style={{
                  margin: this.props.path === '/' ? 0 : 15,
                  padding: this.props.path === '/' ? 0 : 15,
                  background: this.props.path === '/' ? 'transparent' : 'white'
                }}
              >
                {this.props.children}
              </div>
            </Layout.Content>
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }
}

MainLayout.propTypes = {
  auth: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  settings: PropTypes.object,
  project: PropTypes.object,
  projects: PropTypes.array,
  path: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.element
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    settings: state.settings,
    project: state.project.pickedProject,
    projects: state.project.projectList
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(MainLayout)))
