import React, { forwardRef } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Zoom from 'react-medium-image-zoom'
import { Form, Input, Select, Tabs } from 'antd'

import config from '../../../config'
import useObjects from '../../../hooks/useObjects'

const { Item } = Form
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormTask = forwardRef(({ request }, ref) => {
  const intl = useIntl()

  const {
    name,
    objectId,
    description,
    images = [],
    checklist = {},
    extra_fields: extraFields = []
  } = request || {}

  const objectName = useSelector(
    ({
      settings: {
        data: { buildx: { objectName = 'object number' } = {} }
      }
    }) => objectName
  )

  const customFields = checklist.fields || []

  const values = {
    ...request,
    name,
    description,
    objectId: objectId && objectId._id,
    checklist: checklist && checklist._id,
    extra_fields: customFields.map(({ _id }) => ({
      id: _id,
      value: (extraFields.find(({ id }) => id === _id) || {}).value
    }))
  }

  const {
    loading: loadingObjects,
    fetch: fetchObjects,
    items: objects
  } = useObjects(objectId)
  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Tabs defaultActiveKey='information'>
        <TabPane
          key='information'
          tab={intl.formatMessage({ id: 'information' })}
        >
          <Item name='checklist' noStyle>
            <Input type='hidden' />
          </Item>
          <Item
            name='name'
            label={intl.formatMessage({ id: 'name' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'this field is required' })
              }
            ]}
          >
            <Input />
          </Item>
          <Item
            name='objectId'
            label={intl.formatMessage({
              id: objectName,
              defaultMessage: objectName
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'this field is required' })
              }
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={false}
              loading={loadingObjects}
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({
                id: `choose a ${objectName}`,
                defaultMessage: `choose a ${objectName}`
              })}
              onSearch={debounce(fetchObjects, 500)}
            >
              {objects.map(({ _id, name }) => (
                <Option key={_id}>{name}</Option>
              ))}
            </Select>
          </Item>
          <Item
            name='description'
            label={intl.formatMessage({ id: 'description' })}
          >
            <TextArea />
          </Item>
          {customFields.map(({ key, label }, index) => (
            <div key={key}>
              <Item name={`extra_fields_${key}`} noStyle>
                <Input type='hidden' />
              </Item>
              <Item
                name={`extra_fields_${key}`}
                label={intl.formatMessage({ id: label, defaultMessage: label })}
              >
                <Input />
              </Item>
            </div>
          ))}
        </TabPane>
        {images.length > 0 && (
          <TabPane key='pictures' tab={intl.formatMessage({ id: 'pictures' })}>
            {images.map(({ _id, comment, imageUrl }) => (
              <div key={_id}>
                <Item label={intl.formatMessage({ id: 'picture' })}>
                  <Zoom zoomMargin={40}>
                    <img
                      src={config.server.url + imageUrl}
                      className='img'
                      alt='comment image'
                      style={{ width: '100%' }}
                    />
                  </Zoom>
                </Item>
                <Item label={intl.formatMessage({ id: 'comment' })}>
                  <TextArea defaultValue={comment} />
                </Item>
              </div>
            ))}
          </TabPane>
        )}
      </Tabs>
    </StyledForm>
  )
})

FormTask.propTypes = {
  request: PropTypes.object.isRequired
}

export default FormTask
