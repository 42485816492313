import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Form, Input } from 'antd'

const { Item } = Form

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormWorkspace = forwardRef(({ workspace }, ref) => {
  const intl = useIntl()

  const { name } = workspace || {}
  const values = { name }

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Item
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'set a name'
          })}
        />
      </Item>
    </StyledForm>
  )
})

FormWorkspace.propTypes = {
  workspace: PropTypes.object
}

export default FormWorkspace
