import React from 'react'
import { Switch, BrowserRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import DefaultLayout from 'layout/Default'
import MainLayout from 'layout/Main'

import PublicRoute from './PublicRoute'
import ProtectedRoute from './ProtectedRoute'

import DOCLinkPage from 'pages/DOCLink'

import LoginPage from '../pages/PublicRoute/Login'
import ForgotPasswordPage from '../pages/PublicRoute/ForgotPassword'
import ResetPasswordPage from '../pages/PublicRoute/ResetPassword'

import NotFoundPage from 'pages/BaseX/NotFound'
import NoPermissions from 'pages/BaseX/NoPermissions'
import HomePage from 'pages/BaseX/Home'

import DefectsPage from 'pages/MainX/Defects'
import WorkordersPage from 'pages/MainX/Workorders'
import ScheduledWorkordersPage from 'pages/MainX/ScheduledWorkorders'
import RequestsPage from 'pages/MainX/Requests'
import SpecialWorkPage from 'pages/MainX/SpecialWork'
import Checklist from 'pages/MainX/Checklist'

import RecordingsPage from '../pages/BuildX/Recordings'
import AcceptancesPage from 'pages/BuildX/Acceptances'

import ProductsPage from 'pages/DataX/Products'
import ManufacturersPage from 'pages/DataX/Manufacturers'
import CategoriesPage from 'pages/DataX/Categories'
import LocationsPage from 'pages/DataX/Locations'
import ContractSectionPage from 'pages/DataX/ContractSection'

import ContactsPage from 'pages/SettingsPages/Contact'
import ProjectsPage from 'pages/SettingsPages/Projects'
import SettingsPage from 'pages/SettingsPages/Settings'
import ProtocolPage from 'pages/SettingsPages/Protocol'
import UsersPage from 'pages/SettingsPages/Users'
import ObjectsPage from 'pages/SettingsPages/Objects'
import ObjectTypePage from '../pages/SettingsPages/ObjectType'
import FieldDefinition from '../pages/SettingsPages/FieldDefinition'
import FormsPage from 'pages/SettingsPages/Forms'
import LoginLayout from 'layout/LoginLayout'
import WorkspacesPage from 'pages/BaseX/Workspace'

const Routes = props => (
  <BrowserRouter>
    <Switch>
      <PublicRoute
        exact
        path='/login'
        layout={LoginLayout}
        component={LoginPage}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/'
        component={HomePage}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/recordings'
        component={RecordingsPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <PublicRoute
        exact
        path='/forgot-password'
        layout={LoginLayout}
        component={ForgotPasswordPage}
      />

      <PublicRoute
        exact
        path='/password'
        layout={LoginLayout}
        component={ResetPasswordPage}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/acceptances'
        component={AcceptancesPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/contract-sections'
        component={ContractSectionPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/products'
        component={ProductsPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/categories'
        component={CategoriesPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/manufacturers'
        component={ManufacturersPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/checklist'
        component={Checklist}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/doclink'
        component={DOCLinkPage}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/forms'
        component={FormsPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/locations'
        component={LocationsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/no-permissions'
        component={NoPermissions}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/workspaces'
        component={WorkspacesPage}
        roles={['admin', 'superadmin', 'user']}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/objects'
        component={ObjectsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/field-definition'
        component={FieldDefinition}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/object-types'
        component={ObjectTypePage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/defects'
        component={DefectsPage}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/workorders'
        component={WorkordersPage}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/scheduled-workorders'
        component={ScheduledWorkordersPage}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/projects'
        component={ProjectsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/requests'
        component={RequestsPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/specialwork'
        component={SpecialWorkPage}
        user={props.auth.authUser}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/protocol'
        component={ProtocolPage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/settings'
        component={SettingsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/users'
        component={UsersPage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path='/contacts'
        component={ContactsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.authUser}
      />

      <PublicRoute layout={DefaultLayout} exact component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
)

Routes.propTypes = {
  auth: PropTypes.object,
  project: PropTypes.string
}

export default Routes
