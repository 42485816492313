import useService from './useService'
import contactService from '../services/contact'

const useContacts = (defaultValue, options = {}) =>
  useService(contactService.listV2, defaultValue, {
    getArgs: value => ({
      'company[]': value,
      limit: 1000,
      sortField: 'company',
      sortOrder: 'ascend'
    }),
    ...options
  })

export default useContacts
