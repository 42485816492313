import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormContractSection from './FormContractSection'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import contractSectionsService from '../../../services/contractSections'
import Popconfirm from '../../../components/Popconfirm'

const ContractSectionManagement = ({
  visible,
  contractSection,
  onSubmit,
  onClose
}) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createContractSection] = useApi(
    contractSectionsService.addContractSection
  )
  const [updating, updateContractSection] = useApi(
    contractSectionsService.updateContractSection
  )
  const [deleting, deleteContractSection] = useApi(
    contractSectionsService.deleteContractSection
  )

  const handleSubmit = async () => {
    try {
      const { _id } = contractSection || {}
      const values = await form.current.validateFields()
      onSubmit(
        await (_id
          ? updateContractSection(_id, values)
          : createContractSection(values))
      )
    } catch (error) {}
  }

  const handleDelete = async () =>
    onSubmit(await deleteContractSection(contractSection._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (
    scope === 'superadmin' &&
    contractSection &&
    !contractSection.isVerified
  ) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${contractSection ? 'edit' : 'add'} contractSection`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormContractSection ref={form} contractSection={contractSection} />
    </Drawer>
  )
}

ContractSectionManagement.propTypes = {
  contractSection: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ContractSectionManagement.defaultProps = {
  visible: false
}

export default ContractSectionManagement
