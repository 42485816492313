import config from 'config'
import axios from 'helpers/axios'

export default {
  async getList (params = {}) {
    const response = await axios.get('/protocol', params)
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/protocol', params)
  },
  async addProtocol (data) {
    const response = await axios.post('/protocol', data)
    return response
  },
  async deleteProtocol (id) {
    const response = await axios.delete(`/protocol/${id}`)
    return response
  },
  async updateProtocol (id, data) {
    const response = await axios.put(`/protocol/${id}`, data)
    return response
  }
}
