import React from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Input, Select, Tag, Tooltip } from 'antd'
import {
  CheckOutlined,
  PlusOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'

import useObjects from '../../../../hooks/useObjects'
import Button from '../../../../components/Global/Button'
import PriceInput from '../../../../components/PriceInput'
import useContractSections from '../../../../hooks/useContractSections'
import ContactTreeSelect from '../../../../components/ContactTreeSelect'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const groups = [
  'Kabel',
  'Leittechnik',
  'Materialschaden / falsches Material',
  'falsche Montage',
  'Beschriftung',
  'Brandschottung',
  'Doppelboden',
  'Reinigung',
  'Dokumentation',
  'Schema',
  'Schäden',
  'No-Break',
  'Andere',
  'Zu klären / fertigstellen',
  'Altlasten',
  'Baufehler'
]

const statusOptions = ['open', 'in progress', 'completed']

const SelectWrapper = styled('div')`
  display: flex;
  & > .ant-btn {
    margin-left: 10px;
  }
  & > .ant-select {
    flex: 1;
  }
`

const SelectWithButton = ({ children, onClick, ...props }) => (
  <SelectWrapper>
    <Select {...props}>{children}</Select>
    <Button type='primary' onClick={onClick}>
      <PlusOutlined />
    </Button>
  </SelectWrapper>
)

SelectWithButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired
}

const Details = ({ defect, onRefClick, onObjectClick }) => {
  const intl = useIntl()

  const { pickedProject, projectList = [] } =
    useSelector(({ project }) => project) || {}

  const objectName = useSelector(
    ({
      settings: {
        data: { buildx: { objectName = 'object number' } = {} }
      }
    }) => objectName
  )

  const { files, objectId, recording, contractSection } = defect || {}

  const {
    loading: loadingObjects,
    fetch: fetchObjects,
    items: objects
  } = useObjects(objectId)

  const {
    loading: loadingContractSections,
    fetch: fetchContractSections,
    items: contractSections
  } = useContractSections(contractSection)

  return (
    <>
      <Item name='_id' noStyle>
        <Input type='hidden' />
      </Item>
      <Item
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'field is required'
            })
          }
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'name' })} />
      </Item>
      <Item
        name='objectId'
        label={objectName}
        rules={[
          {
            required: !defect,
            message: intl.formatMessage({
              id: 'field is required'
            })
          }
        ]}
      >
        <SelectWithButton
          showSearch
          allowClear
          filterOption={false}
          loading={loadingObjects}
          placeholder={intl.formatMessage(
            {
              id: 'choose a {name}'
            },
            { name: objectName }
          )}
          onClick={onObjectClick}
          onSearch={debounce(fetchObjects, 500)}
        >
          {objects.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </SelectWithButton>
      </Item>
      <Item name='project' label={intl.formatMessage({ id: 'project' })}>
        <Select
          showSearch
          allowClear
          optionFilterProp='children'
          placeholder={intl.formatMessage({ id: 'choose a project' })}
          disabled={pickedProject._id && !defect}
          filterOption={(input, { children }) =>
            children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {projectList.map(({ _id, projectName }) => (
            <Option key={_id} value={_id}>
              {projectName}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='contractSection'
        label={intl.formatMessage({ id: 'contract section' })}
      >
        <Select
          showSearch
          allowClear
          filterOption={false}
          loading={loadingContractSections}
          placeholder={intl.formatMessage({
            id: 'choose a contract section'
          })}
          onSearch={debounce(fetchContractSections, 500)}
        >
          {contractSections.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item name='assignedTo' label={intl.formatMessage({ id: 'assignedTo' })}>
        <ContactTreeSelect
          placeholder={intl.formatMessage({ id: 'select contact' })}
        />
      </Item>
      <Item name='group' label={intl.formatMessage({ id: 'group' })}>
        <Select
          allowClear
          placeholder={intl.formatMessage({ id: 'choose a group' })}
        >
          {groups.map(group => (
            <Option key={group} value={group}>
              {group}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='actualSituation'
        label={intl.formatMessage({ id: 'actual status' })}
      >
        <TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          placeholder={intl.formatMessage({ id: 'actualSituation' })}
        />
      </Item>
      <Item
        name='targetSituation'
        label={intl.formatMessage({ id: 'target status' })}
      >
        <TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          placeholder={intl.formatMessage({ id: 'targetSituation' })}
        />
      </Item>
      <Item name='status' label={intl.formatMessage({ id: 'status' })}>
        <Select placeholder={intl.formatMessage({ id: 'choose a status' })}>
          {statusOptions.map(status => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='estimated_cost'
        label={intl.formatMessage({ id: 'estimated_cost' })}
      >
        <PriceInput customInput={PriceInput.CustomInput({ prefix: 'CHF' })} />
      </Item>
      {defect && recording && (
        <Item label={intl.formatMessage({ id: 'reference' })}>
          {recording.docNumber && (
            <Tag
              style={{ cursor: 'pointer' }}
              onClick={() => onRefClick(recording)}
              color='blue'
            >
              <CheckOutlined />
              &nbsp;
              {recording.docNumber}
            </Tag>
          )}
        </Item>
      )}
      <Item
        name='attachedDocument'
        label={
          <span>
            {intl.formatMessage({ id: 'attached document' })}
            &nbsp;&nbsp;
            <Tooltip
              placement='top'
              title={<FormattedMessage id='will be attached' />}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <Select
          allowClear
          placeholder={intl.formatMessage({ id: 'choose document' })}
        >
          {(files || [])
            .filter(({ type }) => type.indexOf('pdf') > -1)
            .map(({ _id, filename }) => (
              <Option key={_id} value={_id}>
                {filename}
              </Option>
            ))}
        </Select>
      </Item>
    </>
  )
}

Details.propTypes = {
  defect: PropTypes.object,
  onRefClick: PropTypes.func.isRequired,
  onObjectClick: PropTypes.func.isRequired
}

Details.defaultProps = {
  onRefClick: () => {},
  onObjectClick: () => {}
}

export default Details
