import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Table, Button } from 'antd'
import { Helmet } from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'
import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import useApi from '../../../hooks/useApi'
import ContractSectionManagement from './ContractSectionManagement'
import searchColumn from '../../../utils/searchColumn'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'
import contractSectionsService from '../../../services/contractSections'

const ContractSectionsPage = () => {
  const intl = useIntl()
  const input = useRef()
  const [contractSection, setContractSection] = useState()
  const [data, setData] = useState({})
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, fetchContractSections] = useApi(
    contractSectionsService.listV2
  )
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchContractSections({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = contractSection => {
    setContractSection(contractSection)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setContractSection()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const { filterOptions: { createdBy: createdByFilters = [] } = {} } = data

  const columns = [
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input, intl, value: filters.name })
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: user._id
        }
      }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    }
  ]

  if (!selectedProject._id) {
    columns.push({
      title: intl.formatMessage({ id: 'project' }),
      key: 'project',
      width: 220,
      dataIndex: ['project', 'projectName']
    })
  }

  columns.push({
    width: 65,
    align: 'right',
    key: 'actions',
    render: record => (
      <Button onClick={() => handleOpen(record)}>
        <EditOutlined />
      </Button>
    )
  })

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.contractSections'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'contract sections' })}
        buttons={[
          <Button
            type='primary'
            key='create_contractSection'
            onClick={() => handleOpen()}
          >
            <FormattedMessage id='create contract section' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      <ContractSectionManagement
        visible={drawerVisible}
        contractSection={contractSection}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  )
}

export default ContractSectionsPage
