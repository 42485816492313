import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Modal, notification, Spin } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import defectsService from '../../../services/defects'

const SAPWrapper = styled('div')`
  display: flex;
  min-height: 350px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h4 {
    line-height: 1.3;
    text-align: center;
  }

  p {
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 0;
    font-style: italic;
    text-align: center;
  }

  .success .anticon {
    color: #52c41a;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .error .anticon {
    color: red;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .ant-spin {
    margin-right: 10px;
  }
`

const SAPModal = ({ visible, item, onClose, onSubmit }) => {
  const intl = useIntl()
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')
  const [sending, submit] = useApi(defectsService.submit)

  useLayoutEffect(() => {
    if (visible) {
      setStatus('')
      setMessage('')
    }
  }, [visible])

  const handleSubmit = async () => {
    try {
      setStatus('')
      setMessage('')
      const { response } = (await submit(item._id)) || {}
      if (typeof response === 'object' && Array.isArray(response.submissions)) {
        const [{ reference } = {}] = response.submissions
        setStatus('success')
        setMessage(reference || '')
        onSubmit()
      } else if (
        typeof response === 'string' &&
        response.toLowerCase().includes('error')
      ) {
        setStatus('error')
      }
    } catch (e) {
      const message = intl.formatMessage({
        id:
          e.error && e.error.toLowerCase().includes('attachment')
            ? 'attachment error'
            : 'error'
      })
      notification.error({ message })
      setStatus('error')
    }
  }

  const footer = (
    <>
      {status !== 'success' && (
        <Button key='back' onClick={onClose}>
          <FormattedMessage id='cancel' />
        </Button>
      )}
      <Button
        key='submit'
        type='primary'
        loading={sending}
        onClick={status !== 'success' ? handleSubmit : onClose}
      >
        <FormattedMessage
          id={
            sending
              ? 'processing'
              : status === 'error'
              ? 'restart'
              : status === 'success'
              ? 'close'
              : 'submit'
          }
        />
      </Button>
    </>
  )

  return (
    <Modal
      width={640}
      destroyOnClose
      footer={footer}
      visible={visible}
      onCancel={onClose}
    >
      <SAPWrapper>
        {sending || status ? (
          <h4>
            {sending && (
              <>
                <Spin size='large' />
                <FormattedMessage id='uploading to SAP' />
                <br />
                <b>{item.name}</b>
              </>
            )}
            {!sending && status === 'success' && (
              <div className={status}>
                <CheckCircleOutlined />
                <br />
                <b>{item.name}</b>
                <br />
                <FormattedMessage id='Uploaded successfully to SAP' />
                <br />
                {message}
              </div>
            )}
            {!sending && status === 'error' && (
              <div className={status}>
                <CloseCircleOutlined />
                <br />
                <FormattedMessage id='error uploading' />
                <b> {item.name}</b>
                <br />
                {message}
                <br />
                <FormattedMessage
                  tagName='i'
                  id='Please try again later or contact DDC Schweiz AG.'
                />
              </div>
            )}
          </h4>
        ) : (
          <>
            <h4>
              <FormattedMessage id='You are about to start sending' />
              <b> {item.name}</b>
            </h4>
            <FormattedMessage tagName='p' id='interface-process-description' />
          </>
        )}
      </SAPWrapper>
    </Modal>
  )
}

SAPModal.propTypes = {
  visible: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SAPModal
