import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Dropdown, Menu } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import FormRequest from './FormRequest'
import useApi from '../../../hooks/useApi'
import TaskManagement from './TaskManagement'
import Drawer from '../../../components/Drawer'
import requestService from '../../../services/requests'
import WorkOrderManagement from './WorkOrderManagement'

const CancelButton = styled(Button)`
  float: left;
`

const RejectButton = styled(Button)`
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: #fff;
  &:hover {
    background-color: rgb(255, 59, 62);
    border-color: rgb(255, 59, 62);
  }
`

const ApproveButton = styled(Button)`
  background-color: rgb(98, 179, 29);
  border-color: rgb(84, 156, 22);
  &:hover {
    background-color: rgb(84, 156, 22);
    border-color: rgb(84, 156, 22);
  }
`

const { Item } = Menu

const RequestManagement = ({ visible, request, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const [record, setRecord] = useState()
  const [drawerVisible, setDrawerVisible] = useState('')

  const [updating, updateRequest] = useApi(requestService.updateRequest)

  const handleOpen = async drawer => {
    try {
      const record = await form.current.validateFields()
      setRecord(
        Object.assign({}, request, record, {
          objectId: objectIdRef.current,
          checklist: checklistRef.current
        })
      )
      setDrawerVisible(drawer)
    } catch (e) {}
  }

  const handleSubmit = async status => {
    try {
      const { _id } = request || {}
      const record = await form.current.validateFields()
      Object.assign(record, { status })
      Object.keys(record).forEach(item => {
        if (item.indexOf('extra_fields_') > -1) {
          const id = item.replace('extra_fields_', '')
          record.extra_fields = [
            ...(record.extra_fields || []),
            {
              id,
              value: record[item]
            }
          ]
          delete record[item]
        }
      })
      if (request.images) {
        record.images = request.images
      }
      onSubmit(await updateRequest(_id, record))
    } catch (e) {}
  }

  const handleClose = () => {
    setRecord()
    setDrawerVisible('')
  }

  const loading = updating
  const { name, status, objectId } = request

  const checklistRef = useRef()
  const objectIdRef = useRef(objectId)

  const drawerButtons = [
    <CancelButton key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </CancelButton>,
    <RejectButton
      key='reject'
      type='danger'
      loading={loading}
      onClick={() => handleSubmit(3)}
      disabled={loading || status > 1}
    >
      <FormattedMessage id='reject' />
    </RejectButton>,
    <Dropdown
      key='approve'
      trigger={['click']}
      placement='bottomRight'
      disabled={loading || status > 1}
      overlay={
        <Menu>
          <Item onClick={() => handleOpen('task')}>
            <FormattedMessage id='create unassigned task' />
          </Item>
          <Item onClick={() => handleOpen('workOrder')}>
            <FormattedMessage id='create workorder' />
          </Item>
        </Menu>
      }
    >
      <ApproveButton type='primary' loading={loading}>
        <FormattedMessage id='approve' />
      </ApproveButton>
    </Dropdown>
  ]

  return (
    <Drawer
      title={`${intl.formatMessage({ id: 'request' })} "${name}"`}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormRequest
        disabled
        ref={form}
        request={request}
        onObject={objectId => (objectIdRef.current = objectId)}
        onChecklist={checklist => (checklistRef.current = checklist)}
      />

      {record && (
        <>
          <TaskManagement
            visible={drawerVisible === 'task'}
            onSubmit={() => handleSubmit(2)}
            onClose={handleClose}
            request={record}
          />
          <WorkOrderManagement
            visible={drawerVisible === 'workOrder'}
            onSubmit={() => handleSubmit(2)}
            onClose={handleClose}
            request={record}
          />
        </>
      )}
    </Drawer>
  )
}

RequestManagement.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired
}

RequestManagement.defaultProps = {
  visible: false
}

export default RequestManagement
