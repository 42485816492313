import React, { forwardRef } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Select } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import useForms from '../../../hooks/useForms'
import useUsers from '../../../hooks/useUsers'

const { Item } = Form
const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormProject = forwardRef(({ project }, ref) => {
  const intl = useIntl()

  const {
    form,
    status,
    archived,
    projectName,
    projectNumber,
    projectManager,
    custom_forms: customForm
  } = project || {}

  const { acceptance } = customForm || {}

  const values = {
    status,
    archived,
    projectName,
    projectNumber,
    form: form && form._id,
    projectManager: projectManager && projectManager._id,
    custom_forms: {
      acceptance: acceptance && acceptance._id
    }
  }

  const { loading: loadingForms, fetch: fetchForms, items: forms } = useForms(
    form
  )

  const { loading: loadingUsers, fetch: fetchUsers, items: users } = useUsers(
    projectManager
  )

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Item
        name='projectName'
        label={intl.formatMessage({ id: 'name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'name'
          })}
        />
      </Item>

      <Item
        name='projectNumber'
        label={intl.formatMessage({ id: 'project number' })}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'project number'
          })}
        />
      </Item>

      <Item name='projectManager' label={intl.formatMessage({ id: 'manager' })}>
        <Select
          showSearch
          allowClear
          filterOption={false}
          style={{ width: '100%' }}
          loading={loadingUsers}
          placeholder={intl.formatMessage({
            id: 'manager'
          })}
          onSearch={debounce(fetchUsers, 500)}
        >
          {users.map(({ _id, email, fullName }) => (
            <Option key={_id}>{`${fullName} (${email})`}</Option>
          ))}
        </Select>
      </Item>

      <Item
        name={['custom_forms', 'acceptance']}
        label={intl.formatMessage({ id: 'acceptance form' })}
      >
        <Select
          showSearch
          allowClear
          filterOption={false}
          style={{ width: '100%' }}
          loading={loadingForms}
          placeholder={intl.formatMessage({
            id: 'acceptance form'
          })}
          onSearch={debounce(fetchForms, 500)}
        >
          {forms.map(({ _id, name }) => (
            <Option key={_id}>{name}</Option>
          ))}
        </Select>
      </Item>

      <Item name='form' label={intl.formatMessage({ id: 'recording form' })}>
        <Select
          showSearch
          allowClear
          filterOption={false}
          style={{ width: '100%' }}
          loading={loadingForms}
          placeholder={intl.formatMessage({
            id: 'recording form'
          })}
          onSearch={debounce(fetchForms, 500)}
        >
          {forms.map(({ _id, name }) => (
            <Option key={_id}>{name}</Option>
          ))}
        </Select>
      </Item>
      <Item name='status' label={intl.formatMessage({ id: 'status' })}>
        <Select
          placeholder={intl.formatMessage({
            id: 'status'
          })}
        >
          <Option value='opened'>
            <FormattedMessage id='opened' />
          </Option>
          <Option value='inprogress'>
            <FormattedMessage id='in progress' />
          </Option>
        </Select>
      </Item>
    </StyledForm>
  )
})

FormProject.propTypes = {
  project: PropTypes.object
}

export default FormProject
