import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Input, Select, Spin, InputNumber, Button } from 'antd'
import { Form } from '@ant-design/compatible'
import isEqual from 'lodash/isEqual'
import { PlusOutlined } from '@ant-design/icons'

import { fetchObjectsList } from '../../../ducks/object'
import PropTypes from 'prop-types'

import './styles.scss'
import usePrevious from '../../../hooks/usePrevious'
import useObjects from '../../../hooks/useObjects'

const { Item } = Form
const { Option } = Select
const formItemLayout = {
  className: 'form-item-cover'
}

const groupData = [
  'Kabel',
  'Leittechnik',
  'Materialschaden / falsches Material',
  'falsche Montage',
  'Beschriftung',
  'Brandschottung',
  'Doppelboden',
  'Reinigung',
  'Dokumentation',
  'Schema',
  'Schäden',
  'No-Break',
  'Andere',
  'Zu klären / fertigstellen',
  'Altlasten',
  'Baufehler'
]

const SelectWrapper = styled('div')`
  display: flex;
  & > .ant-btn {
    margin-left: 10px;
  }
  & > .ant-select {
    flex: 1;
  }
`

const SelectWithButton = ({ children, onClick, ...props }) => (
  <SelectWrapper>
    <Select {...props}>{children}</Select>
    <Button type='primary' onClick={onClick}>
      <PlusOutlined />
    </Button>
  </SelectWrapper>
)

SelectWithButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired
}

const SpecificFields = ({ data, form, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [formLoading, setFormLoading] = useState(false)
  const prevData = usePrevious(data)
  useEffect(() => {
    if (!isEqual(data, prevData) && Object.keys(data).length) {
      form.resetFields()
    }
  }, [data, prevData])

  useEffect(() => {
    if (
      data.objectId &&
      data.objectId._id &&
      form.getFieldValue('objectId') !== data.objectId._id
    ) {
      form.setFieldsValue({
        objectId: data.objectId._id
      })
    }
  }, [data])

  const {
    loading: loadingObjects,
    // eslint-disable-next-line no-unused-vars
    fetch: fetchObjects,
    items: objects
  } = useObjects(data.objectId)

  const fetchData = async (value, key) => {
    setFormLoading(true)
    const { dispatch } = props
    const timer = setTimeout(async () => {
      try {
        await dispatch(fetchObjectsList({ 'name[]': value, limit: 20 }, true))
        setFormLoading(false)

        clearTimeout(timer)
      } catch (error) {
        // this.setState({
        //   [key]: [],
        //   formLoading: false
        // })
        setFormLoading(false)
        clearTimeout(timer)
      }
    })
  }

  const {
    updateStateDefect,
    handleObjectOpen,
    intl,
    errors,
    objectName
  } = props

  return (
    <Form className='form-cover'>
      <p style={{ width: '100%', marginBottom: 0, marginTop: 16 }}>
        <b>{intl.formatMessage({ id: 'specific data' })}</b>
      </p>

      <Item
        {...formItemLayout}
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        validateStatus={errors.name ? 'error' : null}
        help={errors.name ? intl.formatMessage({ id: 'is required' }) : null}
      >
        <Input
          onChange={event => updateStateDefect('name', event.target.value)}
        />
      </Item>

      <Item
        {...formItemLayout}
        name='objectId'
        label={objectName}
        validateStatus={errors.objectId ? 'error' : null}
        help={
          errors.objectId ? intl.formatMessage({ id: 'is required' }) : null
        }
      >
        <SelectWithButton
          showSearch
          style={{ width: '100%' }}
          optionFilterProp='children'
          notFoundContent={
            formLoading || loadingObjects ? (
              <div className='objects-spinner-container'>
                <Spin size='small' />
              </div>
            ) : null
          }
          onClick={handleObjectOpen}
          onFocus={() => fetchData('', 'objects')}
          onSearch={value => fetchData(value, 'objects')}
          onChange={objectId => updateStateDefect('objectId', objectId)}
          defaultValue={
            data.objectId ? data.objectId.projectName || data.objectId.name : ''
          }
          value={
            data.objectId ? data.objectId.projectName || data.objectId.name : ''
          }
        >
          {objects.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </SelectWithButton>
      </Item>

      <Item
        {...formItemLayout}
        name='estimated_cost'
        label={intl.formatMessage({
          id: 'estimated_cost'
        })}
      >
        <InputNumber
          defaultValue={data.estimated_cost || ''}
          onChange={value => updateStateDefect('estimated_cost', value)}
          formatter={
            value => `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '') // eslint-disable-line no-useless-escape
          }
          style={{ width: '100%' }}
          parser={value => value.replace(' ', '').match(/[0-9 , \.]+/g)} // eslint-disable-line no-useless-escape
        />
      </Item>

      <Item
        {...formItemLayout}
        name='group'
        label={intl.formatMessage({
          id: 'group'
        })}
      >
        <Select onChange={group => updateStateDefect('group', group)}>
          {groupData.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Item>

      <Item
        {...formItemLayout}
        name='actualSituation'
        label={intl.formatMessage({
          id: 'actualSituation'
        })}
      >
        <Input.TextArea
          defaultValue={
            (data.attributes && data.attributes.actualStatus) ||
            data.actualSituation ||
            ''
          }
          onChange={event =>
            updateStateDefect('actualSituation', event.target.value)
          }
        />
      </Item>
    </Form>
  )
}

SpecificFields.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  form: PropTypes.object,
  intl: PropTypes.object.isRequired,
  contractSections: PropTypes.array.isRequired,
  objects: PropTypes.array.isRequired,
  defectData: PropTypes.object,
  projects: PropTypes.array,
  objectName: PropTypes.string,
  errors: PropTypes.object,
  updateStateDefect: PropTypes.func.isRequired,
  handleObjectOpen: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    defectData: state.recordings.recordingDefectCreationData,
    projects: state.project.projectList,
    objectName: state.settings.data.buildx.objectName,
    contractSections: state.contractSections.searchedContractSections
  }
}

export default Form.create()(
  injectIntl(connect(mapStateToProps)(SpecificFields))
)
