import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import ProductsTable from './components/ProductsTable'
import { Button } from 'antd'
import AddProduct from './components/AddProduct'
import { fetchProducts } from '../../../../ducks/products'
import { fetchCategoryList } from '../../../../ducks/category'
import { updatePickedRecording } from '../../../../ducks/recordings'
import { connect } from 'react-redux'

class ProductsTab extends React.Component {
  state = {
    renderAddProduct: false
  }

  componentDidMount () {
    const { dispatch } = this.props
    dispatch(fetchProducts())
    dispatch(fetchCategoryList())
  }

  handleAddProductSwitcher = () => {
    this.setState({
      renderAddProduct: !this.state.renderAddProduct
    })
  }

  addProductToSection = product => {
    const { dispatch, recording } = this.props
    const oldData = recording.products || []
    const productPayload = {
      product: {
        _id: product._id,
        category: product.category,
        name: product.name,
        ordernumber: product.ordernumber
      },
      quantity: 1
    }

    if (
      recording.products &&
      recording.products.filter(
        value => value.product._id === productPayload.product._id
      ).length > 0
    ) {
      this.handleAddProductSwitcher()
      return false
    }

    dispatch(updatePickedRecording({ products: [...oldData, productPayload] }))
    this.handleAddProductSwitcher()
  }

  render () {
    const { readOnly, intl } = this.props
    const { renderAddProduct } = this.state
    return (
      <>
        {renderAddProduct ? (
          <AddProduct
            addProductSwitcher={() =>
              this.setState({
                renderAddProduct: !this.state.renderAddProduct
              })
            }
            addProductToSection={this.addProductToSection}
            readOnly={readOnly}
          />
        ) : (
          <div className='recording-form-tables-wrap'>
            <Button
              onClick={this.handleAddProductSwitcher}
              type='primary'
              disabled={readOnly}
            >
              {intl.formatMessage({ id: 'add product' })}
            </Button>
            <ProductsTable />
          </div>
        )}
      </>
    )
  }
}

ProductsTab.propTypes = {
  recording: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    recording: state.recordings.pickedRecording,
    products: state.products.productsList,
    readOnly: state.recordings.pickedReadOnly,
    user: state.auth.authUser
  }
}

export default injectIntl(connect(mapStateToProps)(ProductsTab))
