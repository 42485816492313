import React, { forwardRef } from 'react'
import { Form, Tabs } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import Files from './Tabs/Files'
import Images from './Tabs/Images'
import Details from './Tabs/Details'
import Updates from './Tabs/Updates'
import ProtocolInfo from './Tabs/ProtocolInfo'

const { TabPane } = Tabs

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  label {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 1rem;
  }
`

const FormDefect = forwardRef(({ defect, onRefClick, onObjectClick }, ref) => {
  const intl = useIntl()

  const { pickedProject } = useSelector(({ project }) => project) || {}

  const {
    _id,
    name,
    group,
    project,
    objectId,
    assignedTo,
    protocolRefExt,
    contractSection,
    actualSituation,
    targetSituation,
    attachedDocument,
    protocolReference,
    protocolResponsible,
    protocolExtResponsible,
    estimated_cost: estimatedCost,
    status,
    images
  } = defect || {}

  const values = {
    _id,
    name,
    group,
    project: defect ? project && project._id : pickedProject._id,
    objectId: objectId && objectId._id,
    assignedTo,
    protocolRefExt,
    contractSection: contractSection && contractSection._id,
    actualSituation,
    targetSituation,
    protocolReference,
    protocolResponsible,
    protocolExtResponsible,
    attachedDocument: attachedDocument && attachedDocument._id,
    estimated_cost: estimatedCost,
    status,
    images: (images || []).reduce((images, { _id, description, comment }) => {
      images[_id] = { comment: description || comment }
      return images
    }, {})
  }

  return (
    <StyledForm ref={ref} {...formItemLayout} initialValues={values}>
      <Tabs defaultActiveKey='information'>
        <TabPane
          key='information'
          tab={intl.formatMessage({ id: 'informations' })}
        >
          <Details defect={defect} />
        </TabPane>
        <TabPane
          key='protocolInfo'
          tab={intl.formatMessage({ id: 'protocol info' })}
        >
          <ProtocolInfo />
        </TabPane>
        {defect && (
          <TabPane key='files' tab={intl.formatMessage({ id: 'files' })}>
            <Files defect={defect} />
          </TabPane>
        )}
        {defect && (
          <TabPane key='images' tab={intl.formatMessage({ id: 'images' })}>
            <Images defect={defect} />
          </TabPane>
        )}
        {defect && (
          <TabPane key='updates' tab={intl.formatMessage({ id: 'updates' })}>
            <Updates defect={defect} />
          </TabPane>
        )}
      </Tabs>
    </StyledForm>
  )
})

FormDefect.propTypes = {
  defect: PropTypes.object,
  onRefClick: PropTypes.func.isRequired,
  onObjectClick: PropTypes.func.isRequired
}

FormDefect.defaultProps = {
  onRefClick: () => {},
  onObjectClick: () => {}
}

export default FormDefect
