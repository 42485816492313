import React from 'react'
import moment from 'moment'
import config from 'config'
import PropTypes from 'prop-types'
import { DeleteOutlined } from '@ant-design/icons'

import PopconfirmDelete from '../Popconfirm'

const previewImage = ({ type, fileUrl, imageUrl }) => {
  const { server: { url } = {} } = config || {}
  switch (`${type}`.toLowerCase()) {
    case 'image/png':
    case 'image/jpg':
      return `${url}${imageUrl || fileUrl}`
    case 'text/csv':
      return 'images/file.svg'
    case 'application/pdf':
      return 'images/pdf.svg'
    default:
      return 'images/doc.svg'
  }
}

const FileItem = ({ file, disabled, onRemove, onDownload }) => {
  const { filename, createdAt, createdBy } = file
  const { fullName } = createdBy || {}

  return (
    <div onClick={() => onDownload(file)} className='file-upload-list-item'>
      <div className='file-image-cover'>
        <img src={previewImage(file)} alt='image' />
      </div>
      <div className='file-description-item'>
        <p>
          <b>{filename}</b>
        </p>
        <p>{moment(createdAt).format('DD.MM.YYYY, HH:mm')}</p>
        <p>{fullName}</p>
      </div>
      <div
        onClick={event => event.stopPropagation()}
        className='confirm-delete-cover'
      >
        <PopconfirmDelete
          disabled={disabled}
          onConfirmAction={() => onRemove(file)}
          value={<DeleteOutlined style={{ color: '#196b9e', fontSize: 16 }} />}
        />
      </div>
    </div>
  )
}

FileItem.propTypes = {
  disabled: PropTypes.bool,
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired
}

export default FileItem
