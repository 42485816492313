import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Button, Tag } from 'antd'

import { Helmet } from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'
import { EditOutlined } from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'
import ObjectTypeManagement from './ObjectTypeManagement'
import objectTypesService from '../../../services/objectTypes'

const ObjectTypesPage = () => {
  const intl = useIntl()
  const [data, setData] = useState({})
  const [objectType, setObjectType] = useState()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, fetchObjectTypes] = useApi(objectTypesService.listV2)
  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchObjectTypes({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = objectType => {
    setObjectType(objectType)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setObjectType()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const { filterOptions: { createdBy: createdByFilters = [] } = {} } = data

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: intl.formatMessage({ id: 'fields' }),
      key: 'fields',
      dataIndex: 'fields',
      sorter: true,
      width: 600,
      render: fields => {
        return fields.map(value => <Tag key={value.key}>{value.label}</Tag>)
      }
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: user._id
        }
      }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    },
    {
      width: 65,
      align: 'right',
      key: 'actions',
      render: record => (
        <Button onClick={() => handleOpen(record)}>
          <EditOutlined />
        </Button>
      )
    }
  ]

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.objectTypes'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'object types' })}
        buttons={[
          <Button
            type='primary'
            key='create_objectType'
            onClick={() => handleOpen()}
          >
            <FormattedMessage id='create object type' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      <ObjectTypeManagement
        visible={drawerVisible}
        objectType={objectType}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  )
}

export default ObjectTypesPage
