import React, { forwardRef } from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const { Item } = Form

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormManufacturer = forwardRef(({ manufacturer }, ref) => {
  const intl = useIntl()

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={manufacturer || {}}>
      <Item
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'set a name'
          })}
        />
      </Item>

      <Item name='street' label={intl.formatMessage({ id: 'street' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a street'
          })}
        />
      </Item>
      <Item name='postcode' label={intl.formatMessage({ id: 'postcode' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a postcode'
          })}
        />
      </Item>
      <Item name='city' label={intl.formatMessage({ id: 'city' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a city'
          })}
        />
      </Item>
      <Item name='country' label={intl.formatMessage({ id: 'country' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a country'
          })}
        />
      </Item>
      <Item name='website' label={intl.formatMessage({ id: 'website' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set a website'
          })}
        />
      </Item>
      <Item
        name='email'
        label={intl.formatMessage({ id: 'email' })}
        rules={[
          {
            type: 'email',
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'set email'
          })}
        />
      </Item>
      <Item name='phone' label={intl.formatMessage({ id: 'phone' })}>
        <Input
          placeholder={intl.formatMessage({
            id: 'set phone'
          })}
        />
      </Item>
    </StyledForm>
  )
})

FormManufacturer.propTypes = {
  manufacturer: PropTypes.object
}

export default FormManufacturer
