import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useIntl } from 'react-intl'
import moment from 'moment'

const GenerationTable = ({ generations }) => {
  const intl = useIntl()
  const columns = [
    {
      title: intl.formatMessage({ id: 'date' }),
      key: 'date',
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
    },
    {
      title: intl.formatMessage({ id: 'generation' }),
      dataIndex: 'docNumber',
      key: 'docNumber',
      render: docNumber =>
        intl.formatMessage({ id: 'workorder generated' }) + ` ${docNumber}`
    }
  ]

  return <Table dataSource={generations} columns={columns} />
}

GenerationTable.propTypes = {
  generations: PropTypes.array.isRequired
}

export default GenerationTable
