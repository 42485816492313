import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Input, Select } from 'antd'
import { Form } from '@ant-design/compatible'

import useApi from '../../../hooks/useApi'
import fieldsService from '../../../services/fields'

const { Item } = Form
const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormChecklist = ({ form, checklist }) => {
  const intl = useIntl()
  const [fieldsOptions, setFieldsOptions] = useState([])
  const { getFieldDecorator } = form
  const [loadingFields, fetchFields] = useApi(fieldsService.listV2)
  const { _id, name, fields = [] } = checklist || {}
  _id && getFieldDecorator('_id', { initialValue: _id })

  const loadFields = async (value = '') => {
    const { docs = [] } = await fetchFields({ 'label[]': value, limit: 100 })
    fields.forEach(addedField => {
      if (!docs.some(({ _id }) => _id === addedField._id)) {
        docs.push(addedField)
      }
    })
    setFieldsOptions(docs)
  }

  useEffect(() => {
    loadFields()
  }, [])

  const fieldsValue =
    (fields.length && fields.map(item => item._id)) || undefined
  return (
    <StyledForm {...formLayout}>
      <Item label={intl.formatMessage({ id: 'name' })}>
        {getFieldDecorator('name', {
          initialValue: name,
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'this field is required' })
            }
          ]
        })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a name'
            })}
          />
        )}
      </Item>

      <Item label={intl.formatMessage({ id: 'form fields' })}>
        {getFieldDecorator('fields', {
          initialValue: fieldsValue,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'is required'
              })
            }
          ]
        })(
          <Select
            mode='multiple'
            showSearch
            allowClear
            loading={loadingFields}
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: 'choose fields' })}
            onSearch={debounce(loadFields, 300)}
          >
            {(fieldsOptions || []).map(field => {
              return <Option key={field._id}>{field.label}</Option>
            })}
          </Select>
        )}
      </Item>
    </StyledForm>
  )
}

FormChecklist.propTypes = {
  checklist: PropTypes.object,
  form: PropTypes.object.isRequired
}

export default Form.create({ name: 'form_checklist' })(FormChecklist)
