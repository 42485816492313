import { createReducer, createAction } from 'redux-act'

import { ObjectsAPI } from '../services'
import { notification } from 'antd'
import axios from 'axios'
import config from '../config'
import { handleDownloadResponse } from './recordings'

export const REDUCER = 'object'
const NS = `@@${REDUCER}/`

export const setObjectsData = createAction(`${NS}SET_OBJECTS_LIST`)
export const setPickedObject = createAction(`${NS}SET_PICKED_OBJECT`)
export const setSearchedObjects = createAction(`${NS}SET_SEARCHED_OBJECTS_LIST`)
export const setSearchParams = createAction(`${NS}SET_SEARCH_PARAMS`)

const ObjectsApi = new ObjectsAPI()

export const initialState = {
  objectsData: {},
  searchedObjectsList: [],
  pickedObject: {},
  searchParams: {}
}

export const fetchObjectsList = (params, search = false) => (
  dispatch,
  getState
) => {
  const pickedProject = getState().project.pickedProject._id
  if (pickedProject) {
    params.project = pickedProject
  }
  return ObjectsApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedObjects(response.docs))
      } else {
        dispatch(setObjectsData(response))
        dispatch(setSearchParams(params))
      }
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export const downloadPDF = object => (dispatch, getState) => {
  axios({
    method: 'get',
    url: `${config.server.url}/object/${object._id}/pdf`,
    responseType: 'arraybuffer',
    headers: {
      authorization: localStorage.getItem('authToken')
    }
  }).then(function (response) {
    handleDownloadResponse(response, 'application/pdf', `${object.name}.pdf`)
  })
}

export const exportObjectCSV = () => (dispatch, getState) => {
  const params = getState().objects.searchParams
  axios({
    method: 'get',
    url: `${config.server.url}/object/exportCSV`,
    responseType: 'text/csv',
    headers: {
      authorization: localStorage.getItem('authToken')
    },
    params
  }).then(function (response) {
    handleDownloadResponse(response, 'text/csv', 'objectsData.csv')
  })
}

export const findObject = id => () => {
  return ObjectsApi.fetchObject(id)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export default createReducer(
  {
    [setObjectsData]: (state, objectsData) => ({ ...state, objectsData }),
    [setPickedObject]: (state, pickedObject) => ({ ...state, pickedObject }),
    [setSearchParams]: (state, searchParams) => ({ ...state, searchParams }),
    [setSearchedObjects]: (state, searchedObjectsList) => ({
      ...state,
      searchedObjectsList
    })
  },
  initialState
)
