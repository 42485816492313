import React, { forwardRef, useState } from 'react'
import moment from 'moment'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useIntl, FormattedMessage } from 'react-intl'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Form, DatePicker, Input, Radio, Select, Switch, Tabs } from 'antd'

import TasksTab from './TasksTab'
import useUsers from '../../../hooks/useUsers'
import useObjects from '../../../hooks/useObjects'
import useSpecialWorks from '../../../hooks/useSpecialWorks'

const { Item } = Form
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const disabledDate = current => {
  return (
    current &&
    current <
      moment()
        .endOf('day')
        .subtract(1, 'days')
  )
}

const FormWorkOrder = forwardRef(({ request }, ref) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const [specialWork, setSpecialWork] = useState(false)

  const {
    name,
    objectId,
    description,
    priority = 0,
    checklist = {},
    extra_fields: extraFields = []
  } = request || {}

  const values = {
    priority,
    signatureRequired: false,
    specialWorkRequired: false,
    tasks: [
      {
        name,
        description,
        extra_fields: extraFields,
        objectId: objectId && objectId._id,
        checklist: checklist && checklist._id
      }
    ]
  }

  const { items: objects } = useObjects(objectId)

  const { loading: loadingUsers, fetch: fetchUsers, items: users } = useUsers()

  const {
    loading: loadingSpecialWorks,
    fetch: fetchSpecialWorks,
    items: specialWorks
  } = useSpecialWorks()

  return (
    <StyledForm ref={ref} form={form} {...formLayout} initialValues={values}>
      <Tabs defaultActiveKey='information'>
        <TabPane
          key='information'
          tab={intl.formatMessage({ id: 'information' })}
        >
          <Item
            name='name'
            label={intl.formatMessage({ id: 'name' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'this field is required' })
              }
            ]}
          >
            <Input />
          </Item>
          <Item
            name='description'
            label={intl.formatMessage({ id: 'description' })}
          >
            <TextArea />
          </Item>
          <Item
            name='executedBy'
            label={intl.formatMessage({ id: 'assignee' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'this field is required' })
              }
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={false}
              loading={loadingUsers}
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({
                id: 'select user'
              })}
              onSearch={debounce(fetchUsers, 500)}
            >
              {users.map(({ _id, email, fullName }) => (
                <Option key={_id}>{`${fullName} (${email})`}</Option>
              ))}
            </Select>
          </Item>
          <Item
            name='deliveryDate'
            label={intl.formatMessage({ id: 'due date' })}
          >
            <DatePicker
              showTime
              allowClear={false}
              format='DD.MM.YYYY HH:mm'
              disabledDate={disabledDate}
            />
          </Item>
          <Item name='priority' label={intl.formatMessage({ id: 'priority' })}>
            <Radio.Group buttonStyle='solid'>
              <Radio.Button value={0}>
                <FormattedMessage id='none' />
              </Radio.Button>
              <Radio.Button value={1}>
                <ArrowDownOutlined /> <FormattedMessage id='low' />
              </Radio.Button>
              <Radio.Button value={2}>
                <FormattedMessage id='medium' />
              </Radio.Button>
              <Radio.Button value={3}>
                <ArrowUpOutlined /> <FormattedMessage id='high' />
              </Radio.Button>
            </Radio.Group>
          </Item>
          <Item
            name='specialWorkRequired'
            label={intl.formatMessage({ id: 'special work required' })}
            valuePropName='checked'
            getValueFromEvent={value => {
              setSpecialWork(value)
              return value
            }}
          >
            <Switch />
          </Item>
          {specialWork && (
            <Item
              name='specialWork'
              label={intl.formatMessage({ id: 'select special work' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'this field is required' })
                }
              ]}
            >
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                loading={loadingSpecialWorks}
                placeholder={intl.formatMessage({
                  id: 'select special work'
                })}
                onSearch={debounce(fetchSpecialWorks, 500)}
              >
                {specialWorks
                  .filter(({ enabled }) => !!enabled)
                  .map(({ _id, name }) => (
                    <Option key={_id}>{name}</Option>
                  ))}
              </Select>
            </Item>
          )}
          <Item
            name='signatureRequired'
            label={intl.formatMessage({ id: 'signature required' })}
            valuePropName='checked'
          >
            <Switch />
          </Item>
        </TabPane>
        <TabPane key='tasks' tab={intl.formatMessage({ id: 'tasks' })}>
          <TasksTab form={form} request={request} objects={objects} />
        </TabPane>
      </Tabs>
    </StyledForm>
  )
})

FormWorkOrder.propTypes = {
  request: PropTypes.object.isRequired
}

export default FormWorkOrder
