import config from 'config'
import axios from '../helpers/axios'
import pick from 'lodash/pick'

export default {
  async getList () {
    const response = await axios.get('/checklist')
    return response
  },
  async listV2 (params = {}) {
    const response = await axios.get(
      config.server.host + '/1.1/checklist',
      params
    )
    return response
  },
  async addChecklist (data) {
    const response = await axios.post('/checklist', data)
    return response
  },
  async updateChecklist (id, data) {
    const params = pick(data, ['fields', 'name', 'archived'])
    const response = await axios.put(`/checklist/${id}`, params)
    return response
  }
}
