import React, { useContext } from 'react'
import { PageHeader } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import useApi from '../../../../hooks/useApi'
import fileService from '../../../../services/file'
import defectsService from '../../../../services/defects'
import FileUpload from '../../../../components/FileUpload'
import { PageContext } from '../../../../providers/PageProvider'

const Files = ({ defect }) => {
  const intl = useIntl()
  const { _id, files } = defect
  const { data, setData, setDefect } = useContext(PageContext)

  const [, deleteFile] = useApi(fileService.delete)
  const [uploading, uploadFile] = useApi(defectsService.addFile)

  const handleUpload = file => {
    const formData = new FormData()
    formData.append('file', file)
    return uploadFile(_id, formData)
  }

  const handleRemove = ({ _id }) => deleteFile(_id)

  const handleChange = files => {
    const item = { ...defect, files }
    const docs = Array.from((data || {}).docs || [])
    docs.splice(
      docs.findIndex(({ _id }) => _id === item._id),
      1,
      item
    )

    setData({ ...data, docs })
    setDefect(item)
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'Upload files'
        })}
      />
      <FileUpload
        value={files}
        loading={uploading}
        onChange={handleChange}
        onUpload={handleUpload}
        onRemove={handleRemove}
      />
    </>
  )
}

Files.propTypes = {
  defect: PropTypes.object.isRequired
}

export default Files
