import React, { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { TreeSelect } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import useContacts from '../hooks/useContacts'

const formatContact = contact => {
  const { name, company, lastName, firstName, contact_type: type } =
    contact || {}
  return type === 'company'
    ? company || name
    : `${firstName || ''}${lastName ? ` ${lastName}` : ''}`
}

const displayContact = contact => {
  const { contact_sup: sup, contact_sub: sub } = contact || {}
  const first = formatContact(sup)
  const second = formatContact(sub)
  return first ? `${first}${second ? ` (${second})` : ''}` : second || undefined
}

const ContactTreeSelect = ({ value, defaultValue, onChange, ...props }) => {
  const intl = useIntl()
  const [state, setState] = useState(defaultValue)

  const { fetch: fetchContacts, items: contacts } = useContacts()

  const treeData = useMemo(() => {
    const data = []
    const unAssignedList = []

    const subIds = new Set()
    contacts.forEach(({ contacts_sub: subs }) =>
      (subs || []).forEach(
        ({ contact_sub: sub }) => sub && sub._id && subIds.add(sub._id)
      )
    )

    contacts.forEach(contact => {
      if (contact.contact_type === 'company') {
        data.push({
          key: contact._id,
          value: contact._id,
          title: formatContact(contact),
          data: { contact_sub: contact },
          children: (contact.contacts_sub || []).map(
            ({ contact_sub: contactSub }) => ({
              title: formatContact(contactSub),
              key: `${contact._id}${contactSub._id}`,
              value: `${contact._id}${contactSub._id}`,
              data: {
                contact_sup: contact,
                contact_sub: contactSub
              }
            })
          )
        })
      } else if (!subIds.has(contact._id)) {
        unAssignedList.push({
          title: formatContact(contact),
          data: { contact_sub: contact },
          key: `unassigned-${contact._id}`,
          value: `unassigned-${contact._id}`
        })
      }
    })

    data.push({
      title: `${intl.formatMessage({ id: 'unassigned persons' })} (${
        unAssignedList.length
      })`,
      key: 'unassigned',
      value: 'unassigned',
      selectable: false,
      children: unAssignedList
    })

    return data
  }, [contacts])

  const onSelect = (value, { data }) => {
    typeof onChange === 'function' ? onChange(data) : setState(data)
  }

  return (
    <TreeSelect
      {...props}
      showSearch
      treeData={treeData}
      onSelect={onSelect}
      filterTreeNode={false}
      dropdownMatchSelectWidth
      onSearch={debounce(fetchContacts, 500)}
      value={displayContact(value || state)}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
    />
  )
}

ContactTreeSelect.displayContact = displayContact

ContactTreeSelect.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string
}

export default ContactTreeSelect
