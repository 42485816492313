import React from 'react'
import { notification } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import FormsList from './List'
import FormBuilder from './Builder'
import formService from 'services/form'
import queryString from 'query-string'
import PropTypes from 'prop-types'

export class FormsPage extends React.Component {
  state = {
    forms: [],
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    form: {},
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }

      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'name'
        searchQuery.sortOrder = 'descend'
      }

      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history } = this.props

    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }

    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'name' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchForms()
  }

  fetchForms = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const forms = await formService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = forms.totalDocs

      this.setState({
        forms: forms.docs,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: forms.limit,
          current: forms.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'forms'
        })} - download error`
      })

      this.setState({
        forms: [],
        loading: false
      })
    }
  }

  setForm = form => {
    this.setState({
      form
    })
  }

  updateForm = (field, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: value
      }
    })
  }

  saveForm = async () => {
    try {
      const savedForm = await formService.save(this.state.form)

      this.fetchForms()
      if (this.state.form._id) {
        notification.success({
          message: `${this.props.intl.formatMessage({
            id: 'updated successfully'
          })}`
        })
      } else {
        notification.success({
          message: `${this.props.intl.formatMessage({
            id: 'created successfully'
          })}`
        })
      }

      this.setState({
        form: {},
        forms: [
          ...this.state.forms.filter(form => form._id !== savedForm._id),
          savedForm
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error'
        }),
        description: error.error
      })
    }
  }

  deleteForm = async form => {
    try {
      await formService.delete(form)

      this.setState({
        form: {},
        forms: [...this.state.forms.filter(item => item._id !== form._id)]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting drror'
        }),
        description: error.message
      })
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  render () {
    const { form, forms, pagination, loading, params } = this.state
    return (
      <>
        <FormattedMessage id='head.title.forms'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {form.name === undefined ? (
          <FormsList
            forms={forms}
            loading={loading}
            onTableChange={this.handleTableChange}
            pagination={pagination}
            params={params}
            setForm={this.setForm}
          />
        ) : (
          <FormBuilder
            formData={form}
            setForm={this.setForm}
            updateForm={this.updateForm}
            saveForm={this.saveForm}
            deleteForm={this.deleteForm}
            authUser={this.props.user}
          />
        )}
      </>
    )
  }
}

FormsPage.propTypes = {
  intl: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.auth.authUser
})

export default injectIntl(connect(mapStateToProps)(FormsPage))
